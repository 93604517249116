import { createEvent, createStore, sample } from 'effector';

import { allImagesUnselected } from './components/Library/model';
import { shapeType } from './components/Shape';
import { MAX_ZOOM, MIN_ZOOM } from './constants';

type OptimisticBlock = {
  imageURL: string | HTMLImageElement;
  position: {
    x: number;
    y: number;
  };
  height: number;
  width: number;
  id: string;
};

type DragBox = {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
};

// Select block
export const selectObjectIds = createEvent<Set<string>>();

export const $selectedObjectIds = createStore<Set<string>>(new Set()).on(
  selectObjectIds,
  (_, payload) => payload,
);

export const selectObjectIdInGroup = createEvent<string | null>();

export const $selectedObjectIdInGroup = createStore<string | null>(null).on(
  selectObjectIdInGroup,
  (_, payload) => payload,
);

// Cropping image
export const startedCroppingImage = createEvent<string>();
export const endCroppingImage = createEvent();

export const $currentlyCroppingPhoto = createStore('')
  .on(startedCroppingImage, (_, p) => p)
  .reset(endCroppingImage);

export const addedOptimisticImage = createEvent<OptimisticBlock>();
export const removedOptimisticImage = createEvent<{
  id: string;
}>();
export const updatedStage = createEvent<{
  scale?: number;
  x: number;
  y: number;
}>();
export const startedDragSelection = createEvent<{
  x: number;
  y: number;
}>();
export const updatedDragSelection = createEvent<{
  x: number;
  y: number;
}>();
export const stoppedDragSelection = createEvent();
export const openedShuffler = createEvent();
export const closedShuffler = createEvent();
export const openedVaiTidyupModal = createEvent();
export const closedVaiTidyupModal = createEvent();

// Canvas
export const $optimisticImages = createStore<OptimisticBlock[]>([])
  .on(addedOptimisticImage, (state, payload) => {
    return [...state, payload];
  })
  .on(removedOptimisticImage, (state, payload) => {
    const newImages = state.filter((image) => image.id !== payload.id);

    return newImages;
  });

export const $stageState = createStore({
  scale: 0.2,
  x: 0,
  y: 0,
}).on(updatedStage, (state, payload) => {
  // TODO fix scale at 0 jumping
  const scale = payload.scale ? payload.scale : state.scale;

  let newScale = 0;
  let updatePosition = true;

  if (scale < MAX_ZOOM) {
    newScale = MAX_ZOOM;
    updatePosition = false;
  } else if (scale > MIN_ZOOM) {
    newScale = MIN_ZOOM;
    updatePosition = false;
  } else newScale = scale;

  return {
    scale: newScale,
    x: updatePosition ? payload.x : state.x,
    y: updatePosition ? payload.y : state.y,
  };
});
export const $dragSelection = createStore<DragBox | null>(null)
  .on(startedDragSelection, (_, p) => {
    return {
      x1: p.x,
      y1: p.y,
      x2: p.x,
      y2: p.y,
    };
  })
  .on(updatedDragSelection, (state, p) => {
    if (!state) return state;

    return {
      x1: state.x1,
      y1: state.y1,
      x2: p.x,
      y2: p.y,
    };
  })
  .reset(stoppedDragSelection);

// Tools
export const changedTool = createEvent<Tool>();
type Tool = 'move' | 'select' | 'add-text' | 'sticky' | 'add-shape';

export const $currentTool = createStore<Tool>('select').on(
  changedTool,
  (_, payload) => payload,
);

export const addShape = createEvent<shapeType>();

export const $currentShape = createStore<shapeType>('rectangle').on(
  addShape,
  (_, payload) => payload,
);

export const $showShuffler = createStore(false)
  .on(openedShuffler, () => true)
  .on(closedShuffler, () => false);
export const $showVaiTidyupModal = createStore(false)
  .on(openedVaiTidyupModal, () => true)
  .on(closedVaiTidyupModal, () => false);

// Library
export const toggledLibrary = createEvent();
export const openedLibrary = createEvent();
export const closedLibrary = createEvent();

export const changedDraggedImage = createEvent<boolean>();

export const $showLibrary = createStore(false)
  .on(toggledLibrary, (s) => !s)
  .on(openedLibrary, () => true)
  .reset([closedLibrary, changedTool]);
export const $isDraggingImageFromLibrary = createStore(false).on(
  changedDraggedImage,
  (_, payload) => payload,
);

// When library sheet is closed, deselect all images
sample({
  source: {
    showLibrary: $showLibrary,
  },
  filter: ({ showLibrary }) => !showLibrary,
  target: allImagesUnselected,
});

// Text
export const changedEditingState = createEvent<boolean>();

export const $isEditingText = createStore(false).on(
  changedEditingState,
  (_, payload) => payload,
);

export const $areShortcutsDisabled = createStore(false);

export const disableShortcuts = createEvent();
export const enableShortcuts = createEvent();

$areShortcutsDisabled
  .on(disableShortcuts, () => true)
  .on(enableShortcuts, () => false)
  // Automatically disable shortcuts when text editing
  .on(changedEditingState, (_, isEditing) => isEditing);
