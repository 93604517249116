import { deleteInvitee, RemoveInvitee } from '@api/boards';
import { queryClient } from '@src/queryClient';
import { MutateOptions, useMutation } from '@tanstack/react-query';

import {
  BOARDS_OF_HUB_QUERY,
  BOARDS_TREE_QUERY,
  MEMBERS_INVITATION_QUERY,
  MEMBERS_SUGGESTIONS_QUERY,
} from '../../../../../shared/constants/query-names';

export const useRemoveInvitee = (
  options?: MutateOptions<RemoveInvitee, any, RemoveInvitee>,
) => {
  const { mutate: removeInvitee } = useMutation({
    mutationFn: ({ boardId, members, email }: RemoveInvitee) =>
      deleteInvitee({ boardId, members, email }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_SUGGESTIONS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hub: variables.hubId }],
      });

      // if (variables.noBoardAccess) {
      //   history.push('/error');
      //   shareSideSheetClosed();
      // }

      // startedSnack({
      //   label: `Removed them from ${variables.boardName}`,
      //   close: true,
      // });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      // startedSnack({
      //   label: `Couldn't remove them from ${variables.boardName}`,
      //   action: {
      //     label: 'Try again',
      //     action: () => {
      //       removeInvitee({
      //         boardId: variables.boardId,
      //         members: variables.members,
      //         email: variables.email,
      //         boardName: variables.boardName,
      //       });
      //     },
      //   },
      //   close: true,
      // });

      if (options?.onError) options?.onError(error, variables, context);
    },
  });

  return { removeInvitee };
};
