import { useEffect, useMemo, useRef } from 'react';

import { useUnit } from 'effector-react';
import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';
import { Layer, Rect, Transformer } from 'react-konva';

import { useKeyPress } from '@visualist/hooks';

import {
  DRAG_SELECTION,
  SELECTION_TRANSFORMER,
} from '@pages/StudioPage/constants';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import {
  $dragSelection,
  $isEditingText,
  $selectedObjectIds,
  selectObjectIds,
} from '@pages/StudioPage/model';
import { $isShiftPressed } from '@pages/StudioPage/shift-key-tracking';
import { getSelectedNodes } from '@pages/StudioPage/utils';
import { TERTIARY_40 } from '@src/shared/constants/colours';

import { $areShortcutsDisabled } from '../../../model';

export const DragLayer = ({
  designId,
  hasCentered,
  stageRef,
}: {
  designId: string;
  hasCentered: boolean;
  stageRef: React.MutableRefObject<Stage | null>;
}) => {
  const [selectedObjectIds, dragSelection, isEditingText] = useUnit([
    $selectedObjectIds,
    $dragSelection,
    $isEditingText,
  ]);

  const selectedBlocks = useMemo(() => {
    return Array.from(selectedObjectIds);
  }, [selectedObjectIds]);

  const selectionTransformerRef = useRef<Konva.Transformer>(null);

  const { deleteObject, areAllInSameGroup } = useStudioDesign(designId);
  const isShiftPressed = useUnit($isShiftPressed);
  const areShortcutsDisabled = useUnit($areShortcutsDisabled);

  // When selected blocks updates update the selection transformer to select all nodes visually
  useEffect(() => {
    if (
      selectedBlocks.length > 1 &&
      stageRef.current &&
      selectionTransformerRef.current
    ) {
      const nodes = getSelectedNodes(stageRef.current, selectedBlocks);

      // @ts-ignore
      selectionTransformerRef.current.nodes(nodes);
      // @ts-ignore
      selectionTransformerRef.current.getLayer()?.batchDraw();
    }
  }, [selectedBlocks]);

  // Setup keyboard shortcuts
  // Keyboard Shortcuts
  const deleteSelectedBlocks = () => {
    if (selectedObjectIds.size && !isEditingText) {
      for (const id of Array.from(selectedObjectIds.values())) {
        deleteObject(id);
      }

      selectObjectIds(new Set());
    }
  };

  useKeyPress({
    key: 'Delete',
    onKeyDown: () => {
      if (!areShortcutsDisabled) {
        deleteSelectedBlocks();
      }
    },
  });

  useKeyPress({
    key: 'Backspace',
    onKeyDown: () => {
      if (!areShortcutsDisabled) {
        deleteSelectedBlocks();
      }
    },
  });

  if (!designId || !hasCentered) return null;

  return (
    <Layer name={DRAG_SELECTION}>
      {dragSelection ? (
        <Rect
          x={dragSelection.x1}
          y={dragSelection.y1}
          width={dragSelection.x2 - dragSelection.x1}
          height={dragSelection.y2 - dragSelection.y1}
          fill="#8B39A833"
          opacity={0.2}
          stroke={TERTIARY_40}
          strokeWidth={10}
          strokeEnabled
        />
      ) : null}
      {selectedBlocks.length > 1 ? (
        <Transformer
          id={SELECTION_TRANSFORMER}
          name={SELECTION_TRANSFORMER}
          ref={selectionTransformerRef}
          borderStroke={TERTIARY_40}
          anchorStroke={TERTIARY_40}
          shouldOverdrawWholeArea={
            !(isShiftPressed || areAllInSameGroup(selectedObjectIds))
          }
        />
      ) : null}
    </Layer>
  );
};
