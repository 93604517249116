import React, { createContext, useEffect, useState } from 'react';

import { useUnit } from 'effector-react';
import { isMobileOnly } from 'react-device-detect';
import { useParams } from 'react-router-dom';

import {
  AssistChip,
  BrandLoader,
  Divider,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useLoadingDelay } from '@visualist/design-system/src/components/v2/brand-loader/useLoadingDelay';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { getDocsOfBoard } from '@api/docs';
import { LibraryActions } from '@components/LibraryActions';
import { ArchiveBanner } from '@src/entities/banner/archive';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { locationSelected } from '@src/entities/dialogs/board/creation/search/model';
import { $isShowBoardDialog } from '@src/entities/dialogs/board/model';
import { IncorrectPermissions } from '@src/entities/global-error/premade/incorrect-permissions';
import { SomethingWentWrong } from '@src/entities/global-error/premade/something-went-wrong';
import { boardIdSelected } from '@src/entities/share-sheet/board/model/inviting';
import { useInvitees } from '@src/entities/share-sheet/board/model/queries/use-invitees';
import { RECENT_BOARDS_KEY } from '@src/shared/constants/variables-local-storage';
import { useRecent } from '@src/shared/hooks/useRecent';
import { showingDemoWarning } from '@src/widgets/onboarding/model';
import { useQuery } from '@tanstack/react-query';

import { getBoard } from '../../api';
import { createBoardShare, deleteShare, getShare } from '../../api/share';
import { useAppData } from '../../AppContext';
import { DocumentHead } from '../../components/DocumentHead';
import { FileCard } from '../../pages';
import { BoardsStripe } from '../../pages/BoardPage/components/BoardsStripe';
import { Container } from '../../pages/BoardPage/components/Container';
import { Grid } from '../../pages/BoardPage/components/Grid';
import { Title } from '../../pages/BoardPage/components/Title';
import { BOARD_QUERY, DOCS_OF_BOARD_QUERY } from '../../shared/constants';
import { Description } from './components/descripton';

import styles from './styles.module.css';

export type BoardShare = {
  allow_comments: boolean;
  board: string;
  hub: null;
  id: string;
  is_active: boolean;
  link: string;
  nested_board: [{ is: string; name: string }];
  set: null;
};

interface BoardContextInterface {
  hasPermissionEditing: boolean;
  isOwner: boolean;
}

const Context = createContext<BoardContextInterface>({
  hasPermissionEditing: true,
  isOwner: true,
});

export const BoardPage = () => {
  const { board_id } = useParams<{ board_id: string }>();

  return <BoardPageComponent key={board_id} board_id={board_id} />;
};

const BoardPageComponent = ({ board_id }: { board_id: string }) => {
  const [boardShare, setBoardShare] = useState<BoardShare | null>(null);

  const isShowBoardDialog = useUnit($isShowBoardDialog);

  const { user } = useAppData();

  const boardQuery = useQuery({
    queryKey: [BOARD_QUERY, { board_id }, user.show_archived],
    queryFn: () => getBoard({ id: board_id, is_archived: user.show_archived }),
    refetchInterval: 180000,
  });

  const { inviteesQuery, role } = useInvitees({ id: board_id });

  const { data: docs } = useQuery({
    queryKey: [DOCS_OF_BOARD_QUERY, { board: board_id }, user.show_archived],
    queryFn: () =>
      getDocsOfBoard({ board: board_id, is_archived: user.show_archived }),
    refetchInterval: 180000,
  });

  const isLoadingDelay = useLoadingDelay({
    enableLoadingDelay: typeof boardQuery.data === 'undefined',
  });

  const hasPermissionEditing =
    inviteesQuery.data &&
    inviteesQuery.data.members
      ?.filter(
        (invitee) => invitee.user && invitee.user.email === user.email,
      )[0]
      ?.permissions.some((permission) => permission === 'edit');

  const isOwner =
    inviteesQuery.data &&
    inviteesQuery.data.members?.filter(
      (invitee) => invitee.user && invitee.user.email === user.email,
    )[0]?.is_owner;

  useRecent({
    key: RECENT_BOARDS_KEY,
    item: {
      id: board_id,
      name: boardQuery.data?.name ?? '',
    },
  });

  useEffect(() => {
    if (board_id) {
      boardIdSelected(board_id);
    }
  }, [board_id]);

  useEffect(() => {
    if (isShowBoardDialog && boardQuery.data && boardQuery.data.level !== 2) {
      locationSelected({
        id: boardQuery.data.id,
        name: boardQuery.data.name,
        level: boardQuery.data.level,
        parent: boardQuery.data.parent,
        type: 'board',
      });
    } else if (
      isShowBoardDialog &&
      boardQuery.data &&
      boardQuery.data.parent &&
      'level' in boardQuery.data.parent &&
      boardQuery.data.level === 2
    ) {
      locationSelected({
        id: boardQuery.data.parent.id,
        name: boardQuery.data.parent.name,
        level: boardQuery.data.parent.level,
        parent: boardQuery.data.parent.parent,
        type: 'board',
      });
    }
  }, [boardQuery.data, isShowBoardDialog]);

  useEffect(() => {
    const getShareFromId = async (shareId: string) => {
      const boardShare = await getShare(shareId);
      setBoardShare(boardShare);
    };
    if (boardQuery.data && boardQuery.data?.share?.id) {
      getShareFromId(boardQuery.data.share.id);
    } else {
      setBoardShare(null);
    }
  }, [boardQuery.data]);

  const shareToWeb = async (published: boolean, allow_comments = true) => {
    if (user.meta?.onboarding?.demoMode) {
      showingDemoWarning();
      return;
    }
    if (published && boardQuery.data) {
      let nestedBoards: string[] = [];
      nestedBoards = boardQuery.data.children?.map(
        (nestedBoard) => nestedBoard.id,
      );
      const share = await createBoardShare(
        boardQuery.data.id,
        allow_comments,
        nestedBoards,
      );
      setBoardShare(share);
    } else {
      if (boardShare) {
        await deleteShare(boardShare.id);
        setBoardShare(null);
      }
    }
  };

  const copyPublicLink = async () => {
    try {
      if (boardShare) {
        await navigator.clipboard.writeText(boardShare.link);
        startedSnack({
          label: 'Copied link',
          close: true,
        });
      }
    } catch (error) {
      startedSnack({
        label: "Oops, could'nt copy this",
        action: {
          label: 'Try again',
          action: () => {
            copyPublicLink();
          },
        },
        close: true,
      });
    }
  };

  if (isLoadingDelay || boardQuery.isLoading) {
    return (
      <BrandLoader.CenterContainer>
        <BrandLoader
          animatedIcon="hub"
          subText="Freshening up your workspace..."
        />
      </BrandLoader.CenterContainer>
    );
  }

  if (boardQuery.isError) {
    if (boardQuery.error.message.includes('401')) {
      return <IncorrectPermissions />;
    }

    return <SomethingWentWrong />;
  }

  if (
    typeof hasPermissionEditing === 'undefined' ||
    typeof isOwner === 'undefined'
  )
    return <IncorrectPermissions />;

  if (!boardQuery.data) return <SomethingWentWrong />;

  return (
    <Context.Provider value={{ hasPermissionEditing, isOwner }}>
      <DocumentHead name={boardQuery.data.name} />
      <FileCard page="Board" />
      <div className={styles.boardPage}>
        {boardQuery.data.is_archived && (
          <ArchiveBanner
            className={isMobileOnly ? styles.archiveBanner : undefined}
            entity="Board"
            id={boardQuery.data.id}
          />
        )}
        <Container>
          <Breadcrumbs
            className={styles.breadcrumbs}
            board={boardQuery.data.parent}
            isClickable={!boardQuery.data.is_archived}
          />
          {boardShare?.link ? (
            <div className={styles.shareBanner}>
              <TooltipRadix description="Anyone on the internet with this link can view">
                <Icon
                  name="sprite/information"
                  size={16}
                  className={styles.infoIcon}
                />
              </TooltipRadix>
              <TypographyPoppins type="body" size="M" className={styles.text}>
                This page is shared to the&nbsp;
                {boardQuery.data.is_archived ? (
                  <span className={styles.archivedLink}>web</span>
                ) : (
                  <a
                    href={boardShare.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    web
                  </a>
                )}
                .
              </TypographyPoppins>
              <AssistChip
                className={styles.assistChip}
                leadingIcon
                icon={<Icon name="sprite/public" />}
                onClick={copyPublicLink}
                style="outlined"
                disabled={boardQuery.data.is_archived}
              >
                Copy public link
              </AssistChip>
            </div>
          ) : null}
          <Title
            board={boardQuery.data}
            shareToWeb={shareToWeb}
            boardShare={boardShare}
          />
          <Description board={boardQuery.data} />
          <Divider type="long-line" className={styles.divider} />
          <BoardsStripe
            boardsThumbnails={boardQuery.data.children}
            parent={board_id}
            level={boardQuery.data.level}
            isArchived={boardQuery.data.is_archived}
          />
        </Container>
        <Grid
          boardId={board_id}
          isOwner={isOwner}
          docs={docs}
          role={role}
          refetchRole={inviteesQuery.refetch}
          isArchived={boardQuery.data.is_archived}
        />
        <LibraryActions isArchived={boardQuery.data.is_archived} />
      </div>
    </Context.Provider>
  );
};

export const useBoardContext = () => React.useContext(Context);
