import { AxiosResponse } from 'axios';

import {
  Orientation,
  PageSize,
} from '@pages/StudioPage/components/page-setup/page-size';
import { shapeType } from '@pages/StudioPage/components/Shape';

import { api, ImageTypes } from './services';

type Photo = {
  full_size: string;
  medium_square_crop: string;
  small_square_crop: string;
  thumbnail: string;
};

export type BoardLvl3 = {
  created_at: string;
  description: string | null;
  id: string;
  name: string;
  level: 2;
  parent: BoardLvl2;
  is_removed: boolean;
};

export type BoardLvl2 = {
  created_at: string;
  description: string | null;
  id: string;
  name: string;
  level: 1;
  parent: BoardLvl1;
  is_removed: boolean;
};

export type BoardLvl1 = {
  created_at: string;
  description: string | null;
  id: string;
  name: string;
  level: 0;
  parent: Hub | null;
  is_removed: boolean;
};

export type Hub = {
  created_by: {
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    photo: Photo;
  };
  id: string;
  name: string;
  type: 'hub';
};

// Design
export type DesignResponse = {
  id: string;
  name: string;
  file: string;
  is_removed: boolean;
  tags: any[];
  contents_blob: DesignJSONV2 | null;
  custom_colors: string[];
  board: BoardLvl1[] | BoardLvl2[] | BoardLvl3[];
};

export type DesignObject = ShapeJSON | ImageJSON | TextJSON;

type BaseCanvas = {
  objects: Array<DesignObject>;
};

export type Page = BaseCanvas & {
  metadata: {
    id: string;
    height: number;
    width: number;
    orientation: Orientation;
    size: PageSize;
    thumbnail?: string;
    backgroundColor: string;
  };
};

export type Infinite = BaseCanvas & {
  metadata: {
    // TODO: infinite canvas metadata?
  };
};

export type Paged = Array<Page>;

export type DesignJSONV2 = {
  id: string;
  version: number;
} & (
  | {
      type: 'infinite';
      data: Infinite;
    }
  | {
      type: 'pages';
      data: Paged;
    }
);

type BaseElement = {
  id: string;
  x: number;
  y: number;
  rotation: number;

  scale: {
    x: number;
    y: number;
  };

  // layer: number
  opacity: number;
};

export type ShapeJSON = BaseElement & {
  type: 'shape';
  metadata: {
    // TODO break it into correct types for each shape type
    type: shapeType;
    width?: number;
    height?: number;
    radius?: number;
    radiusX?: number;
    radiusY?: number;
    sides?: number;
    numPoints?: number;
    innerRadius?: number;
    outerRadius?: number;
    angle?: number;
    points?: number[];
    pointerLength?: number;
    pointerWidth?: number;
    fill: string;
    stroke: string;
    strokeWidth: number;
    groupId?: string;
  };
};

export type PartialImageJSON = Pick<ImageJSON, 'id'> & {
  [K in keyof Omit<ImageJSON, 'id'>]?: K extends 'metadata'
    ? Partial<ImageJSON['metadata']>
    : ImageJSON[K];
};

export type PartialTextJSON = Pick<TextJSON, 'id'> & {
  [K in keyof Omit<TextJSON, 'id'>]?: K extends 'metadata'
    ? Partial<TextJSON['metadata']>
    : TextJSON[K];
};

export type PartialShapeJSON = Pick<ShapeJSON, 'id'> & {
  [K in keyof Omit<ShapeJSON, 'id'>]?: K extends 'metadata'
    ? Partial<ShapeJSON['metadata']>
    : ShapeJSON[K];
};

export type ImageType = 'Image' | 'Background remove' | 'Colour palette';

export type ImageJSON = BaseElement & {
  type: 'image';
  metadata: {
    width: number;
    height: number;
    originalWidth: number;
    originalHeight: number;
    file: ImageTypes;
    imageType: ImageType;
    crop: {
      x: number;
      y: number;
      height: number;
      width: number;
    } | null;
    blockId: string;
    groupId?: string;
  };
};

export type Alignment = 'left' | 'center' | 'right';

export type TextJSON = BaseElement & {
  type: 'text';
  metadata: {
    width: number;
    height: number;
    content: string;
    bold: boolean;
    underline: boolean;
    italic: boolean;
    colour: string;
    fontFamily: string;
    fontSize: number;
    alignment: Alignment;
    groupId?: string;
  };
};

export function isImageJSON(element: DesignObject): element is ImageJSON {
  if (element.type !== 'image') return false;
  const metadata = element.metadata;
  return (
    typeof metadata === 'object' &&
    metadata !== null &&
    'width' in metadata &&
    'height' in metadata &&
    'originalWidth' in metadata &&
    'originalHeight' in metadata &&
    'file' in metadata &&
    'crop' in metadata
  );
}

export function isTextJSON(element: DesignObject): element is TextJSON {
  if (element.type !== 'text') return false;
  const metadata = element.metadata;
  return (
    typeof metadata === 'object' &&
    metadata !== null &&
    'width' in metadata &&
    'height' in metadata &&
    'content' in metadata
  );
}

export function isShapeJSON(element: DesignObject): element is ShapeJSON {
  if (element.type !== 'shape') return false;
  const metadata = element.metadata;
  return (
    typeof metadata === 'object' &&
    metadata !== null &&
    'type' in metadata &&
    'fill' in metadata &&
    'stroke' in metadata &&
    'strokeWidth' in metadata
  );
}

export const getDesign = (
  designId: string,
): Promise<AxiosResponse<DesignResponse>> => {
  return api.get(`/blocks/set/${designId}/`);
};

type NewDesign = {
  name?: string;
  boardId?: string;
};

export const createDesign = ({
  name,
  boardId,
}: NewDesign): Promise<AxiosResponse<DesignResponse>> => {
  return api.post('/blocks/set/', { name, board: boardId, contents_blob: {} });
};

export const updateDesignName = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  return api.patch(`/blocks/set/${id}/`, { name });
};

export const updateDesignThumbnail = (id: string, file: string) =>
  api.put(`/blocks/set/${id}/`, { file });

export const updateDesignFile = async ({
  id,
  contents_blob,
}: {
  id: string;
  contents_blob?: DesignJSONV2;
}): Promise<AxiosResponse<DesignResponse>> =>
  api.put(`/blocks/set/${id}/`, { contents_blob });

export const updateDesignCustomColors = async ({
  id,
  custom_colors,
}: {
  id: string;
  custom_colors?: string[];
}): Promise<AxiosResponse<DesignResponse>> =>
  api.put(`/blocks/set/${id}/`, { custom_colors });
