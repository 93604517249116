import axios, { AxiosResponse } from 'axios';
import { Stage } from 'konva/lib/Stage';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';

import {
  DesignJSONV2 as DesignState,
  DesignObject,
  DesignResponse,
  ImageJSON,
  Infinite,
  Page,
  Paged,
  PartialImageJSON,
  PartialShapeJSON,
  PartialTextJSON,
  ShapeJSON,
  TextJSON,
  updateDesignThumbnail,
} from '@api/designs';
import { AddedBlockToSetResponse, api } from '@api/index';
import { generateId } from '@src/shared/utils/id';

import { Orientation, PageSize } from '../components/page-setup/page-size';
import {
  DEFAULT_FONTFAMILY,
  DEFAULT_FONTSIZE,
  WHITE_ONE_BY_ONE_BASE64_IMAGE,
} from '../constants';
import { createTempStage, loadPageObjectsToStage } from '../export-studio-pdf';
import {
  generatePreview,
  getDefaultPagedContentsBlob,
  getShapeWidthHeight,
  isArrowNode,
  isLineNode,
} from '../utils';
import { UndoRedoManager } from './undo-redo-manager';
import {
  fetchImages,
  fetchTextboxes,
  isBlobOldShapeData,
  rotateAroundCenter,
} from './utils';

const SYNC_INTERVAL = 2000;

export type DesignMetadata = {
  pageNumberIndex: number | null;
  isLoading: boolean;
  isError: false | { message: string };
  bufferPageData: Paged[number] | null;
  bufferObjectData: (ImageJSON | TextJSON | ShapeJSON)[] | null;
  canUndo: boolean;
  canRedo: boolean;
};

export const DEFAULT_PAGE_METADATA = {
  objects: [],
  metadata: {
    id: '',
    height: 2550,
    width: 3300,
    orientation: 'landscape',
    size: 'letter',
    backgroundColor: 'white',
  },
} satisfies Page;

type Mutable<T> = {
  -readonly [P in keyof T]: T[P];
};

export class Design {
  private readonly designId: string;
  // Null indicates it has not loaded just yet.
  private readonly state: DesignState | null;
  private readonly subscribers = new Set<
    (state: DesignState | null, metadata: DesignMetadata) => void
  >();

  // Undo redo
  private readonly undoRedoStack: UndoRedoManager<DesignState>;

  // Loading Related
  private syncTimeout: NodeJS.Timeout | null = null;
  private loadPromise: Promise<void> | null = null;
  private requestInflight = false;

  // Viewing metadata
  // Null if in infinite mode, else has a page number
  private metadata: DesignMetadata;

  constructor(designId: string) {
    this.designId = designId;
    this.state = null;
    this.metadata = {
      pageNumberIndex: null,
      isLoading: true,
      isError: false,
      bufferPageData: null,
      bufferObjectData: null,
      canRedo: false,
      canUndo: false,
    };
    this.undoRedoStack = new UndoRedoManager();
  }

  static readonly convertAddedBlocksToImageJson = (
    blocksToConvert: AddedBlockToSetResponse,
  ) => {
    return blocksToConvert.map(
      (b) =>
        ({
          id: b.id,
          metadata: {
            blockId: b.id,
            crop: null,
            file: b.file,
            width: b.studio.position_width ?? b.width,
            height: b.studio.position_height ?? b.height,
            imageType: 'Image',
            originalWidth: b.width,
            originalHeight: b.height,
          },
          opacity: 1,
          rotation: b.studio.position_omega,
          scale: {
            x: 1,
            y: 1,
          },
          type: 'image',
          x: b.studio.position_x,
          y: b.studio.position_y,
        } satisfies ImageJSON),
    );
  };

  get subscriberCount() {
    return this.subscribers.size;
  }

  getState = () => {
    return this.state;
  };

  getMetadata = () => {
    return this.metadata;
  };

  getCurrentPageMetadata = () => {
    const state = this.state;
    if (state?.type === 'infinite') {
      console.warn('Cannot get page metadata for infinite canvas');
      return null;
    }

    const currentPageIndex = this.metadata.pageNumberIndex || 0;

    const page = state?.data[currentPageIndex];

    return page ? page.metadata : null;
  };

  changeToEnd = () => {
    if (!this.state || this.state.type !== 'pages') return;
    const totalPages = this.state.data.length;
    const lastPage = totalPages - 1;
    this.updateMetadata({
      pageNumberIndex: lastPage,
    });

    return lastPage;
  };

  changePage = (newPageIdx: number) => {
    if (this.state?.type !== 'pages') {
      // TODO error that need to be in pages
      console.error('Document must be in pages state');
      this.updateMetadata({ pageNumberIndex: null });
      return;
    }

    const lastPageIndex = this.state.data.length - 1;
    const clampedIndex = Math.max(0, Math.min(newPageIdx, lastPageIndex));

    this.updateMetadata({ pageNumberIndex: clampedIndex });

    return clampedIndex;
  };

  // Update subscribe method to include metadata
  subscribe = (
    callback: (state: DesignState | null, metadata: DesignMetadata) => void,
  ) => {
    this.subscribers.add(callback);
    return () => this.subscribers.delete(callback);
  };

  private readonly updateMetadata = (updates: Partial<DesignMetadata>) => {
    this.metadata = { ...this.metadata, ...updates };
    this.notifySubscribers();
  };

  /**
   * Sets the current state of the design and notifies subscribers.
   * Optionally, it can ignore the sync to the server.
   *
   * @param newState - The new state to set.
   * @param ignoreSync - Optional flag to ignore syncing to the server.
   */

  private readonly setState = ({
    newState,
    ignoreSync,
    nonCommitingAction = false,
  }: {
    newState: DesignState;
    ignoreSync?: 'ignore-sync';
    nonCommitingAction?: boolean;
  }) => {
    // Add state to the undo redo stack if action is not an undo redo

    //TODO: Remove lodash and use Hashing to keep track of changes
    const isStateChanged = !isEqual(newState, this.state);
    if (!nonCommitingAction && this.state !== null && isStateChanged) {
      this.undoRedoStack.performAction(this.state);
    }

    // Update metadata with undo redo status
    this.metadata.canRedo = this.undoRedoStack.canRedo();
    this.metadata.canUndo = this.undoRedoStack.canUndo();

    // TODO: This is a hack as we are assigning a value to readonly (due to time)
    // Proper way have a getState() function which returns a readonly state
    // and the only way to update the private state will be here using this._state = newState;
    (this.state as Mutable<DesignState>) = newState;
    this.notifySubscribers();

    // Do not sync when updating initial states
    if (ignoreSync === 'ignore-sync') return;

    this.scheduleSyncToServer();
    this.generateThumbnailsSet({ allPages: false });
  };

  private readonly notifySubscribers = () => {
    this.subscribers.forEach((callback) => callback(this.state, this.metadata));
  };

  /**
   * @group Sync Operations
   * Debounces sync operations to reduce server load
   */

  private readonly scheduleSyncToServer = () => {
    if (this.syncTimeout) {
      clearTimeout(this.syncTimeout);
    }
    this.syncTimeout = setTimeout(this.syncToServer, SYNC_INTERVAL);
  };

  /**
   * @group Sync Operations
   * Schedules a sync operation to persist design state to the server.
   */

  private readonly syncToServer = async () => {
    try {
      await api.put(`/blocks/set/${this.designId}/`, {
        contents_blob: this.state,
      });
    } catch (error) {
      console.error('Failed to sync design:', error);
      // TODO Handle errors here
    }
  };

  /**
   * @group Request
   * Fetches the initial design handling loading state
   */

  loadDesign = async (pageNumber?: number) => {
    if (this.loadPromise) return this.loadPromise;

    this.loadPromise = (async () => {
      // Prevent duped requests
      if (this.requestInflight) return;

      try {
        this.requestInflight = true;

        const response = await api.get(`/blocks/set/${this.designId}`);
        // TODO Validate the parsed response has correct blob version etc
        const parsedResponse = response as AxiosResponse<DesignResponse>;
        const blob = parsedResponse.data.contents_blob;
        // Check if correctly migrated and ready file
        if (blob && blob.version === 1) {
          this.setState({
            newState: blob,
            ignoreSync: 'ignore-sync',
          });

          if (!isInfinite(blob)) {
            let pn = pageNumber !== undefined ? pageNumber - 1 : 0;
            const totalPages = blob.data.length;

            if (pn >= totalPages || pn < 0) {
              pn = 0;
            }

            this.updateMetadata({
              pageNumberIndex: pn,
            });

            if (!pageNumber) {
              let newUrl = window.location.href;

              if (newUrl.endsWith('/')) {
                newUrl = `${newUrl}1`;
              } else {
                newUrl = `${newUrl}/1`;
              }

              window.history.replaceState(null, '', newUrl);
              this.changePage(0);
            }
          }

          return;
        }

        // Attempt a migration
        const migrationStatus = await this.migrateToV1File(blob as unknown);

        if (migrationStatus) {
          // Succesfull migration
          return;
        } else {
          this.updateMetadata({
            isError: {
              message: 'Migration attempted and failed?',
            },
          });
          // TODO Something errored... what to do here??????????
          // Create initial empty file
          // // TODO Handle migrating data to this file, perhaps using the version number?
          // this.setState({
          //   id: this.designId,
          //   version: 1,
          //   type: 'infinite',
          //   data: {
          //     objects: [],
          //     metadata: {},
          //   },
          // });
        }

        return;
      } catch (e) {
        if (axios.isAxiosError(e)) {
          console.error('Failed to load design');
          this.updateMetadata({
            isError: {
              message: e.message,
            },
          });
        } else {
          this.updateMetadata({
            isError: {
              message: 'Something went wrong',
            },
          });
          console.error('Failed to load design:', e);
        }
      } finally {
        this.requestInflight = false;
        this.updateMetadata({
          isLoading: false,
        });
      }
    })();

    return this.loadPromise;
  };

  /**
   * Upload a white pixel if number of objects meet required condition
   *
   * @param state
   * @returns
   */

  generateSinglePixelThumbnailIfLengthMatches = (
    state: DesignState,
    lengthCheck: number,
  ) => {
    // Check if there is a single file added
    if (isInfinite(state)) {
      const isSingleFile = state.data.objects.length === lengthCheck;
      if (!isSingleFile) return false;
      updateDesignThumbnail(this.designId, WHITE_ONE_BY_ONE_BASE64_IMAGE);
    } else {
      const isSingleFile =
        state.data.reduce((total, page) => total + page.objects.length, 0) ===
        lengthCheck;
      if (!isSingleFile) return false;
      updateDesignThumbnail(this.designId, WHITE_ONE_BY_ONE_BASE64_IMAGE);
    }
    return true;
  };

  /**
   * @group Internal Design Updates
   * Add object internal
   */

  private readonly addNewObject = (
    newObject: ImageJSON | ShapeJSON | TextJSON,
  ) => {
    if (!this.state) throw new Error('No design file loaded into state');

    // Checks if id exists to prevent collisions
    const currentObjects = isInfinite(this.state)
      ? this.state.data.objects
      : this.state.data.flatMap((page) => page.objects);

    const isIdCollision = currentObjects.some((v) => v.id === newObject.id);

    if (isIdCollision) {
      newObject.id = generateId('studio_object');
    }

    if (isInfinite(this.state)) {
      const stateClone = structuredClone(this.state);
      stateClone.data.objects.push(newObject);
      this.setState({
        newState: stateClone,
      });
    } else {
      if (this.metadata.pageNumberIndex === null) return;
      const stateClone = structuredClone(this.state);
      stateClone.data[this.metadata.pageNumberIndex].objects.push(newObject);
      this.setState({
        newState: stateClone,
      });
    }

    // Generate a small 1x1 pixel for thumbnails of designs
    this.generateSinglePixelThumbnailIfLengthMatches(this.state, 1);
  };

  /**
   * @group Internal Design Updates
   * Edit image
   */

  private readonly editObject = (
    partialData: PartialImageJSON | PartialTextJSON | PartialShapeJSON,
  ) => {
    if (!this.state) throw new Error('No design file loaded into state');
    if (isInfinite(this.state)) {
      const foundIdx = this.state.data.objects.findIndex(
        (obj) => obj.id === partialData.id,
      );

      if (foundIdx === -1) return;

      const existingObject = this.state.data.objects[foundIdx];

      // if (
      //   isImageJSON(existingObject) ||
      //   isShapeJSON(existingObject) ||
      //   isTextJSON(existingObject)
      // )
      //   return;

      const stateClone = structuredClone(this.state);

      //@ts-expect-error TODO FIX THIS STUPID ERROR
      stateClone.data.objects[foundIdx] = {
        ...existingObject,
        ...partialData,
        metadata: {
          ...existingObject.metadata,
          ...partialData.metadata,
        },
      };
      this.setState({
        newState: stateClone,
      });
    } else {
      if (this.metadata.pageNumberIndex === null)
        throw new Error('No page number associated?');
      const foundIdx = this.state.data[
        this.metadata.pageNumberIndex
      ].objects.findIndex((obj) => obj.id === partialData.id);

      if (foundIdx === -1) return;

      const existingObject =
        this.state.data[this.metadata.pageNumberIndex].objects[foundIdx];

      // if (
      //   !isImageJSON(existingObject) ||
      //   !isShapeJSON(existingObject) ||
      //   !isTextJSON(existingObject)
      // )
      //   return;
      const stateClone = structuredClone(this.state);
      //@ts-expect-error TODO FIX THIS STUPID ERROR
      stateClone.data[this.metadata.pageNumberIndex].objects[foundIdx] = {
        ...existingObject,
        ...partialData,
        metadata: {
          ...existingObject.metadata,
          ...partialData.metadata,
        },
      };
      this.setState({
        newState: stateClone,
      });
    }
  };

  private readonly removeObject = (id: string) => {
    if (!this.state) throw new Error('No design file loaded into state');

    if (isInfinite(this.state)) {
      const stateClone = structuredClone(this.state);
      stateClone.data.objects = this.state.data.objects.filter(
        (obj) => obj.id !== id,
      );

      this.setState({
        newState: stateClone,
      });
    } else {
      if (this.metadata.pageNumberIndex === null)
        throw new Error('No page number associated?');

      const stateClone = structuredClone(this.state);
      stateClone.data[this.metadata.pageNumberIndex].objects = this.state.data[
        this.metadata.pageNumberIndex
      ].objects.filter((obj) => obj.id !== id);

      this.setState({
        newState: stateClone,
      });
    }
  };

  private readonly rotateObject = (id: string, amount: number) => {
    const state = this.state;
    if (!state) throw new Error('No design file loaded into state');

    let objects: DesignObject[] | undefined = undefined;

    if (state.type === 'infinite') {
      objects = state.data.objects;
    } else {
      if (this.metadata.pageNumberIndex === null) return;
      const currentPage = state.data[this.metadata.pageNumberIndex];
      objects = currentPage.objects;
    }

    const objectIndex = objects.findIndex((obj) => obj.id === id);
    if (objectIndex === -1) return;

    const object = objects[objectIndex];

    let updatedObject = null;

    if (object.type !== 'shape' || object.metadata?.type === 'rectangle') {
      const rotatedObject = rotateAroundCenter(object, amount);
      updatedObject = {
        ...rotatedObject,
      };
    } else {
      updatedObject = {
        ...object,
        rotation: object.rotation + amount,
      };
    }

    const newObjects = [...objects];
    newObjects[objectIndex] = updatedObject;

    if (state.type === 'infinite') {
      this.setState({
        newState: {
          ...state,
          data: {
            ...state.data,
            objects: newObjects,
          },
        },
      });
    } else {
      if (this.metadata.pageNumberIndex === null) return;
      const newPages = [...state.data];
      newPages[this.metadata.pageNumberIndex] = {
        ...newPages[this.metadata.pageNumberIndex],
        objects: newObjects,
      };

      this.setState({
        newState: {
          ...state,
          data: newPages,
        },
      });
    }
  };

  private readonly removePage = (pageId: string) => {
    const state = this.state;
    if (!state) throw new Error('No design file loaded into state');

    if (!isInfinite(state)) {
      const getIndex = state.data.findIndex(
        (page) => page.metadata.id === pageId,
      );
      const newPagesData = state.data.filter(
        (page) => page.metadata.id !== pageId,
      );
      const page = getDefaultPagedContentsBlob();
      if (newPagesData.length === 0) {
        newPagesData.push(page);
      }

      this.setState({
        newState: {
          ...state,
          data: newPagesData,
        },
      });

      return getIndex ? getIndex : 0;
    } else {
      throw new Error('Cant delete page in infinite canvas');
    }
  };

  private readonly createPage = (index?: number, frameId?: string) => {
    const state = this.state;
    if (!state) throw new Error('No design file loaded into state');

    const id = generateId('studio_page');

    if (!isInfinite(state)) {
      const lastElement = state.data.length - 1;
      let frameIndex = null;

      // This is for when we are adding a page based on the DropZone indicator
      // If the dropzone indicator is at index 0 we need the the page to the right
      // In other cases we need the page to our left [index - 1]
      if (index !== undefined && frameId === undefined) {
        if (index === 0) {
          frameIndex = 0;
        } else {
          frameIndex =
            index !== 0 && index - 1 >= 0 ? index - 1 : state.data.length - 1;
        }
      } else {
        frameIndex = state.data.findIndex(
          (page) => page.metadata.id === frameId,
        );
      }

      const newPage = {
        objects: [],
        metadata: {
          id,
          height:
            frameIndex !== -1
              ? state.data[frameIndex]?.metadata.height
              : state.data.length > 0
              ? state.data[lastElement].metadata.height
              : 2550,
          width:
            frameIndex !== -1
              ? state.data[frameIndex]?.metadata.width
              : state.data.length > 0
              ? state.data[lastElement].metadata.width
              : 3300,
          orientation:
            frameIndex !== -1
              ? state.data[frameIndex]?.metadata.orientation
              : state.data.length > 0
              ? state.data[lastElement].metadata.orientation
              : 'landscape',
          backgroundColor: 'white',
          size:
            frameIndex !== -1
              ? state.data[frameIndex]?.metadata.size
              : state.data.length > 0
              ? state.data[lastElement].metadata.size
              : 'letter',
        },
      } satisfies Page;

      if (index !== undefined) {
        // If an index is provided, insert the page AT that specific position
        const insertPosition = Math.min(index, state.data.length);

        // Use spread operator for cleaner array manipulation
        state.data = [
          ...state.data.slice(0, insertPosition),
          newPage,
          ...state.data.slice(insertPosition),
        ];
      } else {
        // Default behavior: add page at the end
        state.data.push(newPage);
      }

      this.setState({
        newState: state,
      });

      return id; // Return the ID of the newly created page
    } else {
      throw new Error('Cannot add page in infinite canvas');
    }
  };

  private readonly reorderPage = (
    currentIndex: number,
    swapTo: number,
    currentSelectedFrameId: string,
  ) => {
    const state = this.state;
    if (!state) throw new Error('No design file loaded into state');
    if (!isInfinite(state)) {
      // Create a copy of the pages array
      const reorderedPages = Array.from(state.data);

      // Remove the dragged item from the array and insert it at the new position
      const [removed] = reorderedPages.splice(currentIndex, 1);
      reorderedPages.splice(swapTo, 0, removed);

      // Update state with the reordered pages
      this.setState({
        newState: {
          ...state,
          data: reorderedPages,
        },
      });

      const selectedFrameIdx = reorderedPages.findIndex(
        (page) => page.metadata.id === currentSelectedFrameId,
      );

      return selectedFrameIdx;
    }
  };

  private readonly updateThumbnail = (pageIndex: number, data: string) => {
    const state = this.state;
    if (!state) throw new Error('No design file loaded into state');
    if (!isInfinite(state)) {
      const newPagesData = state.data.map((page, index) => {
        if (index === pageIndex) {
          return {
            ...page,
            metadata: {
              ...page.metadata,
              thumbnail: data,
            },
          };
        }
        return page;
      });

      // Donot call setState it will trigger circular call with generateThumbnailsSet
      state.data = [...newPagesData];
      this.notifySubscribers();
      this.scheduleSyncToServer();
    } else {
      throw new Error('Cant delete page in infinite canvas');
    }
  };

  // Avoid generating new IDs in this method, as the page may be pasted or duplicated multiple times.
  private readonly markForCopy = (pageId: string) => {
    const state = this.state;
    if (!state) throw new Error('No design file loaded into state');

    if (!isInfinite(state)) {
      const page = state.data.find((page) => page.metadata.id === pageId);

      if (!page) throw new Error('No page to copy index');

      const clonedPage = structuredClone(page);

      const newMeta = { ...this.metadata, bufferPageData: clonedPage };
      this.updateMetadata(newMeta);
    } else {
      throw new Error('Cant delete page in infinite canvas');
    }
  };

  private readonly clonePageData = (index?: number) => {
    const state = this.state;
    if (!state) throw new Error('No design file loaded into state');

    if (!isInfinite(state)) {
      // Create a copy of the pages array
      if (!this.metadata.bufferPageData) return;

      const clonedPage = structuredClone(this.metadata.bufferPageData);
      clonedPage.metadata.id = generateId('studio_page');

      const groupIdMap = new Map<string, string>();

      clonedPage.objects = clonedPage.objects.map((elements) => {
        const newId = generateId('studio_object');
        const newElement = { ...elements, id: newId };
        const metadata = { ...elements.metadata };

        if ('groupId' in metadata && metadata.groupId) {
          const originalGroupId = metadata.groupId;

          if (!groupIdMap.has(originalGroupId)) {
            groupIdMap.set(originalGroupId, generateId('studio_group'));
          }

          metadata.groupId = groupIdMap.get(originalGroupId);
        }

        return {
          ...newElement,
          metadata,
        } as DesignObject;
      });

      if (typeof index !== 'undefined' && index >= 0) {
        const insertPosition = Math.min(index, state.data.length);
        state.data = [
          ...state.data.slice(0, insertPosition),
          clonedPage,
          ...state.data.slice(insertPosition),
        ];
      } else {
        state.data.push(clonedPage);
      }
      this.setState({ newState: state });
    } else {
      throw new Error('Cant delete page in infinite canvas');
    }
  };

  private readonly duplicate = (pageId: string) => {
    const state = this.state;
    if (!state) throw new Error('No design file loaded into state');
    if (!isInfinite(state)) {
      // Create a copy of the pages array
      const page = state.data.find((page) => page.metadata.id === pageId);
      if (!page) throw new Error('Page not found');

      const clonedPage = structuredClone(page);

      clonedPage.metadata.id = generateId('studio_page');

      const groupIdMap = new Map<string, string>();

      clonedPage.objects = clonedPage.objects.map((elements) => {
        const newId = generateId('studio_object');

        const newElement = { ...elements, id: newId };
        const metadata = { ...elements.metadata };
        if ('groupId' in metadata && metadata.groupId) {
          const originalGroupId = metadata.groupId;

          if (!groupIdMap.has(originalGroupId)) {
            groupIdMap.set(originalGroupId, generateId('studio_group'));
          }

          metadata.groupId = groupIdMap.get(originalGroupId);
        }

        return {
          ...newElement,
          metadata,
        } as DesignObject;
      });

      const index = state.data.findIndex((page) => page.metadata.id === pageId);

      const insertPosition = Math.min(index + 1, state.data.length);
      state.data = [
        ...state.data.slice(0, insertPosition),
        clonedPage,
        ...state.data.slice(insertPosition),
      ];

      this.setState({ newState: state });

      return insertPosition;
    } else {
      throw new Error('Cant delete page in infinite canvas');
    }
  };

  /**
   * @group Design Updates
   * Add image
   */

  addImage = ({
    id,
    x,
    y,
    height,
    width,
    originalWidth,
    originalHeight,
    file,
    imageType,
  }: Pick<ImageJSON, 'x' | 'y' | 'id'> &
    Pick<
      ImageJSON['metadata'],
      | 'width'
      | 'height'
      | 'originalHeight'
      | 'originalWidth'
      | 'file'
      | 'imageType'
    >) => {
    const newImage = {
      id,
      x,
      y,
      opacity: 1,
      rotation: 0,
      scale: {
        x: 1,
        y: 1,
      },
      type: 'image',
      metadata: {
        crop: null,
        file,
        height,
        width,
        originalHeight,
        originalWidth,
        imageType,
        blockId: id,
      },
    } satisfies ImageJSON;

    this.addNewObject(newImage);
  };

  /**
   * @group Design Updates
   * Add Text
   */

  addText = (textData: TextJSON) => {
    this.addNewObject(textData);
  };

  /**
   * @group Design Updates
   * Add Shape
   */

  addShape = (shapeData: ShapeJSON) => {
    this.addNewObject(shapeData);
  };

  /**
   * @group Design Updates
   * Edit image related data
   */

  updateImage = (imageData: PartialImageJSON) => {
    this.editObject(imageData);
  };

  /**
   * @group Design Updates
   * Edit shape related data
   */

  updateShape = (shapeData: PartialShapeJSON) => {
    this.editObject(shapeData);
  };

  /**
   * @group Design Updates
   * Edit text related data
   */

  updateText = (textData: PartialTextJSON) => {
    this.editObject(textData);
  };

  /**
   * @group Design Updates
   * Delete image
   */

  deleteObject = (id: string) => {
    this.removeObject(id);
  };

  rotateSingleObject = (id: string, amount: number) => {
    this.rotateObject(id, amount);
  };

  rotateMultipleObjects = (
    rotatedNodes: Array<{
      id: string;
      x: number;
      y: number;
      rotation: number;
    }>,
  ) => {
    this.updateRotationMultipleObjects(rotatedNodes);
  };

  private readonly updateRotationMultipleObjects = (
    rotatedNodes: Array<{
      id: string;
      x: number;
      y: number;
      rotation: number;
    }>,
  ) => {
    const state = this.getState();
    if (!state) throw new Error('No design file loaded into state');

    let objects: DesignObject[] | undefined = undefined;

    if (state.type === 'infinite') {
      objects = state.data.objects;
    } else {
      if (this.metadata.pageNumberIndex === null) return;
      const currentPage = state.data[this.metadata.pageNumberIndex];
      objects = currentPage.objects;
    }

    // Create a map of rotated nodes for quick lookup
    const rotatedNodesMap = new Map(
      rotatedNodes.map((node) => [node.id, node]),
    );

    const updatedObjects = objects.map((object) => {
      const rotatedNode = rotatedNodesMap.get(object.id);
      return rotatedNode
        ? {
            ...object,
            x: rotatedNode.x,
            y: rotatedNode.y,
            rotation: rotatedNode.rotation,
          }
        : object;
    });

    if (state.type === 'infinite') {
      this.setState({
        newState: {
          ...state,
          data: {
            ...state.data,
            objects: updatedObjects,
          },
        },
      });
    } else {
      if (this.metadata.pageNumberIndex === null) return;
      const newPages = [...state.data];
      newPages[this.metadata.pageNumberIndex] = {
        ...newPages[this.metadata.pageNumberIndex],
        objects: updatedObjects,
      };

      this.setState({
        newState: {
          ...state,
          data: newPages,
        },
      });
    }
  };

  /**
   * @group Design Updates
   * Deletes a page from the document at the specified index.
   *
   * @param pageIndex - The index of the page to be deleted.
   * Must be a valid index within the document's page list.
   */

  deletePage = (pageId: string) => {
    return this.removePage(pageId);
  };

  /**
   * @group Design Updates
   * Adds a new page to the document.
   * Optionally accepts an index to insert the page at a specific position.
   * If no index is provided, the page will be added at the end.
   *
   * @param index - Optional index to slot the new page at. If not specified, the page is appended.
   */

  addPage = (index?: number, id?: string) => {
    this.createPage(index, id);
  };

  /**
   * @group Design Updates
   * Clones a Page in metadata.bufferPageData
   * If metadata.bufferPageData is empty returns early
   * If no index is provided, the page will be added at the end.
   *
   * @param index - Optional index to insert the cloned page. If not specified, the page is appended.
   */

  pastePage = (index?: number) => {
    this.clonePageData(index);
  };

  /**
   * @group Design Updates
   * Duplicates a page at the specified index and appends the duplicate immediately after the original page.
   *
   * @param index - The index of the page to duplicate. The duplicate will be inserted directly after this index.
   */

  duplicatePage = (pageId: string) => {
    return this.duplicate(pageId);
  };

  /**
   * @group Design Updates
   * Reorders pages by swapping the position of two pages within the provided array.
   *
   * @param pagesArray - The array of pages to reorder.
   * @param currentIndex - The index of the page to be moved.
   * @param swapTo - The index to swap the page to.
   */

  swapPage = (
    currentIndex: number,
    swapTo: number,
    currentSelectedFrameId: string,
  ) => {
    return this.reorderPage(currentIndex, swapTo, currentSelectedFrameId);
  };

  /**
   * @group Design Updates
   * Saves a thumbnail image for the page
   *
   * @param index - The index of the page to associate the thumbnail with.
   * @param data - The thumbnail image data in base64.
   */

  saveThumbnail = (index: number, data: string) => {
    this.updateThumbnail(index, data);
  };

  /**
   * @group Design Updates
   * Performs a structured copy of a page and adds it to `metadata.bufferPageData`.
   *
   * @param index - The index of the page to mark for copying.
   */

  markPageForCopy = (pageId: string) => {
    this.markForCopy(pageId);
  };

  /**
   * @group Design Updates
   * Switch state type (infinite or pages)
   */

  switchStateType = ({ stage }: { stage: Stage | null }) => {
    this.changeStateType({ stage });
  };

  private readonly changeStateType = ({ stage }: { stage: Stage | null }) => {
    const state = this.getState();
    if (!state) throw new Error('No design file loaded into state');
    if (isInfinite(state)) {
      const infiniteData = state.data;
      const containerBounds = {
        minX: 0,
        minY: 0,
        maxX: 3300,
        maxY: 2550,
      };
      // move elements to fit in page
      infiniteData.objects = this.updateElementsPosition({
        data: infiniteData.objects,
        containerBounds,
        stage,
      });

      const pageData = {
        ...infiniteData,
        metadata: {
          id: generateId('studio_page'),
          height: 2550,
          width: 3300,
          orientation: 'landscape',
          size: 'letter',
          backgroundColor: '#FFFFFF',
        },
      } satisfies Page;
      this.updateMetadata({ pageNumberIndex: 0 });
      this.setState({
        newState: {
          ...state,
          type: 'pages',
          data: [pageData],
        },
      });
    } else {
      const pagesData = state.data as Paged;
      const allObjects = this.spreadElementsOnInfiniteCanvas(pagesData);
      const infiniteData: Infinite = {
        objects: allObjects,
        metadata: {},
      };
      this.setState({
        newState: {
          ...state,
          type: 'infinite',
          data: infiniteData,
        },
      });
      this.changePage(-1);
    }
  };

  // this is used in pages
  private readonly updateElementsPosition = ({
    data,
    containerBounds,
    stage,
  }: {
    data: DesignObject[];
    containerBounds: {
      minX: number;
      minY: number;
      maxX: number;
      maxY: number;
    };
    stage: Stage | null;
  }) => {
    if (data.length === 0) return data;

    const MIN_WIDTH = 100; // Minimum width threshold
    const MIN_HEIGHT = 100; // Minimum height threshold
    const MIN_FONT_SIZE = 20; // Minimum font threshold
    //Get bounding box of all elements
    let minX = Infinity,
      minY = Infinity,
      maxX = -Infinity,
      maxY = -Infinity;

    const elementsWithSize = data.map((elementObject) => {
      let width = 0,
        height = 0;

      if (elementObject.type === 'image' || elementObject.type === 'text') {
        width = elementObject.metadata.width;
        height = elementObject.metadata.height;

        // Update function level bounding box
        minX = Math.min(minX, elementObject.x);
        minY = Math.min(minY, elementObject.y);
        maxX = Math.max(maxX, elementObject.x + width);
        maxY = Math.max(maxY, elementObject.y + height);
      } else if (elementObject.type === 'shape') {
        const shapeSize = getShapeWidthHeight({ shape: elementObject, stage });
        width = shapeSize.width;
        height = shapeSize.height;
        if (
          elementObject.metadata.type === 'line' ||
          elementObject.metadata.type === 'arrow'
        ) {
          if (stage) {
            const node = stage.findOne(`#${elementObject.id}`);
            if (node && (isLineNode(node) || isArrowNode(node))) {
              const x1 = node.x();
              const y1 = node.y();
              const x2 = node.x() + node.points()[2];
              const y2 = node.y() + node.points()[3];
              // Update function level bounding box
              minX = Math.min(minX, x1, x2);
              minY = Math.min(minY, y1, y2);
              maxX = Math.max(maxX, x1, x2);
              maxY = Math.max(maxY, y1, y2);
            }
          }
        } else {
          // Update function level bounding box
          minX = Math.min(minX, elementObject.x);
          minY = Math.min(minY, elementObject.y);
          maxX = Math.max(maxX, elementObject.x + width);
          maxY = Math.max(maxY, elementObject.y + height);
        }
      }

      return { ...elementObject, width, height };
    });

    // Compute scale factor to fit within page bounds
    const elementWidth = maxX - minX;
    const elementHeight = maxY - minY;
    const containerWidth = containerBounds.maxX - containerBounds.minX;
    const containerHeight = containerBounds.maxY - containerBounds.minY;

    // Define Safe Space Based on Page Size
    const safeMargin = Math.min(containerWidth, containerHeight) * 0.07; // 7% margin
    const safeMinX = containerBounds.minX + safeMargin;
    const safeMinY = containerBounds.minY + safeMargin;
    const safeMaxX = containerBounds.maxX - safeMargin;
    const safeMaxY = containerBounds.maxY - safeMargin;

    // Compute available space after applying safe margin
    const availableWidth = safeMaxX - safeMinX;
    const availableHeight = safeMaxY - safeMinY;

    // Compute scale factor considering safe margins
    let scaleFactor = Math.min(
      availableWidth / elementWidth,
      availableHeight / elementHeight,
      1,
    );
    // Ensure scale factor does not reduce elements below their minimum size
    const minScaleFactor = Math.min(
      MIN_WIDTH / elementWidth,
      MIN_HEIGHT / elementHeight,
      1,
    );
    scaleFactor = Math.max(scaleFactor, minScaleFactor);

    // Compute new centered position within safe area
    const offsetX =
      safeMinX + (availableWidth - elementWidth * scaleFactor) / 2;
    const offsetY =
      safeMinY + (availableHeight - elementHeight * scaleFactor) / 2;

    // Apply scaling and centering with safe margins
    return elementsWithSize.map((elementObject) => {
      const scaledX = (elementObject.x - minX) * scaleFactor + offsetX;
      const scaledY = (elementObject.y - minY) * scaleFactor + offsetY;
      const scaledWidth = Math.max(
        elementObject.width * scaleFactor,
        MIN_WIDTH,
      );
      const scaledHeight = Math.max(
        elementObject.height * scaleFactor,
        MIN_HEIGHT,
      );

      if (elementObject.type === 'shape') {
        switch (elementObject.metadata.type) {
          case 'rectangle':
            return {
              ...elementObject,
              x: scaledX,
              y: scaledY,
              metadata: {
                ...elementObject.metadata,
                width: scaledWidth,
                height: scaledHeight,
              },
            };

          case 'circle':
          case 'hexagon':
          case 'wedge':
            return {
              ...elementObject,
              x: scaledX,
              y: scaledY,
              metadata: {
                ...elementObject.metadata,
                radius: Math.max(scaledWidth, scaledHeight) / 2,
              },
            };

          case 'ellipse':
            return {
              ...elementObject,
              x: scaledX,
              y: scaledY,
              metadata: {
                ...elementObject.metadata,
                radiusX: scaledWidth / 2,
                radiusY: scaledHeight / 2,
              },
            };

          case 'star':
          case 'ring':
          case 'arc':
            return {
              ...elementObject,
              x: scaledX,
              y: scaledY,
              metadata: {
                ...elementObject.metadata,
                outerRadius: Math.max(scaledWidth, scaledHeight) / 2,
                innerRadius: (Math.max(scaledWidth, scaledHeight) / 2) * 0.5,
              },
            };

          case 'arrow':
          case 'line':
            if (stage) {
              const node = stage.findOne(`#${elementObject.id}`);
              if (node && elementObject.metadata.points) {
                const originalPoints = elementObject.metadata.points;
                const scaledPoints = [
                  0,
                  0,
                  originalPoints[2] * scaleFactor,
                  originalPoints[3] * scaleFactor,
                ];
                return {
                  ...elementObject,
                  x: scaledX,
                  y: scaledY,
                  metadata: {
                    ...elementObject.metadata,
                    points: scaledPoints,
                  },
                };
              }
            }
            break;

          default:
            console.warn(`Unknown shape type: ${elementObject.metadata.type}`);
            return {
              ...elementObject,
              x: scaledX,
              y: scaledY,
              metadata: {
                ...elementObject.metadata,
                width: scaledWidth,
                height: scaledHeight,
              },
            };
        }
      } else if (elementObject.type === 'text') {
        return {
          ...elementObject,
          x: scaledX,
          y: scaledY,
          metadata: {
            ...elementObject.metadata,
            fontSize: Math.max(
              elementObject.metadata.fontSize * scaleFactor,
              MIN_FONT_SIZE,
            ),
          },
        };
      }

      return {
        ...elementObject,
        x: scaledX,
        y: scaledY,
        metadata: {
          ...elementObject.metadata,
          width: scaledWidth,
          height: scaledHeight,
        },
      };
    }) as DesignObject[];
  };

  // this is used to spread elements across infinite, when moving from pages to infinite
  private readonly spreadElementsOnInfiniteCanvas = (pagesData: Paged) => {
    if (pagesData.length === 0) return [];

    let currentX = 0;
    const allObjects: DesignObject[] = [];

    pagesData.forEach((page) => {
      const pageWidth = page.metadata.width;

      // Move elements from each page while preserving their layout
      const pageObjects = page.objects.map((object) => ({
        ...object,
        x: object.x + currentX,
        y: object.y + 0,
      }));

      allObjects.push(...pageObjects);
      currentX += pageWidth;
    });

    return allObjects;
  };

  /**
   * @group Design Updates
   * Clones an Object/Objects in metadata.bufferObjectData
   *
   */

  pasteStudioObjects = () => {
    return this.pasteObjects();
  };

  /**
   * @group Design Updates
   * Copies object id/ids to metadata.bufferObjectData
   *
   * @param objectIds - The ids of the objects to mark for copying.
   */

  copyStudioObject = (objectIds: string[]) => {
    return this.copyObjects(objectIds);
  };

  /**
   * @group Design Updates
   * Copies object id/ids to metadata.bufferObjectData
   * Removes the original object
   *
   * @param objectIds - The ids of the objects to mark for copying.
   */

  cutStudioObject = (objectIds: string[]) => {
    return this.cutObjects(objectIds);
  };

  /**
   * @group Design Updates
   * Update page background color
   */

  updateBackgroundColor = (
    backgroundColor: string,
    updateAllPages: boolean,
    pageId: string,
  ) => {
    this.changeBackgroundColor(backgroundColor, updateAllPages, pageId);
  };

  private readonly changeBackgroundColor = (
    backgroundColor: string,
    updateAllPages: boolean,
    pageId: string,
  ) => {
    const state = this.getState();
    if (!state) throw new Error('No design file loaded into state');

    if (!isInfinite(state)) {
      const pagesData = state.data as Paged;

      const updatedPages = updateAllPages
        ? pagesData.map((page) => ({
            ...page,
            metadata: {
              ...page.metadata,
              backgroundColor: backgroundColor,
            },
          }))
        : (() => {
            const pageIndex = pagesData.findIndex(
              (page) => page.metadata.id === pageId,
            );
            if (pageIndex === null) {
              throw new Error('Invalid page');
            }

            const updatedPage = {
              ...pagesData[pageIndex],
              metadata: {
                ...pagesData[pageIndex].metadata,
                backgroundColor: backgroundColor,
              },
            };
            const updatedPagesCopy = [...pagesData];
            updatedPagesCopy[pageIndex] = updatedPage;
            return updatedPagesCopy;
          })();

      // do not call setstate as it will trigger circular call to generateThumbnailsSet
      state.data = [...updatedPages];
      this.notifySubscribers();
      this.scheduleSyncToServer();
      this.generateThumbnailsSet({ allPages: true });
    } else {
      throw new Error('Cannot update page on infinite canvas');
    }
  };

  /**
   * @group Design Updates
   * Update page size
   */

  updatePageSize = (
    width: number,
    height: number,
    orientation: Orientation,
    size: PageSize,
    updateAllPages: boolean,
    pageId: string,
  ) => {
    this.changePageSize(
      width,
      height,
      orientation,
      size,
      updateAllPages,
      pageId,
    );
  };

  private readonly changePageSize = (
    width: number,
    height: number,
    orientation: Orientation,
    size: PageSize,
    updateAllPages: boolean,
    pageId: string,
  ) => {
    const state = this.getState();
    if (!state) throw new Error('No design file loaded into state');

    if (!isInfinite(state)) {
      const pagesData = state.data as Paged;

      const updatedPages = updateAllPages
        ? pagesData.map((page) => ({
            ...page,
            metadata: {
              ...page.metadata,
              width: width,
              height: height,
              orientation: orientation,
              size: size,
            },
          }))
        : (() => {
            const pageIndex = pagesData.findIndex(
              (page) => page.metadata.id === pageId,
            );
            if (pageIndex === null) {
              throw new Error('Invalid page');
            }

            const updatedPage = {
              ...pagesData[pageIndex],
              metadata: {
                ...pagesData[pageIndex].metadata,
                width: width,
                height: height,
                orientation: orientation,
                size: size,
              },
            } satisfies Page;
            const updatedPagesCopy = [...pagesData];
            updatedPagesCopy[pageIndex] = updatedPage;
            return updatedPagesCopy;
          })();

      // do not call setstate as it will trigger circular call to generateThumbnailsSet
      state.data = [...updatedPages];
      this.notifySubscribers();
      this.scheduleSyncToServer();
      this.generateThumbnailsSet({ allPages: true });
    } else {
      throw new Error('Cannot update page on infinite canvas');
    }
  };

  /**
   * @group Design Updates
   * Updating layers
   */

  updateLayer = (
    id: string,
    action: 'forward' | 'backward' | 'front' | 'back',
  ) => {
    const state = this.getState();
    if (!state) return;

    let objects: DesignObject[] | undefined = undefined;

    if (state.type === 'infinite') {
      objects = state.data.objects;
    } else {
      // For paged designs, we'll need to handle the active page
      if (this.metadata.pageNumberIndex === null) return;

      const currentPage = state.data[this.metadata.pageNumberIndex];

      objects = currentPage.objects;
    }

    const elementIndex = objects.findIndex((obj) => obj.id === id);

    if (elementIndex === -1) return;

    const element = objects[elementIndex];
    const newObjects = [...objects];
    newObjects.splice(elementIndex, 1);

    switch (action) {
      case 'forward': {
        // Move one layer up
        const newIndex = Math.min(elementIndex + 1, objects.length - 1);
        newObjects.splice(newIndex, 0, element);
        break;
      }
      case 'backward': {
        // Move one layer down
        const newIndex = Math.max(elementIndex - 1, 0);
        newObjects.splice(newIndex, 0, element);
        break;
      }
      case 'front': {
        // Move to top layer
        newObjects.push(element);
        break;
      }
      case 'back': {
        // Move to bottom layer
        newObjects.unshift(element);
        break;
      }
    }

    if (state.type === 'infinite') {
      this.setState({
        newState: {
          ...state,
          data: {
            ...state.data,
            objects: newObjects,
          },
        },
      });
    } else {
      const pageNumberIndex = this.metadata.pageNumberIndex;
      if (pageNumberIndex !== null) {
        const newPages = [...state.data];
        newPages[pageNumberIndex] = {
          ...newPages[pageNumberIndex],
          objects: newObjects,
        };

        this.setState({
          newState: {
            ...state,
            data: newPages,
          },
        });
      }
    }
  };

  updateLayers = ({
    objectIds,
    action,
  }: {
    objectIds: Array<string>;
    action: 'front' | 'back';
  }) => {
    const state = this.getState();
    if (!state) return;

    let objects: DesignObject[];

    if (state.type === 'pages') {
      const currentPageIndex = this.metadata.pageNumberIndex;
      if (currentPageIndex === null) return;

      objects = state.data[currentPageIndex].objects;
    } else {
      objects = state.data.objects;
    }

    const { selectedObjects, otherObjects } = objects.reduce(
      (acc, obj) => {
        if (objectIds.includes(obj.id)) {
          acc.selectedObjects.push(obj);
        } else {
          acc.otherObjects.push(obj);
        }
        return acc;
      },
      {
        selectedObjects: [] as DesignObject[],
        otherObjects: [] as DesignObject[],
      },
    );

    objects.length = 0;

    if (action === 'front') {
      objects.push(...otherObjects, ...selectedObjects);
    } else {
      objects.push(...selectedObjects, ...otherObjects);
    }

    this.setState({ newState: state });
  };

  /**
   * Passed layered objects layering is what gets applied.
   *
   * @param param0
   * @returns
   */

  applyPassedLayers = ({
    layeredObjects,
  }: {
    layeredObjects: Array<string>;
  }) => {
    const state = this.getState();
    if (!state) return;

    if (state.type === 'pages') {
      // TODO add pages logic
      throw new Error('Implement pages pls');
    }

    const currentObjects = [...state.data.objects];

    // Create a set of IDs from layeredObjects for quick lookup
    const layeredObjectIds = new Set(layeredObjects);

    // Filter out objects that are part of the layering operation
    const otherObjects = currentObjects.filter(
      (obj) => !layeredObjectIds.has(obj.id),
    );

    const matchedLayeredObjects = layeredObjects.map((id) => {
      const matchedObject = currentObjects.find((obj) => obj.id === id);
      if (!matchedObject) {
        throw new Error(`Object with id ${id} not found`);
      }
      return matchedObject;
    });

    // Create the new objects array with layeredObjects at the beginning (back)
    // and other objects at the end (front)
    const newObjects = [...matchedLayeredObjects, ...otherObjects];

    this.setState({
      newState: {
        ...state,
        data: {
          ...state.data,
          objects: newObjects,
        },
      },
    });
  };

  /**
   * Generate thumbnail on update.
   *
   * @param param0
   * @returns
   */
  generateThumbnailsSet = debounce(
    async ({ allPages = false }: { allPages: boolean }) => {
      const state = this.getState();
      if (!state) return;

      // Checks if objects are 0. If so generate white thumbnail and return
      const generatedWhiteThumbnail =
        this.generateSinglePixelThumbnailIfLengthMatches(state, 0);

      if (generatedWhiteThumbnail) return;

      if (isInfinite(state)) {
        // Create a temporary container for this page
        const stageContainer = document.getElementById('studioStageContainer');
        if (!stageContainer) return;

        let infiniteContext: {
          container: HTMLDivElement;
          stage: Stage;
          index: number;
        } | null = null;

        try {
          const { stage, layer, container } = createTempStage({
            width: stageContainer.clientWidth,
            height: stageContainer.clientHeight,
            backgroundColor: '#fff',
            type: 'infinite',
          });

          // Store the context for cleanup later
          const context = {
            container: container,
            stage: stage,
            index: 0,
          };

          infiniteContext = context;
          // Load all objects from the page into the temporary stage
          await loadPageObjectsToStage(layer, state.data.objects);

          const { data } = generatePreview(
            stage,
            'thumbnail',
            'image/jpeg',
            isInfinite(state),
          );
          if (!data || data === 'data:,') {
            console.error('Thumbnail generation failed: Data URL is empty.');
          } else {
            updateDesignThumbnail(state.id, data);
          }
        } catch (error) {
          console.error('Error generating thumbnail:', error);
        } finally {
          // Clean up all temporary elements
          if (infiniteContext) {
            if (infiniteContext.stage) infiniteContext.stage.destroy();
            if (
              infiniteContext.container &&
              document.body.contains(infiniteContext.container)
            ) {
              document.body.removeChild(infiniteContext.container);
            }
          }
        }
      } else {
        const currentPage = this.metadata.pageNumberIndex;
        if (!currentPage && currentPage != 0) return;

        const pageContexts: {
          container: HTMLDivElement;
          stage: Stage;
          index: number;
        }[] = [];

        try {
          const pages = allPages ? state.data : [state.data[currentPage]];
          for (const [index, page] of pages.entries()) {
            // Create a temporary container for this page
            const { container, layer, stage } = createTempStage({
              width: page.metadata.width,
              height: page.metadata.height,
              backgroundColor: page.metadata.backgroundColor,
              type: 'page',
            });

            // Store the context for cleanup later
            const context = {
              container,
              stage,
              index: 0,
            };

            pageContexts.push(context);
            // Load all objects from the page into the temporary stage
            await loadPageObjectsToStage(layer, page.objects);

            const { data } = generatePreview(
              stage,
              'thumbnail',
              'image/jpeg',
              isInfinite(state),
              page,
            );
            if (!data || data === 'data:,') {
              console.error('Thumbnail generation failed: Data URL is empty.');
            } else {
              this.saveThumbnail(allPages ? index : currentPage, data);
              if (index === 0) {
                updateDesignThumbnail(state.id, data);
              }
            }
          }
        } catch (error) {
          console.error('Error generating thumbnail:', error);
        } finally {
          // Clean up all temporary elements
          pageContexts.forEach(({ container, stage }) => {
            if (stage) stage.destroy();
            if (container && document.body.contains(container)) {
              document.body.removeChild(container);
            }
          });
        }
      }
    },
    2000,
  );

  migrateToV1File = async (blob: unknown) => {
    if (this.state?.version === 1) {
      // File already migrated
      return true;
    }

    try {
      const imagesPromise = fetchImages(this.designId);
      const textboxesPromise = fetchTextboxes(this.designId);

      const [images, textboxes] = await Promise.allSettled([
        imagesPromise,
        textboxesPromise,
      ]);

      if (images.status === 'rejected' || textboxes.status === 'rejected') {
        // TODO add failed error issue here
        console.error('Fetching image or text failed');
        return false;
      }

      // Move Images
      const mappedImages: ImageJSON[] = images.value.map((img) => {
        let crop = null;

        if (
          img.studio.crop.left &&
          img.studio.crop.right &&
          img.studio.crop.top &&
          img.studio.crop.bottom
        ) {
          crop = {
            x: img.studio.crop.left,
            y: img.studio.crop.top,
            height: img.studio.crop.bottom,
            width: img.studio.crop.right,
          };
        }

        return {
          id: img.id,
          type: 'image',
          x: img.studio.position_x,
          y: img.studio.position_y,
          rotation: img.studio.position_omega,
          scale: {
            x: 1,
            y: 1,
          },
          opacity: 1,
          metadata: {
            blockId: img.id,
            width: img.studio.position_width || img.width,
            height: img.studio.position_height || img.height,
            originalWidth: img.width,
            originalHeight: img.height,
            file: img.file,
            crop,
            imageType: img.block_type,
          },
        };
      });

      // Move Textboxes
      const mappedText: TextJSON[] = textboxes.value.map((txt) => {
        return {
          id: txt.id,
          type: 'text',
          x: txt.position_x,
          y: txt.position_y,
          rotation: txt.text_format.rotation ?? 0,
          scale: {
            x: 1,
            y: 1,
          },
          opacity: 1,
          metadata: {
            width: txt.width,
            content: txt.text,
            height: 0,
            alignment: txt.text_format.alignment ?? 'left',
            bold: txt.text_format.bold ?? false,
            colour: txt.text_format.colour ?? '#000',
            fontFamily: txt.text_format.fontFamily ?? DEFAULT_FONTFAMILY,
            italic: txt.text_format.italic ?? false,
            underline: txt.text_format.underline ?? false,
            fontSize: txt.text_format.additionalProp1 ?? DEFAULT_FONTSIZE,
          },
        };
      });

      const newState: DesignState = {
        version: 1,
        id: this.designId,
        type: 'infinite',
        data: {
          metadata: {},
          objects: [...mappedImages, ...mappedText],
        },
      };

      if (isBlobOldShapeData(blob)) {
        blob.shapes.forEach((s) => {
          const updatedS = {
            ...s,
            opacity: 1,
            type: 'shape',
          } satisfies ShapeJSON;

          newState.data.objects.push(updatedS);
        });
      } else {
        console.error(
          'Blob shape is incorrect, do not push in new shapes',
          blob,
        );
      }

      this.setState({
        newState,
      });
      return true;
    } catch (e) {
      // TODO what to do with error when migration fails??? Default to a fresh file?
      console.error('Failed migration of design file', e);
      return false;
    }
  };

  private readonly copyObjects = (objectIds: string[]) => {
    if (!this.state) return [];

    const objects = this.getObjectsByIds(objectIds);

    // Store in buffer
    this.updateMetadata({
      bufferObjectData: objects,
    });

    return objects;
  };

  private readonly cutObjects = (objectIds: string[]) => {
    if (!this.state) return [];

    const objects = this.copyObjects(objectIds);

    // Delete the objects after copying
    objectIds.forEach((id) => {
      this.deleteObject(id);
    });

    return objects;
  };

  private readonly pasteObjects = () => {
    if (!this.state) return;

    const objectsToPaste = this.metadata.bufferObjectData;

    if (!objectsToPaste || !objectsToPaste.length) return;

    // Offset for pasted to make it more obvious they are copied
    const offsetX = 20;
    const offsetY = 20;

    // Create new copies of objects with new IDs
    objectsToPaste.forEach((obj) => {
      const newId = generateId('studio_object');
      const newObj = {
        ...obj,
        id: newId,
        x: obj.x + offsetX,
        y: obj.y + offsetY,
      };

      if (this.state!.type === 'infinite') {
        // For infinite canvas
        const currentState = this.state!;
        const newState: DesignState = {
          id: currentState.id,
          version: currentState.version,
          type: 'infinite',
          data: {
            ...currentState.data,
            objects: [...currentState.data.objects, newObj],
          },
        };
        this.setState({ newState });
      } else if (
        this.state!.type === 'pages' &&
        this.metadata.pageNumberIndex !== null
      ) {
        // For pages mode
        const currentState = this.state!;
        const pageIndex = this.metadata.pageNumberIndex;
        const pages = [...currentState.data];
        const page = { ...pages[pageIndex] };

        page.objects = [...page.objects, newObj];
        pages[pageIndex] = page;

        const newState: DesignState = {
          id: currentState.id,
          version: currentState.version,
          type: 'pages',
          data: pages,
        };
        this.setState({ newState });
      }
    });
  };

  private readonly getObjectsByIds = (
    objectIds: string[],
  ): (ImageJSON | TextJSON | ShapeJSON)[] => {
    if (!this.state) return [];

    let allObjects: (ImageJSON | TextJSON | ShapeJSON)[] = [];

    if (this.state.type === 'infinite') {
      allObjects = this.state.data.objects;
    } else if (
      this.state.type === 'pages' &&
      this.metadata.pageNumberIndex !== null
    ) {
      const pageIndex = this.metadata.pageNumberIndex;
      allObjects = this.state.data[pageIndex].objects;
    }

    return allObjects.filter((obj) => objectIds.includes(obj.id));
  };

  undo = () => {
    const newState = this.undoRedoStack.undo(this.state);

    if (newState === null) return;

    this.setState({
      newState,
      nonCommitingAction: true,
    });
  };

  redo = () => {
    const newState = this.undoRedoStack.redo(this.state);

    if (newState === null) return;

    this.setState({
      newState,
      nonCommitingAction: true,
    });
  };

  /**
   * @group Design Updates
   * Add/remove elements from group
   */

  addToGroup = (objectIds: Set<string>) => {
    this.groupObjects(objectIds);
  };

  removeFromGroup = (objectIds: Set<string>) => {
    this.ungroupObjects(objectIds);
  };

  private readonly groupObjects = (objectIds: Set<string>) => {
    if (!this.state) throw new Error('No design file loaded into state');

    const groupId = generateId('studio_group');

    const updateMetadata = (obj: DesignObject): DesignObject => {
      if (!objectIds.has(obj.id)) return obj;

      if (obj.type === 'text') {
        return {
          ...obj,
          metadata: {
            ...obj.metadata,
            groupId,
          },
        };
      }

      if (obj.type === 'image') {
        return {
          ...obj,
          metadata: {
            ...obj.metadata,
            groupId,
          },
        };
      }

      if (obj.type === 'shape') {
        return {
          ...obj,
          metadata: {
            ...obj.metadata,
            groupId,
          },
        };
      }

      return obj;
    };

    if (isInfinite(this.state)) {
      const stateClone = structuredClone(this.state);
      stateClone.data.objects = this.state.data.objects.map(updateMetadata);
      this.setState({
        newState: stateClone,
      });
    } else {
      if (this.metadata.pageNumberIndex === null)
        throw new Error('No page number associated?');

      const stateClone = structuredClone(this.state);
      const pageObjects =
        stateClone.data[this.metadata.pageNumberIndex].objects;
      const updatedObjects = pageObjects.map(updateMetadata);
      stateClone.data[this.metadata.pageNumberIndex].objects = updatedObjects;
      this.setState({
        newState: stateClone,
      });
    }
  };

  private readonly ungroupObjects = (elementIds: Set<string>) => {
    if (!this.state) throw new Error('No design file loaded into state');

    const clearGroupId = (obj: DesignObject): DesignObject => {
      if (!elementIds.has(obj.id)) return obj;

      if (obj.type === 'text') {
        const metadata = { ...obj.metadata };
        delete metadata.groupId;
        return {
          ...obj,
          metadata,
        };
      }

      if (obj.type === 'image') {
        const metadata = { ...obj.metadata };
        delete metadata.groupId;
        return {
          ...obj,
          metadata,
        };
      }

      if (obj.type === 'shape') {
        const metadata = { ...obj.metadata };
        delete metadata.groupId;
        return {
          ...obj,
          metadata,
        };
      }

      return obj;
    };

    if (isInfinite(this.state)) {
      const stateClone = structuredClone(this.state);
      stateClone.data.objects = stateClone.data.objects.map(clearGroupId);
      this.setState({ newState: stateClone });
    } else {
      if (this.metadata.pageNumberIndex === null)
        throw new Error('No page number associated?');

      const stateClone = structuredClone(this.state);
      const pageObjects =
        stateClone.data[this.metadata.pageNumberIndex].objects;
      stateClone.data[this.metadata.pageNumberIndex].objects =
        pageObjects.map(clearGroupId);
      this.setState({ newState: stateClone });
    }
  };

  getGroupObjectsByObjectId = (objectId: string) => {
    if (!this.state) throw new Error('No design file loaded into state');

    let objects: DesignObject[] = [];

    if (isInfinite(this.state)) {
      objects = this.state.data.objects;
    } else {
      if (this.metadata.pageNumberIndex === null)
        throw new Error('No page number associated?');

      objects = this.state.data[this.metadata.pageNumberIndex].objects;
    }

    const target = objects.find((obj) => obj.id === objectId);
    if (!target || !target.metadata?.groupId) return [objectId];

    const groupId = target.metadata.groupId;

    return objects
      .filter((obj) => obj.metadata?.groupId === groupId)
      .map((obj) => obj.id);
  };

  isAnyGrouped = (objectIds: Set<string>) => {
    if (!this.state) throw new Error('No design file loaded into state');

    let objects: DesignObject[] = [];

    if (isInfinite(this.state)) {
      objects = this.state.data.objects;
    } else {
      if (this.metadata.pageNumberIndex === null)
        throw new Error('No page number associated?');

      objects = this.state.data[this.metadata.pageNumberIndex].objects;
    }

    return objects.some(
      (obj) => objectIds.has(obj.id) && !!obj.metadata?.groupId,
    );
  };

  areAllInSameGroup = (objectIds: Set<string>): boolean => {
    if (!this.state) throw new Error('No design file loaded into state');

    let objects: DesignObject[] = [];

    if (isInfinite(this.state)) {
      objects = this.state.data.objects;
    } else {
      if (this.metadata.pageNumberIndex === null)
        throw new Error('No page number associated?');

      objects = this.state.data[this.metadata.pageNumberIndex].objects;
    }

    let sharedGroupId: string | null = null;
    for (const id of objectIds) {
      const obj = objects.find((o) => o.id === id);
      const groupId = obj?.metadata?.groupId;

      if (!groupId) return false; // missing groupId

      if (sharedGroupId === null) {
        sharedGroupId = groupId; // set first groupId
      } else if (sharedGroupId !== groupId) {
        return false; // mismatch
      }
    }

    return true; // all have same groupId
  };
}

const isInfinite = (
  state: DesignState,
): state is DesignState & { type: 'infinite' } => {
  return state.type === 'infinite';
};

export type DesignType = InstanceType<typeof Design>;
