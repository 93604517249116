/**
 * Code for snapping adapted from official konva docs: https://konvajs.org/docs/sandbox/Objects_Snapping.html
 */

import { RefObject, useCallback, useState } from 'react';

import Konva from 'konva';
import { KonvaEventObject, Node } from 'konva/lib/Node';
import { Stage } from 'konva/lib/Stage';

import {
  IMAGE_GROUP,
  PAGE_GROUP_RECT,
  SHAPE,
  TEXT,
} from '@pages/StudioPage/constants';
import { isGroupNode, isTransformerNode } from '@pages/StudioPage/utils';

type Snap = 'start' | 'center' | 'end';

export const useSnap = ({
  usePageGuides = false,
}: {
  usePageGuides?: boolean;
}) => {
  const [enableSnapping] = useState(true);

  const onMoveAnchorSnap = useCallback(
    (
      oldPos: { x: number; y: number },
      newPos: { x: number; y: number },
      groupRef: RefObject<Konva.Node>,
    ) => {
      const group = groupRef.current;

      if (!group) return newPos;

      const stage = group.getStage();
      if (!stage) return newPos;

      const layer = group.getLayer();
      if (!layer) return newPos;

      // Clear any existing guide lines
      const lines = layer.find('.guide-line');
      if (lines) lines.forEach((l) => l.destroy());

      // Use existing guide lines for snapping
      const lineGuideStops = getLineGuideStops(stage, group, usePageGuides);

      // Find the closest vertical and horizontal guides
      const closestVertical = lineGuideStops.vertical
        .map((guide) => ({ guide, diff: Math.abs(newPos.x - guide) }))
        .sort((a, b) => a.diff - b.diff)[0];

      const closestHorizontal = lineGuideStops.horizontal
        .map((guide) => ({ guide, diff: Math.abs(newPos.y - guide) }))
        .sort((a, b) => a.diff - b.diff)[0];

      const snappedX = closestVertical && closestVertical.diff < 10;
      const snappedY = closestHorizontal && closestHorizontal.diff < 10;

      // Draw guide lines if snapping
      if (snappedX) {
        drawGuides(
          [
            {
              lineGuide: closestVertical.guide,
              offset: 0,
              orientation: 'V',
              snap: 'start',
            },
          ],
          layer,
        );
      }

      if (snappedY) {
        drawGuides(
          [
            {
              lineGuide: closestHorizontal.guide,
              offset: 0,
              orientation: 'H',
              snap: 'start',
            },
          ],
          layer,
        );
      }

      if (snappedX && !snappedY) {
        return {
          x: closestVertical.guide,
          y: oldPos.y,
        };
      } else if (snappedY && !snappedX) {
        return {
          x: oldPos.x,
          y: closestHorizontal.guide,
        };
      } else if (snappedX && snappedY) {
        return {
          x: closestVertical.guide,
          y: closestHorizontal.guide,
        };
      }

      return newPos;
    },
    [getLineGuideStops, drawGuides],
  );

  const onMoveCheckSnap = useCallback(
    (e: Konva.KonvaEventObject<Event>) => {
      if (!enableSnapping) {
        // Call end drag check to make sure no lines are rendereds
        onEndCheckSnap(e);
        return;
      }

      const layer = e.target.getLayer();

      if (!layer) return;

      // clear all previous lines on the screen
      const lines = layer.find('.guide-line');

      if (lines) lines.forEach((l) => l.destroy());

      // find possible snapping lines
      const lineGuideStops = getLineGuideStops(
        e.target.getStage(),
        e.currentTarget,
        usePageGuides,
      );
      // find snapping points of current object
      const itemBounds = getObjectSnappingEdges(e.target);

      // now find where can we snap current object
      const guides = getGuides(lineGuideStops, itemBounds);

      // do nothing if no snapping
      if (!guides.length) {
        return;
      }

      drawGuides(guides, layer);

      // Update position while preserving scale and rotation
      const absPos = e.target.absolutePosition();
      guides.forEach((lg) => {
        switch (lg.snap) {
          case 'start':
          case 'center':
          case 'end': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
            }
            break;
          }
        }
      });

      e.target.absolutePosition(absPos);
    },
    [drawGuides, getGuides, getObjectSnappingEdges, enableSnapping],
  );

  const onEndCheckSnap = useCallback(
    (e: KonvaEventObject<Event>) => {
      const layer = e.target.getLayer();

      if (!layer) return;

      // clear all previous lines on the screen
      const lines = layer.find('.guide-line');

      if (lines) lines.forEach((l) => l.destroy());
    },
    [enableSnapping],
  );

  return {
    onMoveCheckSnap,
    onEndCheckSnap,
    onMoveAnchorSnap,
  };
};

/**
 * Find elements to use for snapping
 *
 * @param stage
 * @param currentTarget
 * @returns
 */

const getLineGuideStops = (
  stage: Stage | null,
  currentTarget: Node,
  usePageGuides: boolean,
) => {
  if (!stage) return { vertical: [], horizontal: [] };

  const vertical: number[] = [];
  const horizontal: number[] = [];

  if (usePageGuides) {
    const [pageGroup] = stage.find(`.${PAGE_GROUP_RECT}`);

    if (!pageGroup) return { vertical, horizontal };

    const box = pageGroup.getClientRect();
    // Top, Middle, Bottom
    vertical.push(0, box.x, box.x + box.width / 2, box.x + box.width);
    // Left, Center, Right
    horizontal.push(0, box.y, box.y + box.height / 2, box.y + box.height);
  }

  // TODO make this search and snap on any layer so dont need to manually add the types of things that can be snapped.

  // Search for images layers
  // and we snap over edges and center of each object on the canvas
  stage.find(`.${IMAGE_GROUP}`).forEach((guideItem) => {
    // Images & textboxes are inside groups when dragged so iterate through the group to check its children

    // If node is a transformer (happens when transforming anchors) check its connected child node
    if (isTransformerNode(guideItem)) {
      const nodes = guideItem.getNodes();
      if (nodes.length === 0) return;
      const node = nodes[0];
      if (node.id() === currentTarget.id()) return;
    }

    if (guideItem.id() === currentTarget.id()) return;

    if (isGroupNode(currentTarget)) {
      const hasMatchingChild = currentTarget.children.some(
        (child) => child.id() === guideItem.id(),
      );

      if (hasMatchingChild) return;
    }

    const box = guideItem.getClientRect();
    // and we can snap to all edges of shapes
    vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
    horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
  });

  // Search for text layers
  stage.find(`.${TEXT}`).forEach((guideItem) => {
    // Images & textboxes are inside groups when dragged so iterate through the group to check its children
    if (guideItem.id() === currentTarget.id() || isTransformerNode(guideItem))
      return;

    if (isGroupNode(currentTarget)) {
      const hasMatchingChild = currentTarget.children.some(
        (child) => child.id() === guideItem.id(),
      );

      if (hasMatchingChild) return;
    }

    const box = guideItem.getClientRect();
    // and we can snap to all edges of shapes
    vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
    horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
  });

  // Search for shapes
  stage.find(`.${SHAPE}`).forEach((guideItem) => {
    if (guideItem.id() === currentTarget.id() || isTransformerNode(guideItem))
      return;

    const box = guideItem.getClientRect();
    // and we can snap to all edges of shapes
    vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
    horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
  });

  return {
    vertical,
    horizontal,
  };
};

/**
 * Gets the snapping edges for a node by calculating its bounding box and absolute position.
 * Returns vertical and horizontal guides with start, center and end points for snapping.
 *
 * @param {Node} node The Konva node to get snapping edges for
 * @returns Object containing vertical and horizontal snapping guides with offsets
 */

function getObjectSnappingEdges(node: Node) {
  const box = node.getClientRect();
  const absPos = node.absolutePosition();

  return {
    vertical: [
      {
        guide: Math.round(box.x),
        offset: Math.round(absPos.x - box.x),
        snap: 'start' as const,
      },
      {
        guide: Math.round(box.x + box.width / 2),
        offset: Math.round(absPos.x - box.x - box.width / 2),
        snap: 'center' as const,
      },
      {
        guide: Math.round(box.x + box.width),
        offset: Math.round(absPos.x - box.x - box.width),
        snap: 'end' as const,
      },
    ],
    horizontal: [
      {
        guide: Math.round(box.y),
        offset: Math.round(absPos.y - box.y),
        snap: 'start' as const,
      },
      {
        guide: Math.round(box.y + box.height / 2),
        offset: Math.round(absPos.y - box.y - box.height / 2),
        snap: 'center' as const,
      },
      {
        guide: Math.round(box.y + box.height),
        offset: Math.round(absPos.y - box.y - box.height),
        snap: 'end' as const,
      },
    ],
  };
}

const GUIDELINE_OFFSET = 5;

/**
 * Gets the snapping guides by comparing the line guide stops with the item bounds.
 * For each vertical and horizontal line guide, checks if any item bounds are within
 * the GUIDELINE_OFFSET threshold. If so, creates a guide with the line position,
 * distance to item bound, snap type (start/center/end), and offset.
 *
 * @param {ReturnType<typeof getLineGuideStops>} lineGuideStops The possible snapping lines for the stage
 * @param {ReturnType<typeof getObjectSnappingEdges>} itemBounds The snapping edges of the current object
 * @returns Array of guides with lineGuide position, offset, snap type and orientation
 */

function filterNearbyGuides(
  guides: Array<{
    lineGuide: number;
    diff: number;
    snap: Snap;
    offset: number;
    objectDistance?: number;
  }>,
) {
  // Make threshold more aggressive
  const PROXIMITY_THRESHOLD = GUIDELINE_OFFSET;

  if (guides.length === 0) return [];

  // Sort guides by objectDistance first, then by lineGuide position
  const sortedGuides = [...guides].sort((a, b) => {
    const distanceDiff = (a.objectDistance || 0) - (b.objectDistance || 0);
    if (distanceDiff !== 0) return distanceDiff;
    return a.lineGuide - b.lineGuide;
  });

  const filteredGuides: typeof guides = [];
  let lastAcceptedGuide = sortedGuides[0];
  filteredGuides.push(lastAcceptedGuide);

  for (let i = 1; i < sortedGuides.length; i++) {
    const currentGuide = sortedGuides[i];

    // Check distance from ALL previously accepted guides
    const isFarEnough = filteredGuides.every((acceptedGuide) => {
      const distance = Math.abs(
        acceptedGuide.lineGuide - currentGuide.lineGuide,
      );
      return distance >= PROXIMITY_THRESHOLD;
    });

    if (isFarEnough) {
      filteredGuides.push(currentGuide);
      lastAcceptedGuide = currentGuide;
    }
  }

  return filteredGuides;
}

const getGuides = (
  lineGuideStops: ReturnType<typeof getLineGuideStops>,
  itemBounds: ReturnType<typeof getObjectSnappingEdges>,
) => {
  const resultV: Array<{
    lineGuide: number;
    diff: number;
    snap: Snap;
    offset: number;
    objectDistance?: number;
  }> = [];

  const resultH: Array<{
    lineGuide: number;
    diff: number;
    snap: Snap;
    offset: number;
    objectDistance?: number;
  }> = [];

  // First pass: collect all potential guides with improved distance calculation
  lineGuideStops.vertical.forEach((lineGuide) => {
    itemBounds.vertical.forEach((itemBound) => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        resultV.push({
          lineGuide: lineGuide,
          diff: diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
          objectDistance: diff, // Use the actual difference as the distance
        });
      }
    });
  });

  lineGuideStops.horizontal.forEach((lineGuide) => {
    itemBounds.horizontal.forEach((itemBound) => {
      const diff = Math.abs(lineGuide - itemBound.guide);
      if (diff < GUIDELINE_OFFSET) {
        resultH.push({
          lineGuide: lineGuide,
          diff: diff,
          snap: itemBound.snap,
          offset: itemBound.offset,
          objectDistance: diff, // Use the actual difference as the distance
        });
      }
    });
  });

  // Filter guides
  const filteredV = filterNearbyGuides(resultV);
  const filteredH = filterNearbyGuides(resultH);

  return [
    ...filteredV.map((guide) => ({
      lineGuide: guide.lineGuide,
      offset: guide.offset,
      orientation: 'V' as const,
      snap: guide.snap,
    })),
    ...filteredH.map((guide) => ({
      lineGuide: guide.lineGuide,
      offset: guide.offset,
      orientation: 'H' as const,
      snap: guide.snap,
    })),
  ];
};

/**
 * Draw guide lines on the layer based on the calculated snap guides
 *
 * @param {ReturnType<typeof getGuides>} guides The calculated snap guides
 * @param {Konva.Layer} layer The Konva layer to draw the guides on
 */

const drawGuides = (
  guides: ReturnType<typeof getGuides>,
  layer: Konva.Layer,
) => {
  guides.forEach((lg) => {
    if (lg.orientation === 'H') {
      const line = new Konva.Line({
        points: [-600000, 0, 600000, 0],
        stroke: 'red',
        strokeWidth: 2,
        name: 'guide-line',
      });
      layer.add(line);
      line.absolutePosition({
        x: 0,
        y: lg.lineGuide,
      });
    } else if (lg.orientation === 'V') {
      const line = new Konva.Line({
        points: [0, -600000, 0, 600000],
        stroke: 'red',
        strokeWidth: 2,
        name: 'guide-line',
      });
      layer.add(line);
      line.absolutePosition({
        x: lg.lineGuide,
        y: 0,
      });
    }
  });
};
