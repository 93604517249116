import { ReactNode } from 'react';

import cn from 'classnames';

import {
  Avatar,
  Badge,
  FilterChip,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Member } from '@api/docs';
import { Member as MemberHub } from '@api/hubs';
import { Permissions as PermissionsType } from '@api/users';
import { useAppData } from '@src/AppContext';
import {
  renderUserInitials,
  renderUserNameOrEmail,
} from '@src/shared/utils/names';

import { Permissions } from '../../ui/permissions';

import styles from './styles.module.css';

type FilterChipsProps = {
  user: Member | MemberHub;
  isShowMenu: boolean;
  setShowMenu: React.Dispatch<boolean>;
  getPermissions?: (permission?: any) => ReactNode;
  isDisabled: (
    currentPermissions: PermissionsType[],
    targetPermissions: PermissionsType[],
  ) => boolean;
  permissions: PermissionsType[];
  currentPermissions?: PermissionsType[];
  lastEditorId?: string | number | null;
  type?: 'doc';
  ownerId: number;
};

type SwitchProps = {
  user: Member | MemberHub;
  on: boolean;
  invite?: () => void;
  remove?: () => void;
  isDisabled: (
    currentPermissions: PermissionsType[],
    targetPermissions: PermissionsType[],
  ) => boolean;
  permissions: PermissionsType[];
  currentPermissions?: PermissionsType[];
  isLastMember?: boolean;
  lastEditorId?: string | number | null;
  type?: 'doc';
  ownerId: number;
};

type UserProps = {
  user: Member | MemberHub;
  isShowMenu: boolean;
  setShowMenu: React.Dispatch<boolean>;
  changePermissions: (permissions: PermissionsType[]) => void;
  invite?: () => void;
  remove?: () => void;
  on: boolean;
  permissions: PermissionsType[];
  currentPermissions?: PermissionsType[];
  permissionsRef: React.RefObject<HTMLDivElement>;
  getPermissions?: (permission: any) => ReactNode;
  type?: 'doc';
  isLastMember?: boolean;
  lastEditorId?: string | number | null;
};

export const User = ({
  user,
  isShowMenu,
  setShowMenu,
  changePermissions,
  invite,
  remove,
  on,
  permissions,
  permissionsRef,
  getPermissions,
  type,
  currentPermissions,
  isLastMember,
  lastEditorId,
}: UserProps) => {
  const {
    user: { id },
  } = useAppData();

  const isDisabled = (
    currentPermissions: PermissionsType[],
    targetPermissions: PermissionsType[],
  ) => {
    const permissionPriority = {
      edit: 3,
      comment: 2,
      invite: 1,
    };

    const getMaxPriority = (permissions: PermissionsType[]) => {
      return Math.max(...permissions.map((p) => permissionPriority[p] || 0));
    };

    const currentPriority = getMaxPriority(currentPermissions);
    const targetPriority = getMaxPriority(targetPermissions);

    if (currentPriority === 3) return false;
    if (currentPriority === 2) {
      return !(targetPriority === 2 || targetPriority === 1);
    }
    if (currentPriority === 1) return !(targetPriority === 1);

    return true;
  };

  return (
    <li key={user.id} className={styles.user}>
      <div
        className={cn(styles.nameOrEmail, {
          [styles.showMenu]: isShowMenu,
        })}
      >
        <TooltipRadix
          key={user.id}
          classNameTrigger={styles.tooltip}
          type="rich"
          title={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '12px',
              }}
            >
              <Avatar
                key={user.id}
                image={user.user?.photo?.full_size}
                initials={`${user.user?.first_name ?? ''} ${
                  user.user?.last_name ?? ''
                }`}
                size={40}
              />
              {user.user
                ? `${user.user.first_name} ${user.user.last_name}`
                : undefined}
            </span>
          }
          description={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                marginBottom: '8px',
              }}
            >
              <Icon
                name="sprite/at-sign"
                style={{ paddingLeft: '8px' }}
                color="var(--color-neutral-variant-30)"
              />
              {user.email}
            </span>
          }
          descriptionSize="S"
          side="bottom"
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Avatar
              image={user.user?.photo?.full_size}
              initials={renderUserInitials({
                first_name: user.user ? user.user.first_name : null,
                last_name: user.user ? user.user.last_name : null,
                email: user.email,
              })}
              size={20}
            />
            <TypographyPoppins
              className={styles.nameTitle}
              type="body"
              size="S"
            >
              {renderUserNameOrEmail({
                first_name: user.user ? user.user.first_name : null,
                last_name: user.user ? user.user.last_name : null,
                email: user.email,
              })}
            </TypographyPoppins>
          </span>
        </TooltipRadix>
        {user.is_owner && <Badge style="text" text="Owner" />}
        {!user.is_owner && !user.user && user.permissions ? (
          <Badge style="text" text="Pending" />
        ) : null}
      </div>
      <div className={styles.actions}>
        <div ref={permissionsRef} className={styles.permissions}>
          <Popover open={isShowMenu}>
            <PopoverTrigger className={styles.popover}>
              <FilterChips
                user={user}
                isShowMenu={isShowMenu}
                setShowMenu={setShowMenu}
                getPermissions={getPermissions}
                isDisabled={isDisabled}
                permissions={permissions}
                currentPermissions={currentPermissions}
                lastEditorId={lastEditorId}
                type={type}
                ownerId={id}
              />
            </PopoverTrigger>
            <PopoverContent side="top" align="end">
              <Permissions
                permissions={permissions}
                permissionsSelected={changePermissions}
                closeMenu={() => setShowMenu(false)}
                type={type}
                currentPermissions={currentPermissions}
              />
            </PopoverContent>
          </Popover>
        </div>
        <Switchs
          user={user}
          on={on}
          invite={invite}
          remove={remove}
          isDisabled={isDisabled}
          permissions={permissions}
          currentPermissions={currentPermissions}
          isLastMember={isLastMember}
          lastEditorId={lastEditorId}
          type={type}
          ownerId={id}
        />
      </div>
    </li>
  );
};

const FilterChips = ({
  user,
  isShowMenu,
  setShowMenu,
  getPermissions,
  isDisabled,
  permissions,
  currentPermissions,
  lastEditorId,
  type,
  ownerId,
}: FilterChipsProps) => {
  return (
    <>
      {/* Owner */}
      {user.is_owner && user.permissions && (
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={<Icon name="sprite/caret-down" size={16} />}
          type="label"
          size="S"
          isDisabled={
            type !== 'doc' &&
            (isDisabled(currentPermissions ?? [], permissions) ||
              (user.user && user.user.id === ownerId && !user.board) ||
              (user.is_owner && !user.board) ||
              lastEditorId === user.id)
          }
          onClick={() => setShowMenu(!isShowMenu)}
        >
          {getPermissions && getPermissions(user)}
        </FilterChip>
      )}

      {/* Invited users */}
      {!user.is_owner && user.permissions && (
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={<Icon name="sprite/caret-down" size={16} />}
          type="label"
          size="S"
          isSelected={isShowMenu}
          onClick={() => setShowMenu(!isShowMenu)}
          isDisabled={
            type !== 'doc' &&
            (isDisabled(currentPermissions ?? [], permissions) ||
              (user.user &&
                user.user.id === ownerId &&
                user.is_owner &&
                !user.board) ||
              lastEditorId === user.id)
          }
        >
          {getPermissions && getPermissions(user)}
        </FilterChip>
      )}

      {/* Existing users and guest seats */}
      {!user.permissions && (
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={<Icon name="sprite/caret-down" size={16} />}
          type="label"
          size="S"
          isSelected={isShowMenu}
          onClick={() => setShowMenu(!isShowMenu)}
        >
          {getPermissions && getPermissions()}
        </FilterChip>
      )}
    </>
  );
};

const Switchs = ({
  user,
  on,
  invite,
  remove,
  isDisabled,
  permissions,
  currentPermissions,
  isLastMember,
  lastEditorId,
  type,
  ownerId,
}: SwitchProps) => {
  return (
    <>
      {/* Owner */}
      {user.is_owner && user.permissions && (
        <Switch
          onValueChange={() => {
            if (remove) {
              remove();
            }
          }}
          value={on}
          disable={
            type !== 'doc' &&
            (isDisabled(currentPermissions ?? [], permissions) ||
              (user.user && user.user.id === ownerId && !user.board) ||
              (user.is_owner && !user.board) ||
              isLastMember ||
              lastEditorId === user.id)
          }
        />
      )}

      {/* Invited users */}
      {!user.is_owner && user.permissions && (
        <TooltipRadix description="Remove">
          <Switch
            onValueChange={() => {
              if (remove) {
                remove();
              }
            }}
            value={on}
            disable={
              type !== 'doc' &&
              (isDisabled(currentPermissions ?? [], permissions) ||
                (user.user && user.user.id === ownerId && user.is_owner) ||
                isLastMember ||
                lastEditorId === user.id)
            }
          />
        </TooltipRadix>
      )}

      {/* Existing users and guest seats */}
      {!user.permissions && (
        <TooltipRadix description="Invite">
          <Switch
            onValueChange={() => {
              if (invite) {
                invite();
              }
            }}
            value={on}
          />
        </TooltipRadix>
      )}
    </>
  );
};
