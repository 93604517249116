import { createEvent, createStore, sample } from 'effector';

import { paletteCleared } from './palette/model';
import {
  adjustPaletteSelected,
  resetAllOptions,
} from './palette-options/model/options-managment';

export const fileCardIdSelected = createEvent<string | null>();
export const fileCardIdUnselected = createEvent();

export const fileCardDotMenuOpened = createEvent();
export const fileCardDotMenuClosed = createEvent();

export const searchBoardsModalWindowOpened = createEvent();
export const searchBoardsModalWindowClosed = createEvent();

export const searchQueryStarted = createEvent<string>();
export const searchQueryFinished = createEvent();

export const enabledStickyMode = createEvent();
export const disabledStickyMode = createEvent();
export const changedStickyMode = createEvent<boolean | null>();
export const openedPanel = createEvent<'sticky' | 'colour' | 'palette'>();
export const toggledPanel = createEvent<'sticky' | 'colour' | 'palette'>();
export const closedPanel = createEvent();

export const $fileCardId = createStore<string | null>(null);
export const $isOpenFileCardDotMenu = createStore(false);

export const $isOpenSearchBoardsModalWindow = createStore(false);
export const $searchQuery = createStore('');
export const $isStickyModeOn = createStore(false);
export const $openPanel = createStore<null | 'sticky' | 'colour' | 'palette'>(
  'palette',
);

$fileCardId.on(fileCardIdSelected, (_, id) => id).reset(fileCardIdUnselected);

export const $isFileCardOpen = $fileCardId.map((id) => {
  if (id) {
    return true;
  } else {
    return false;
  }
});

$isOpenFileCardDotMenu
  .on(fileCardDotMenuOpened, (isOpen) => !isOpen)
  .reset(fileCardDotMenuClosed);

$isOpenSearchBoardsModalWindow
  .on(searchBoardsModalWindowOpened, () => true)
  .reset([searchBoardsModalWindowClosed, fileCardIdUnselected]);

$searchQuery
  .on(searchQueryStarted, (_, query) => query)
  .reset([searchQueryFinished, searchBoardsModalWindowClosed]);

$isStickyModeOn
  .on(enabledStickyMode, () => true)
  .on(changedStickyMode, (currentMode, newMode) =>
    newMode === null ? !currentMode : newMode,
  )
  .reset([closedPanel, fileCardIdUnselected, disabledStickyMode]);

$openPanel
  .on(openedPanel, (_, panel) => panel)
  .on(toggledPanel, (currentPanel, toggledPanel) =>
    currentPanel === toggledPanel ? null : toggledPanel,
  )
  .reset([closedPanel, fileCardIdUnselected]);

sample({
  clock: [openedPanel, toggledPanel],
  source: { isStickyModeOn: $isStickyModeOn },
  filter: ({ isStickyModeOn }, panel) => isStickyModeOn && panel !== 'sticky',
  target: disabledStickyMode,
});

sample({
  clock: [openedPanel, toggledPanel],
  target: adjustPaletteSelected,
});

sample({
  clock: $openPanel,
  filter: (openPanel) => openPanel !== 'palette',
  target: resetAllOptions,
});

sample({
  clock: [enabledStickyMode, changedStickyMode],
  source: { openPanel: $openPanel },
  filter: ({ openPanel }) => openPanel !== 'sticky',
  fn: () => null,
  target: $openPanel,
});

// Clear the palette state when closing a file
sample({
  source: { isFileCardOpen: $isFileCardOpen },
  filter: ({ isFileCardOpen }) => !isFileCardOpen,
  target: paletteCleared,
});
