import { Stage } from 'konva/lib/Stage';

import { File as ImageFile } from '@pages/StudioPage/api';
import { SelectedImage } from '@pages/StudioPage/components/Library/model';
import {
  centeredWidthHeightForImage,
  generateFormDataForUpload,
  loadImageFromFile,
} from '@pages/StudioPage/utils';

import { useImages } from '../useImages';

const DEFAULT_DROPPED_WIDTH = 900;

export const useDrop = ({
  designId,
  stageRef,
}: {
  designId: string;
  stageRef: React.MutableRefObject<Stage | null>;
}) => {
  const { imageUploadMutation, existingImageUploadMutation } = useImages({
    designId,
  });

  const droppedImageFileFromLibrary = async ({
    e,
    images,
  }: {
    e: React.DragEvent<HTMLDivElement>;
    images: SelectedImage[];
  }) => {
    if (!stageRef || !stageRef.current) return;

    const files: ImageFile[] = [];

    const konvaStage = stageRef.current;

    const imageX =
      (e.nativeEvent.offsetX - konvaStage.x()) / konvaStage.scaleX();
    const imageY =
      (e.nativeEvent.offsetY - konvaStage.y()) / konvaStage.scaleY();

    if (images) {
      for (const image of images) {
        const newWidth = DEFAULT_DROPPED_WIDTH;
        const newHeight =
          (newWidth * image.ref.naturalHeight) / image.ref.naturalWidth;

        const { x, y } = centeredWidthHeightForImage({
          x: imageX,
          y: imageY,
          width: newWidth,
          height: newHeight,
        });

        files.push({
          file: image.id,
          position: {
            position_x: Math.trunc(x),
            position_y: Math.trunc(y),
            position_width: newWidth,
            position_height: newHeight,
            position_omega: 0,
            position_lock: false,
          },
        });
      }
    }

    if (files.length > 0) {
      await existingImageUploadMutation.mutateAsync({ id: designId, files });
    }
  };

  const droppedImageFile = async (e: React.DragEvent<HTMLDivElement>) => {
    // TODO ADD IMAGE CANVAS
    e.preventDefault();

    if (!stageRef || !stageRef.current) return;

    const konvaStage = stageRef.current;

    const forms: FormData[] = [];
    const imageX =
      (e.nativeEvent.offsetX - konvaStage.x()) / konvaStage.scaleX();
    const imageY =
      (e.nativeEvent.offsetY - konvaStage.y()) / konvaStage.scaleY();
    let containsNonImage = false;

    let file: File | null = null;

    let shouldHideFromLibrary = true;

    let height = undefined;
    let width = undefined;

    // File being uploaded
    file = e.dataTransfer.files[0];

    if (!file) return;

    const image = await loadImageFromFile(file);
    height = image.height;
    width = image.width;

    shouldHideFromLibrary = false;

    if (file && file.type.includes('image')) {
      const newWidth = DEFAULT_DROPPED_WIDTH;
      const newHeight = (newWidth * height) / width;

      const { x, y } = centeredWidthHeightForImage({
        x: imageX,
        y: imageY,
        width: newWidth,
        height: newHeight,
      });

      const form = generateFormDataForUpload({
        file,
        width: newWidth || width,
        height: newHeight || height,
        positionX: x,
        positionY: y,
        id: `${width}-${height}-${x}-${y}`,
        set: designId,
        isHiddenFromLibrary: shouldHideFromLibrary,
      });

      forms.push(form);
    } else {
      containsNonImage = true;
    }

    if (containsNonImage) {
      // Add Error Notification
    }

    if (forms.length > 0) {
      await imageUploadMutation.mutateAsync({ forms });
    }
  };

  return {
    droppedImageFileFromLibrary,
    droppedImageFile,
  };
};
