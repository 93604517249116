import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import { $archiveBoardId } from '@pages/BoardPage/model/archive';
import { useArchiveBoard } from '@pages/BoardPage/model/queries/use-archive-board';
import { $archiveFileIds } from '@pages/FileCard/model/archive';
import { useArchiveFile } from '@pages/FileCard/model/queries/use-archive-file';
import { $archiveHubId } from '@pages/HubPage/model/archive';
import { useArchiveHub } from '@pages/HubPage/model/queries/use-archive-hub';
import { $archiveDocId } from '@src/entities/doc/archive';
import { useArchiveDoc } from '@src/entities/doc/queries/use-archive-doc';
import { $entityType } from '@src/shared/utils/get-entity-type';

import { $isOpenArchiveDialog, archiveDialogClosed } from './model';

export const ArchiveDialog = () => {
  const isOpenArchiveDialog = useUnit($isOpenArchiveDialog);
  const entityType = useUnit($entityType);
  const archiveHubId = useUnit($archiveHubId);
  const archiveBoardId = useUnit($archiveBoardId);
  const archiveDocId = useUnit($archiveDocId);
  const archiveFileIds = useUnit($archiveFileIds);

  const { archiveHubMutation } = useArchiveHub();
  const { archiveBoardMutation } = useArchiveBoard();
  const { archiveDocMutation } = useArchiveDoc();
  const { archiveFileMutation } = useArchiveFile();

  const type =
    entityType === 'image' ||
    entityType === 'design' ||
    entityType === 'palette'
      ? 'file'
      : entityType;

  const description =
    entityType === 'hub'
      ? `Do you want to archive this hub?

We will stop billing you for this hub and refund any pre-paid charges as credits.

You can always restore it later.`
      : `Do you want to archive this ${type}? 

You can always restore it later.`;

  const headline =
    entityType === 'image' ||
    entityType === 'design' ||
    entityType === 'palette'
      ? `Archive this ${type}`
      : `Archive this ${entityType}`;

  const action = () => {
    if (entityType === 'hub' && archiveHubId) {
      archiveHubMutation.mutate({ id: archiveHubId });
    } else if (entityType === 'board' && archiveBoardId) {
      archiveBoardMutation.mutate({ id: archiveBoardId });
    } else if (entityType === 'doc' && archiveDocId) {
      archiveDocMutation.mutate({ id: archiveDocId });
    } else if (
      (entityType === 'image' ||
        entityType === 'design' ||
        entityType === 'palette') &&
      Array.isArray(archiveFileIds) &&
      archiveFileIds.length > 0
    ) {
      archiveFileMutation.mutate({ ids: archiveFileIds, entityType });
    }

    archiveDialogClosed();
  };

  return (
    <Modal handleClose={archiveDialogClosed} showModal={isOpenArchiveDialog}>
      <Dialog
        headline={headline}
        description={description}
        actionLabel="Archive now"
        action={action}
        closeAction={archiveDialogClosed}
      />
    </Modal>
  );
};
