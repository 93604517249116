import React, { useEffect, useRef } from 'react';

import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';
import { useParams } from 'react-router';

import { BrandLoader } from '@visualist/design-system/src/components/v2';

import { DocumentHead } from '@components/DocumentHead';
import { useDesign } from '@pages/StudioPage/hooks/useDesign';
import { ErrorBoundary } from '@sentry/react';
import { MissingContent } from '@src/entities/global-error/premade/missing-content';
import { SomethingWentWrong } from '@src/entities/global-error/premade/something-went-wrong';
import { SearchDialog } from '@src/entities/search/ui/searchDialog';
import { SelectedSticky } from '@src/entities/Stickies/SelectedSticky';
import { queryClient } from '@src/queryClient';

import { Canvas } from './components/Canvas';
import { FileLoader } from './components/file-loader';
import { FilmStrip } from './components/film-strip';
import { Library } from './components/Library';
import { MainToolbar } from './components/main-toolbar';
import { Shuffler } from './components/Shuffler';
import { Tabs } from './components/Tabs';
import { useStudioDesign } from './hooks/use-studio-design';
import { $showLibrary, closedLibrary } from './model';

import styles from './styles.module.css';

export const StudioPage = () => {
  const { design_id } = useParams<{ design_id?: string }>();

  if (!design_id) return <MissingContent />;

  return <Studio designId={design_id} />;
};

const Studio = ({ designId }: { designId: string }) => {
  const {
    designData,
    deletePage,
    addPage,
    swapPage,
    allPageObjects,
    switchStateType,
    pastePage,
    duplicatePage,
    markPageForCopy,
    currentPageMetadata,
  } = useStudioDesign(designId);

  const showLibrary = useUnit($showLibrary);

  const stageRef = useRef<Stage | null>(null);

  useEffect(() => {
    return () => {
      if (!location.pathname.startsWith('/studio/') && showLibrary) {
        closedLibrary();
      }
    };
  }, [location.pathname, showLibrary]);

  if (designData.state === null) {
    return (
      <div className={styles.loaderContainer}>
        <BrandLoader
          animatedIcon="paint-brush"
          subText="Crafting your canvas, just a sec!"
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <DocumentHead name="Studio" />
      <Tabs designId={designId} stageRef={stageRef} />
      <ErrorBoundary
        fallback={
          <SomethingWentWrong
            crashSideEffect={async () => {
              queryClient.clear();
              // Clearing cache required 1s to persist the empty cache in localStorage
              await new Promise((r) => setTimeout(r, 1100));
              window.location.reload();
            }}
          />
        }
      >
        <FileLoader key={designData.state.type} designId={designId}>
          <MainCanvas
            key={`${designId}-main-canvas`}
            designId={designId}
            stageRef={stageRef}
          />
          {designData.state.type === 'pages' ? (
            <FilmStrip
              key={`${designId}-film-strip`}
              stageRef={stageRef}
              designId={designId}
              pages={designData.state?.data}
              allPageObjects={allPageObjects}
              switchStateType={switchStateType}
              deletePage={deletePage}
              addPage={addPage}
              swapPage={swapPage}
              pastePage={pastePage}
              markPageForCopy={markPageForCopy}
              duplicatePage={duplicatePage}
              currentPageMetadata={currentPageMetadata}
            />
          ) : null}
        </FileLoader>
      </ErrorBoundary>
    </div>
  );
};

type Props = {
  designId: string;
  stageRef: React.MutableRefObject<Stage | null>;
};

const MainCanvas = ({ designId, stageRef }: Props) => {
  const { designQuery } = useDesign({ designId });

  if (designQuery.isError) return <SomethingWentWrong />;

  return (
    <div className={styles.canvasContainer}>
      <SearchDialog hideMobileSearch />
      <Library stageRef={stageRef} designId={designId} />
      <Canvas ref={stageRef} stageRef={stageRef} designId={designId} />
      <Shuffler stageRef={stageRef} designId={designId} />
      <SelectedSticky blockId={designId} />
      <MainToolbar designId={designId} stageRef={stageRef} />
    </div>
  );
};
