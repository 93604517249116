import type { Stage } from 'konva/lib/Stage';
import { Layer } from 'react-konva';

import { STICKY_LAYER } from '@pages/StudioPage/constants';

import { Stickies } from '../../Stickies';

export const StickiesLayer = ({
  designId,
  hasCentered,
  stageRef,
  dimensions,
}: {
  designId: string;
  hasCentered: boolean;
  stageRef: React.MutableRefObject<Stage | null>;
  dimensions: {
    height: number;
    width: number;
  };
}) => {
  if (!designId || !hasCentered) return null;

  return (
    <Layer name={STICKY_LAYER}>
      <Stickies
        stageRef={stageRef}
        designId={designId}
        dimensions={dimensions}
      />
    </Layer>
  );
};
