import Konva from 'konva';
import { Group } from 'konva/lib/Group';
import { Layer } from 'konva/lib/Layer';
import { Node } from 'konva/lib/Node';
import { Arrow } from 'konva/lib/shapes/Arrow';
import { Line } from 'konva/lib/shapes/Line';
import { Text } from 'konva/lib/shapes/Text';
import { Transformer } from 'konva/lib/shapes/Transformer';
import { Stage } from 'konva/lib/Stage';

import { ImageJSON, Page, ShapeJSON, TextJSON } from '@api/designs';
import { generateId } from '@src/shared/utils/id';

import {
  DRAG_SELECTION,
  IMAGE_GROUP,
  MAIN_OBJECTS_LAYER,
  PAGE_GROUP,
  SHAPE,
  SHAPE_GROUP,
  TEXT,
  TEXT_GROUP,
} from '../constants';
import { DEFAULT_PAGE_METADATA } from '../lib/design';
import { selectObjectIds, updatedStage } from '../model';
import {
  calculateCenter,
  calculateLowerFence,
  calculateUpperFence,
} from './calculate-center';

/**
 * Search nodes in the stage and perform an action on nodes that match a condition.
 * nodeSearchCondition if left blank will be ignored
 * Images and text+shapes have different node structures hence this function. Can probably make this a recursive search to work for any condition in the future
 */

const searchNodesInStage = ({
  stage,
  nodeSearchCondition,
  matchingNodeAction,
}: {
  stage: Stage;
  nodeSearchCondition?: (id: string) => boolean;
  matchingNodeAction: (id: string, node: Node) => void;
}) => {
  stage.getLayers().forEach((layer) => {
    if (layer.attrs.name === MAIN_OBJECTS_LAYER) {
      let children = layer.children;

      if (
        layer.children.length > 0 &&
        layer.children[0].attrs?.name === PAGE_GROUP
      ) {
        const pageGroup = layer.children[0] as Group;
        children = pageGroup.children;
      }
      children.forEach((g) => {
        try {
          // Check image layer
          if (isGroupNode(g)) {
            // We use the values in the group with images as the image inside are group match the groups bounding box
            if (
              isGroupNode(g) &&
              (typeof nodeSearchCondition !== 'undefined'
                ? nodeSearchCondition(g.attrs.id)
                : true) &&
              g.attrs.name === IMAGE_GROUP
            ) {
              // Image specific matching
              matchingNodeAction(g.attrs.id, g);
            } else if (
              isGroupNode(g) &&
              (g.attrs.name === TEXT_GROUP || g.attrs.name === SHAPE_GROUP)
            ) {
              // Text & Shape specific matching
              g.children.forEach((c) => {
                if (
                  (typeof nodeSearchCondition !== 'undefined'
                    ? nodeSearchCondition(c.attrs.id)
                    : true) &&
                  (c.attrs.name === TEXT || c.attrs.name === SHAPE)
                ) {
                  matchingNodeAction(c.attrs.id, c);
                }
              });
            }
          }
        } catch (e) {
          console.error(e);
          return;
        }
      });
    }
  });
};

export const calculateNewStagePosition = ({
  x,
  y,
  previousScale,
  newScale,
  origin,
}: {
  x: number;
  y: number;
  previousScale: number;
  newScale: number;
  origin: { x: number; y: number };
}) => {
  const mousePointTo = {
    x: origin.x / previousScale - x / previousScale,
    y: origin.y / previousScale - y / previousScale,
  };

  return {
    scale: newScale,
    x: (origin.x / newScale - mousePointTo.x) * newScale,
    y: (origin.y / newScale - mousePointTo.y) * newScale,
  };
};

export const asyncLoadImage = (imgSrc: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve(img);
    };

    img.onerror = () => {
      reject(new Error('Failed to load image'));
    };

    img.src = imgSrc;
  });
};

export async function loadImageFromFile(file: File): Promise<HTMLImageElement> {
  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;
      img.onload = () => {
        res(img);
      };
    };

    reader.onerror = (e) => {
      rej(e);
    };

    reader.readAsDataURL(file);
  });
}

export async function imageUrlToFile(
  url: string,
  fileName: string,
): Promise<File | null> {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    return new File([blob], fileName, { type: blob.type });
  } catch (error) {
    console.error('Error converting image URL to File:', error);
    return null;
  }
}

export const generatePreview = (
  stage: Stage,
  quality?: 'export' | 'high' | 'low' | 'medium' | 'thumbnail',
  mimeType?: 'image/jpeg' | 'image/png',
  isInfinite: boolean = true,
  pageData?: Page,
  customBackgroundColor?: string,
) => {
  const clonedStage = stage.clone();

  clonedStage.scale({ x: 1, y: 1 });
  clonedStage.x(0);
  clonedStage.y(0);

  let minX = Number.MAX_VALUE;
  let minY = Number.MAX_VALUE;
  let maxX = Number.MIN_VALUE;
  let maxY = Number.MIN_VALUE;

  const nodes: Node[] = [];

  // Delete drag selection layer so it is not in the export
  clonedStage
    .getLayers()
    .find((l) => l.name() === DRAG_SELECTION)
    ?.destroy();

  searchNodesInStage({
    stage: clonedStage,
    matchingNodeAction: (_, node) => {
      nodes.push(node);
    },
  });

  if (nodes.length === 0 && quality === 'thumbnail')
    throw Error('No nodes to export');

  if (isInfinite) {
    const boundingBoxOfNoxes = nodes.map((n) => {
      return n.getClientRect();
    });

    const xValues = boundingBoxOfNoxes.map((n) => n.x);
    const yValues = boundingBoxOfNoxes.map((n) => n.y);

    const { xLowerFence, xUpperFence, yLowerFence, yUpperFence } =
      calculateFence(xValues, yValues);

    const filteredPoints = boundingBoxOfNoxes.filter((node) => {
      const point = {
        x: node.x,
        y: node.y,
      };

      return (
        point.x >= xLowerFence &&
        point.x <= xUpperFence &&
        point.y >= yLowerFence &&
        point.y <= yUpperFence
      );
    });

    filteredPoints.forEach((image) => {
      minX = Math.min(minX, image.x);
      minY = Math.min(minY, image.y);
      maxX = Math.max(maxX, image.x + image.width);
      maxY = Math.max(maxY, image.y + image.height);
    });
  } else {
    minX = 0;
    minY = 0;
    maxX = pageData?.metadata.width || 1;
    maxY = pageData?.metadata.height || 1;
  }

  let pixelRatio = 1 / 2;
  let imageQuality = 0;

  switch (quality) {
    case 'export':
      pixelRatio = calculatePixelRatio({
        width: maxX - minX,
        desiredWidth: 5200,
      });
      imageQuality = 1;
      break;
    case 'high':
      pixelRatio = 1;
      imageQuality = 0.8;
      break;
    case 'medium':
      pixelRatio = 1 / 2;
      imageQuality = 0.5;
      break;
    case 'low':
      pixelRatio = 1 / 3;
      imageQuality = 0.2;
      break;
    case 'thumbnail':
      pixelRatio = calculatePixelRatio({
        width: maxX - minX,
        desiredWidth: 1200,
      });
      imageQuality = 0.4;
      break;
    default:
      pixelRatio = 1 / 4;
      imageQuality = 0.2;
  }

  if (
    (mimeType === 'image/jpeg' || customBackgroundColor) &&
    mimeType !== 'image/png' &&
    isInfinite
  ) {
    if (!customBackgroundColor) customBackgroundColor = 'white';
    const layer = new Konva.Layer();
    clonedStage.add(layer);
    const background = new Konva.Rect({
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
      fill: customBackgroundColor,
      listening: false,
    });

    layer.add(background);
    background.moveToBottom();
    layer.moveToBottom();
    layer.draw();
  }

  const data = clonedStage.toDataURL({
    pixelRatio,
    quality: imageQuality,
    x: minX,
    y: minY,
    width: isInfinite ? maxX - minX : maxX,
    height: isInfinite ? maxY - minY : maxY,
    mimeType: mimeType,
  });

  return { data, minX, minY, maxX, maxY };
};

/**
 * Function generates a pixel rato
 * Returns values less than 1
 */

const calculatePixelRatio = (props: {
  width: number;
  desiredWidth: number;
}) => {
  return Math.min(props.desiredWidth / props.width, 2);
};

export async function copyImageToClipboard(base64Image: string): Promise<void> {
  // Create a temporary canvas and context
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  // Create a new image
  const img = new Image();
  img.src = base64Image;

  // Once the image loads, draw it onto the canvas
  img.onload = () => {
    if (context) {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0);

      // Convert the canvas to a Blob
      canvas.toBlob(async (blob) => {
        if (blob) {
          // Try to copy the Blob to the clipboard
          try {
            await navigator.clipboard.write([
              new ClipboardItem({
                'image/png': blob,
              }),
            ]);
          } catch (err) {
            console.error('Failed to copy image to clipboard', err);
          }
        }
      });
    }
  };
}

export const mapValueInRange = (
  value: number,
  fromRange: [number, number],
  toRange: [number, number],
) => {
  // Maps value in range but skews to one side of the range
  const [fromLow, fromHigh] = fromRange;
  const [toLow, toHigh] = toRange;

  const fraction = (value - fromLow) / (fromHigh - fromLow);
  const nonLinearFraction = 1 - Math.pow(fraction, 50);

  return nonLinearFraction * (toHigh - toLow) + toLow;
};

/**
 * Function to generate form data for image upload
 * @param props.width
 * @param props.height
 * @param props.file
 * @param props.positionX
 * @param props.positionY
 * @param props.id
 * @param props.set
 * @param props.isHiddenFromLibrary
 *
 * @returns FormData
 * @example
 * const formData = generateFormDataForUpload({
 *  width: 100,
 * height: 100,
 * file: new File([''], 'image.jpeg'),
 * positionX: 100,
 * positionY: 100,
 * id: '123',
 * set: '123',
 * isHiddenFromLibrary: true,
 * });
 */

export const generateFormDataForUpload = (props: {
  width: number;
  height: number;
  file: File;
  positionX: number;
  positionY: number;
  id: string;
  set: string;
  isHiddenFromLibrary: boolean;
}) => {
  const formData = new FormData();

  formData.append('width', props.width.toString());
  formData.append('height', props.height.toString());
  formData.append('file', props.file);
  formData.append('position_x', Math.trunc(props.positionX).toString());
  formData.append('position_y', Math.trunc(props.positionY).toString());
  formData.append('id', props.id);
  formData.append('set', props.set);
  formData.append(
    'is_hidden_from_library',
    props.isHiddenFromLibrary.toString(),
  );

  return formData;
};

/**
 * Function to calculate the centered width and height for an image
 *
 * @param props.x
 * @param props.y
 * @param props.width
 * @param props.height
 *
 * @returns {x: number, y: number}
 */

export const centeredWidthHeightForImage = (props: {
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  return {
    x: props.x - props.width / 2,
    y: props.y - props.height / 2,
  };
};

export const getAverage = (arr: number[]) => {
  return arr.reduce((a, b) => a + b, 0) / arr.length;
};

export const getSelectedNodes = (stage: Stage, nodeIds: string[]) => {
  try {
    const nodes: Node[] = [];
    searchNodesInStage({
      stage,
      nodeSearchCondition: (id) => {
        return nodeIds.includes(id);
      },
      matchingNodeAction: (_, node) => nodes.push(node),
    });
    return nodes;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const selectBlocksInSelection = ({
  stage,
  x1,
  y1,
  x2,
  y2,
  selectedObjectIds,
  getGroupObjectsByObjectId,
  isShiftPressed,
}: {
  stage: Stage;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  selectedObjectIds: Set<string>;
  getGroupObjectsByObjectId: (objectId: string) => string[];
  isShiftPressed: boolean;
}) => {
  try {
    const textIds: string[] = [];
    const imageIds: string[] = [];
    const shapeIds: string[] = [];

    searchNodesInStage({
      stage,
      matchingNodeAction: (_, node) => {
        const nodeX = node.x();
        const nodeY = node.y();
        let nodeWidth = node.width();
        let nodeHeight = node.height();
        if (isLineNode(node) || isArrowNode(node)) {
          nodeWidth = node.points()[2];
          nodeHeight = node.points()[3];
        }
        const hasOverlap = doesOverlap({
          l1: { x: x1, y: y1 },
          r1: { x: x2, y: y2 },
          l2: { x: nodeX, y: nodeY },
          r2: { x: nodeX + nodeWidth, y: nodeY + nodeHeight },
        });

        if (node.attrs.name === IMAGE_GROUP && hasOverlap) {
          imageIds.push(node.attrs.id);
        } else if (node.attrs.name === SHAPE && hasOverlap) {
          shapeIds.push(node.attrs.id);
        } else if (node.attrs.name === TEXT && hasOverlap) {
          textIds.push(node.attrs.id);
        }
      },
    });

    const newlySelected = new Set([...imageIds, ...textIds, ...shapeIds]);
    const combinedSet = isShiftPressed
      ? new Set([...Array.from(selectedObjectIds), ...newlySelected])
      : newlySelected;

    const finalSet = new Set(combinedSet);
    for (const id of combinedSet) {
      const groupObjects = getGroupObjectsByObjectId(id);
      groupObjects.forEach((objectId) => finalSet.add(objectId));
    }

    selectObjectIds(finalSet);
  } catch (e) {
    console.error(e);
    selectObjectIds(new Set());
    return;
  }
};

type Point = {
  x: number;
  y: number;
};

/**
 * @description l is for the bounding box as this function check if r is within l or r intersects l
 */
export function doesOverlap({
  l1,
  r1,
  l2,
  r2,
}: {
  l1: Point;
  r1: Point;
  l2: Point;
  r2: Point;
}) {
  if (
    contains({
      a: {
        x1: l1.x,
        y1: l1.y,
        x2: r1.x,
        y2: r1.y,
      },
      b: {
        x1: l2.x,
        y1: l2.y,
        x2: r2.x,
        y2: r2.y,
      },
    })
  ) {
    return true;
  }

  if (
    overlaps({
      a: {
        x1: l1.x,
        y1: l1.y,
        x2: r1.x,
        y2: r1.y,
      },
      b: {
        x1: l2.x,
        y1: l2.y,
        x2: r2.x,
        y2: r2.y,
      },
    })
  )
    return true;

  return false;
}

/**
 *
 * @description
 * Check if a contains b
 * @param a
 * @param b
 *
 */

function contains({
  a,
  b,
}: {
  a: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  b: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
}) {
  // Ensure a is always the bounding box with the top-left corner and b with the bottom-right corner
  const [topLeft, bottomRight] = [
    { x: Math.min(a.x1, a.x2), y: Math.min(a.y1, a.y2) },
    { x: Math.max(a.x1, a.x2), y: Math.max(a.y1, a.y2) },
  ];

  return (
    b.x1 >= topLeft.x &&
    b.y1 >= topLeft.y &&
    b.x2 <= bottomRight.x &&
    b.y2 <= bottomRight.y
  );
}

/**
 * @description check if a overlaps b
 * @param a
 * @param b
 */
function overlaps({
  a,
  b,
}: {
  a: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
  b: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  };
}) {
  // Ensure a is always the bounding box with the top-left corner and b with the bottom-right corner
  const [topLeftA, bottomRightA] = [
    { x: Math.min(a.x1, a.x2), y: Math.min(a.y1, a.y2) },
    { x: Math.max(a.x1, a.x2), y: Math.max(a.y1, a.y2) },
  ];

  const [topLeftB, bottomRightB] = [
    { x: Math.min(b.x1, b.x2), y: Math.min(b.y1, b.y2) },
    { x: Math.max(b.x1, b.x2), y: Math.max(b.y1, b.y2) },
  ];

  // no horizontal overlap
  if (topLeftA.x >= bottomRightB.x || topLeftB.x >= bottomRightA.x)
    return false;

  // no vertical overlap
  if (topLeftA.y >= bottomRightB.y || topLeftB.y >= bottomRightA.y)
    return false;

  return true;
}

export const centerStage = (props: {
  stageRef: React.MutableRefObject<Stage | null>;
  images: ImageJSON[];
  textboxes: TextJSON[];
  setHasCentered: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // Calculates the center point of the stage given the images and text blocks and centers the view on that position
  if (
    typeof props.stageRef === 'function' ||
    !props.stageRef ||
    !props.stageRef.current
  )
    return;

  const stage = props.stageRef.current;
  const stageScale = 0.2;

  const blocks: {
    x: number;
    y: number;
    width: number;
    height: number;
  }[] = [
    ...props.images.map((b) => ({
      x: b.x,
      y: b.y,
      width: b.metadata.width,
      height: b.metadata.height,
    })),
    ...props.textboxes.map((t) => ({
      x: t.x,
      y: t.y,
      width: t.metadata.width,
      height: 64,
    })),
  ];

  const center = calculateCenter({ blocks });

  if (!center) return;

  const x = -center.x * stageScale + stage.width() / 2;
  const y = -center.y * stageScale + stage.height() / 2;

  updatedStage({
    x: x || 0,
    y: y || 0,
    scale: stageScale,
  });

  props.setHasCentered(true);
};

/**
 * @name Calculate Canvas
 * @description Takes the image and text data and calculates the max bounds of these elements
 * @param {ImageBlock[]} images
 * @param {TextBox[]} textboxes
 *
 * @returns {maxX: number, maxY: number, minX: number, minY: number}
 */

export const calculateTextAndImageBounds = ({
  images,
  textboxes,
}: {
  images?: ImageJSON[];
  textboxes?: TextJSON[];
}) => {
  // Compute max bounds
  let minY = Infinity;
  let maxY = -Infinity;
  let maxX = -Infinity;
  let minX = Infinity;

  if (!images || !textboxes) return { maxX, maxY, minX, minY };

  for (const image of images) {
    const {
      x,
      y,
      metadata: { width, height },
    } = image;
    minY = Math.min(minY, y);
    minX = Math.min(minX, x);
    maxY = Math.max(maxY, y + (height ?? 0));
    maxX = Math.max(maxX, x + (width ?? 0));
  }
  for (const textbox of textboxes) {
    const {
      x,
      y,
      metadata: { width },
    } = textbox;
    // Height is calculated on demand so its impossible to get here without some trickery. Assume its 64 (height of 1 line)
    const HEIGHT = 64;
    minY = Math.min(minY, y);
    minX = Math.min(minX, x);
    maxY = Math.max(maxY, y + HEIGHT);
    maxX = Math.max(maxX, x + width);
  }

  return { maxX, maxY, minX, minY };
};

export const calculateBoundsFromStage = (stage: Stage) => {
  let minX = Number.MAX_VALUE;
  let minY = Number.MAX_VALUE;
  let maxX = Number.MIN_VALUE;
  let maxY = Number.MIN_VALUE;

  const nodes: { x: number; y: number; height: number; width: number }[] = [];

  searchNodesInStage({
    stage,
    matchingNodeAction: (_, node) => {
      const height = node.height();
      const width = node.width();
      const x = node.x();
      const y = node.y();
      nodes.push({ x, y, height, width });
    },
  });

  const xValues = nodes.map((node) => node.x);
  const yValues = nodes.map((node) => node.y);

  const { xLowerFence, xUpperFence, yLowerFence, yUpperFence } = calculateFence(
    xValues,
    yValues,
  );

  const filteredPoints = nodes.filter((node) => {
    const point = {
      x: node.x,
      y: node.y,
    };

    return (
      point.x >= xLowerFence &&
      point.x <= xUpperFence &&
      point.y >= yLowerFence &&
      point.y <= yUpperFence
    );
  });

  filteredPoints.forEach((image) => {
    minX = Math.min(minX, image.x);
    minY = Math.min(minY, image.y);
    maxX = Math.max(maxX, image.x + image.width);
    maxY = Math.max(maxY, image.y + image.height);
  });

  return { minX, minY, maxX, maxY };
};

/**
 * @description Given a list of x and y numbers get the upper and lower fence
 * @param xValues
 * @param yValues
 * @returns
 */

const calculateFence = (xValues: number[], yValues: number[]) => {
  const xLowerFence = calculateLowerFence(xValues, 0, 1);
  const xUpperFence = calculateUpperFence(xValues, 0, 1);

  const yLowerFence = calculateLowerFence(yValues, 0, 1);
  const yUpperFence = calculateUpperFence(yValues, 0, 1);

  return { xLowerFence, xUpperFence, yLowerFence, yUpperFence };
};

/**
 * Calculates the Euclidean distance between two points in 2D space
 * @param p1 First point with x,y coordinates
 * @param p2 Second point with x,y coordinates
 * @returns The distance between the two points
 */
export function getDistance(
  p1: { x: number; y: number },
  p2: { x: number; y: number },
) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

/**
 * Finds the midpoint between two points in 2D space
 * @param p1 First point with x,y coordinates
 * @param p2 Second point with x,y coordinates
 * @returns The center point coordinates
 */
export function getCenter(
  p1: { x: number; y: number },
  p2: { x: number; y: number },
) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

/**
 * Guard function for checking if a konva node is a Group
 */

export const isGroupNode = (node: Node): node is Group => {
  return node instanceof Group;
};

/**
 * Guard function for checking if a konva node is a Text
 */

export const isTextNode = (node: Node): node is Text => {
  return node instanceof Text;
};

/**
 * Guard function for checking if a konva node is a Line
 */

export const isLineNode = (node: Node): node is Line => {
  return node instanceof Line;
};

/**
 * Guard function for checking if a konva node is a Arrow
 */

export const isArrowNode = (node: Node): node is Arrow => {
  return node instanceof Arrow;
};

/**
 * Guard function for checking if a konva node is a Transformer
 */
export const isTransformerNode = (node: Node): node is Transformer => {
  return node instanceof Transformer;
};

/**
 * Guard function for checking if a konva node is a Layer node
 */
export const isLayerNode = (node: Node): node is Layer => {
  return node instanceof Layer;
};

export const keepElementInPageBounds = (
  element: {
    x: number;
    y: number;
    width: number;
    height: number;
  },
  bounds: {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
  },
) => {
  const { x, y, width, height } = element;
  const { minX, minY, maxX, maxY } = bounds;

  const overflowRight = Math.max(0, x + width - maxX);
  const overflowLeft = Math.max(0, minX - x);
  const overflowBottom = Math.max(0, y + height - maxY);
  const overflowTop = Math.max(0, minY - y);

  // If the element is out of bounds, shift it proportionally
  const newX = x - overflowRight + overflowLeft;
  const newY = y - overflowBottom + overflowTop;

  return { x: newX, y: newY };
};

export const getShapeWidthHeight = ({
  shape,
  stage,
}: {
  shape: ShapeJSON;
  stage: Stage | null;
}) => {
  let width = 0;
  let height = 0;
  switch (shape.metadata.type) {
    case 'rectangle':
      width = shape.metadata.width || 0;
      height = shape.metadata.height || 0;
      break;

    case 'circle':
    case 'hexagon':
    case 'wedge':
      width = shape.metadata.radius || 0 * 2;
      height = shape.metadata.radius || 0 * 2;
      break;

    case 'ellipse':
      width = shape.metadata.radiusX || 0 * 2;
      height = shape.metadata.radiusY || 0 * 2;
      break;

    case 'star':
    case 'ring':
    case 'arc':
      width = shape.metadata.outerRadius || 0 * 2;
      height = shape.metadata.outerRadius || 0 * 2;
      break;

    case 'line':
    case 'arrow':
      if (stage) {
        const node = stage.findOne(`#${shape.id}`);
        if (node) {
          width = node.getClientRect().width;
          height = node.getClientRect().height;
        }
      }
      break;

    default:
      console.warn(`Unknown shape type: ${shape.metadata.type}`);
  }
  return { width, height };
};

export const getDefaultPagedContentsBlob = () => {
  const defaultPagedContentsBlob = DEFAULT_PAGE_METADATA;
  defaultPagedContentsBlob.metadata.id = generateId('studio_page');
  return defaultPagedContentsBlob;
};
