import { forwardRef, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { AnimatePresence, LayoutGroup, motion, Reorder } from 'framer-motion';

// import { flushSync } from 'react-dom';
import {
  Dropdown,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useOnClickOutside } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { BoardLvl1, BoardLvl2, BoardLvl3 } from '@api/designs';
import { Role } from '@api/users';
import {
  $isShowElementsPanel,
  $isShowFilesPanel,
} from '@pages/DocPage/model/sidebar-opening';
import { TabWithHandlers } from '@src/entities/doc/ui/tabs';

import styles from './styles.module.css';

export const Tabs = <T extends TabWithHandlers>(props: {
  tabs: Array<T>;
  isLoadingTabs: boolean;
  reorderTabs: (values: Array<string>) => void;
  closeTab: (id: string) => void;
  postCloseAction?: () => void;
  canDeleteTab: boolean;
  deleteTab: (id: string) => void;
  archive: () => void;
  unarchive: () => void;
  saveTabName: (id: string, name: string) => void;
  newTabAction: () => void;
  selectedId: string;
  board?: BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
  role: Role | null;
  refetch: () => void;
  isArchived?: boolean;
}) => {
  const {
    tabs,
    reorderTabs,
    isLoadingTabs,
    closeTab,
    postCloseAction,
    canDeleteTab,
    archive,
    unarchive,
    deleteTab,
    saveTabName,
    newTabAction,
    selectedId,
    board,
    role,
    refetch,
    isArchived,
  } = props;

  return (
    <TabContainer values={tabs.map((t) => t.id)} onReorder={reorderTabs}>
      <LayoutGroup>
        <AnimatePresence
          mode="popLayout"
          onExitComplete={() => {
            if (postCloseAction) postCloseAction();
          }}
        >
          {!isLoadingTabs || !tabs.length
            ? tabs.map((t) => {
                return (
                  <Tab
                    key={t.id}
                    tab={t}
                    closeTab={closeTab}
                    canDeleteTab={canDeleteTab}
                    archive={archive}
                    unarchive={unarchive}
                    deleteTab={deleteTab}
                    saveTabName={saveTabName}
                    isSelected={t.id === selectedId}
                    board={board}
                    role={role}
                    refetch={refetch}
                    isArchived={isArchived}
                  />
                );
              })
            : null}
          <motion.button
            onClick={newTabAction}
            layout
            className={styles.addButton}
          >
            <Icon name="sprite/plus" />
          </motion.button>
        </AnimatePresence>
      </LayoutGroup>
    </TabContainer>
  );
};

export const Tab = forwardRef<
  HTMLDivElement,
  {
    tab: TabWithHandlers;
    closeTab: (id: string) => void;
    archive: () => void;
    unarchive: () => void;
    deleteTab: (id: string) => void;
    canDeleteTab: boolean;
    saveTabName: (id: string, name: string) => void;
    isSelected: boolean;
    board?: BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
    role: Role | null;
    refetch: () => void;
    isArchived?: boolean;
  }
>((props, ref) => {
  const {
    tab: { id, onClick, label, menuItems },
    closeTab,
    archive,
    unarchive,
    deleteTab,
    canDeleteTab,
    saveTabName,
    isSelected,
    board,
    role,
    refetch,
    isArchived,
  } = props;

  const labelRef = useRef<HTMLSpanElement | null>(null);

  const [isHovered, setIsHovered] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);

  const closeTabAction = () => closeTab(id);

  useOnClickOutside(ref, () => {
    if (
      isRenaming &&
      labelRef &&
      labelRef.current &&
      labelRef.current.innerText
    ) {
      saveTabName(id, labelRef.current.innerText);
    }
    setIsRenaming(false);
  });

  return isSelected && board ? (
    // <TooltipRadix
    //   description={<Breadcrumbs board={board} className={styles.breadcrumbs} />}
    //   side="bottom"
    // >
    <Reorder.Item
      value={id}
      draggable={!isRenaming}
      drag="x"
      onDragEnd={(e) => e.stopPropagation()}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, y: -48 }}
      layoutScroll
      layout="position"
      as="div"
      className={styles.tabMain}
      ref={ref}
    >
      <div
        className={cn(styles.tabContainer, {
          [styles.tabContainerSelected]: isSelected,
        })}
      >
        {isSelected && menuItems && menuItems.length > 0 ? (
          <Dropdown
            open={showDropdown}
            onOpenChange={() => {
              refetch();
              setShowDropdown(!showDropdown);
            }}
          >
            <Dropdown.Menu
              trigger={
                isSelected && (
                  <button
                    className={styles.dropdownButton}
                    onClick={() => setShowDropdown((o) => !o)}
                  >
                    <Icon name="sprite/caret-down" />
                  </button>
                )
              }
              side="bottom"
              sideOffset={20}
              align="start"
              alignOffset={-13}
              density="-2"
            >
              {menuItems.map((item, index) => (
                <Dropdown.MenuItem key={index} item={item} />
              ))}
              {role === 'Editor' && !isArchived && (
                <Dropdown.MenuItem
                  key="rename"
                  item={{
                    leadingIcon: <Icon name="sprite/pen" />,
                    content: 'Rename',
                    onClick: () => setIsRenaming(true),
                  }}
                />
              )}
              <Dropdown.MenuItem
                item={{
                  leadingIcon: <Icon name="sprite/archive" />,
                  content: isArchived ? 'Unarchive' : 'Archive',
                  isDivider: true,
                  onClick: isArchived ? unarchive : archive,
                }}
              />
              {canDeleteTab ? (
                <Dropdown.MenuItem
                  key="delete"
                  item={{
                    leadingIcon: (
                      <Icon name="sprite/bin" color="var(--color-error)" />
                    ),
                    content: 'Delete',
                    onClick: () => deleteTab(id),
                    classNameContent: styles.delete,
                  }}
                />
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        <TypographyPoppins type="label" size="L">
          <span
            role="textbox"
            tabIndex={1}
            onClick={(e) => {
              if (isRenaming) e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();

                if (!labelRef.current) return;
                saveTabName(id, labelRef.current.innerText);
                setIsRenaming(false);
              }
              if (e.key === 'Escape') {
                e.preventDefault();
                setIsRenaming(false);
                if (!labelRef.current) return;
                labelRef.current.innerText = label;
              }
            }}
            ref={labelRef}
            contentEditable={isRenaming}
            suppressContentEditableWarning
            className={cn(styles.textarea, {
              [styles.editing]: isRenaming,
            })}
            dangerouslySetInnerHTML={{ __html: label ?? 'Untitled' }}
          />
        </TypographyPoppins>
        {(isSelected && isHovered && !isRenaming) || showDropdown ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              closeTabAction();
            }}
            className={styles.closeButton}
          >
            <Icon name="sprite/x" />
          </button>
        ) : null}
        {isRenaming && isSelected ? (
          <button
            onClick={() => {
              if (!labelRef.current) return;
              saveTabName(id, labelRef.current.innerText);
              setIsRenaming(false);
            }}
            className={styles.closeButton}
          >
            <Icon name="sprite/tick" />
          </button>
        ) : null}
      </div>
    </Reorder.Item>
  ) : (
    // </TooltipRadix>
    <Reorder.Item
      value={id}
      draggable={!isRenaming}
      drag="x"
      onDragEnd={(e) => e.stopPropagation()}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, y: -48 }}
      layoutScroll
      layout="position"
      as="div"
      className={styles.tabMain}
      ref={ref}
    >
      <div
        className={cn(styles.tabContainer, {
          [styles.tabContainerSelected]: isSelected,
        })}
      >
        {isSelected && menuItems && menuItems.length > 0 ? (
          <Dropdown
            open={showDropdown}
            onOpenChange={() => {
              refetch();
              setShowDropdown(!showDropdown);
            }}
          >
            <Dropdown.Menu
              trigger={
                isSelected && (
                  <button
                    className={styles.dropdownButton}
                    onClick={() => setShowDropdown((o) => !o)}
                  >
                    <Icon name="sprite/caret-down" />
                  </button>
                )
              }
              side="bottom"
              sideOffset={20}
              align="start"
              alignOffset={-13}
              density="-2"
            >
              {menuItems.map((item, index) => (
                <Dropdown.MenuItem key={index} item={item} />
              ))}
              {role === 'Editor' && !isArchived && (
                <Dropdown.MenuItem
                  key="rename"
                  item={{
                    leadingIcon: <Icon name="sprite/pen" />,
                    content: 'Rename',
                    onClick: () => setIsRenaming(true),
                  }}
                />
              )}
              <Dropdown.MenuItem
                item={{
                  leadingIcon: <Icon name="sprite/archive" />,
                  content: isArchived ? 'Unarchive' : 'Archive',
                  isDivider: true,
                  onClick: isArchived ? unarchive : archive,
                }}
              />
              {canDeleteTab ? (
                <Dropdown.MenuItem
                  key="delete"
                  item={{
                    leadingIcon: (
                      <Icon name="sprite/bin" color="var(--color-error)" />
                    ),
                    content: 'Delete',
                    onClick: () => deleteTab(id),
                    classNameContent: styles.delete,
                  }}
                />
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        <TypographyPoppins type="label" size="L">
          <span
            role="textbox"
            tabIndex={1}
            onClick={(e) => {
              if (isRenaming) e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();

                if (!labelRef.current) return;
                saveTabName(id, labelRef.current.innerText);
                setIsRenaming(false);
              }
              if (e.key === 'Escape') {
                e.preventDefault();
                setIsRenaming(false);
                if (!labelRef.current) return;
                labelRef.current.innerText = label;
              }
            }}
            ref={labelRef}
            contentEditable={isRenaming}
            suppressContentEditableWarning
            className={cn(styles.textarea, {
              [styles.editing]: isRenaming,
            })}
            dangerouslySetInnerHTML={{ __html: label ?? 'Untitled' }}
          />
        </TypographyPoppins>
        {(isSelected && isHovered && !isRenaming) || showDropdown ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              closeTabAction();
            }}
            className={styles.closeButton}
          >
            <Icon name="sprite/x" />
          </button>
        ) : null}
        {isRenaming && isSelected ? (
          <button
            onClick={() => {
              if (!labelRef.current) return;
              saveTabName(id, labelRef.current.innerText);
              setIsRenaming(false);
            }}
            className={styles.closeButton}
          >
            <Icon name="sprite/tick" />
          </button>
        ) : null}
      </div>
    </Reorder.Item>
  );
});

Tab.displayName = 'Tab';

type ContainerProps = {
  children: React.ReactNode;
  values: Array<string>;
  onReorder: (values: Array<string>) => void;
};

const TabContainer = ({ children, values, onReorder }: ContainerProps) => {
  const isShowElementsPanel = useUnit($isShowElementsPanel);
  const isShowFilesPanel = useUnit($isShowFilesPanel);
  return (
    <Reorder.Group
      axis="x"
      values={values}
      onReorder={onReorder}
      as="div"
      className={cn(styles.container, {
        [styles.showDocSidebar]: isShowElementsPanel || isShowFilesPanel,
      })}
    >
      {children}
    </Reorder.Group>
  );
};
