import { useEffect, useState } from 'react';

import { useUnit } from 'effector-react';
import Konva from 'konva';
import {
  Arc,
  Arrow,
  Circle,
  Ellipse,
  Line,
  Rect,
  RegularPolygon,
  Ring,
  Star,
  Wedge,
} from 'react-konva';

import { ShapeJSON } from '@api/designs';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import {
  $currentShape,
  $currentTool,
  $stageState,
} from '@pages/StudioPage/model';
import { generateId } from '@src/shared/utils/id';

import { calculateShapeMetadata, getInitialShapeMetada } from './util';

export const PreviewShape = ({
  designId,
  stageRef,
}: {
  designId: string;
  stageRef: React.RefObject<Konva.Stage>;
}) => {
  const [currentTool, currentShape, stageState] = useUnit([
    $currentTool,
    $currentShape,
    $stageState,
  ]);
  const { addShape } = useStudioDesign(designId);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [previewShape, setPreviewShape] = useState<ShapeJSON | null>(null);
  const [isClick, setIsClick] = useState(true);

  // Handle Mouse Down (Start Drawing)
  const handleMouseDown = (e: MouseEvent) => {
    if (currentTool !== 'add-shape' || !stageRef.current) return;
    const s = stageRef.current;

    // Get starting position
    const pointerX = e.offsetX;
    const pointerY = e.offsetY;

    const x = (pointerX - s.x()) / s.scaleX();
    const y = (pointerY - s.y()) / s.scaleY();

    setStartX(x);
    setStartY(y);
    setIsDrawing(true);
    setIsClick(true);
    const shapeMetadata = getInitialShapeMetada(currentShape);
    setPreviewShape({
      id: generateId('studio_shape_preview'),
      x,
      y,
      rotation: currentShape === 'wedge' ? -120 : 0,
      scale: {
        x: 1,
        y: 1,
      },
      opacity: 1,
      type: 'shape',
      metadata: {
        type: currentShape,
        fill: '#ffffff',
        stroke: '#1F1F1F',
        strokeWidth: 5,
        ...shapeMetadata,
      },
    });
  };

  // Handle Mouse Move (Draw Preview)
  const handleMouseMove = (e: MouseEvent) => {
    if (!isDrawing || !stageRef.current || !previewShape) return;
    const s = stageRef.current;
    const pointerX = e.offsetX;
    const pointerY = e.offsetY;
    const x = (pointerX - s.x()) / s.scaleX();
    const y = (pointerY - s.y()) / s.scaleY();

    if (Math.abs(x - startX) > 5 || Math.abs(y - startY) > 5) {
      setIsClick(false); // It's a drag, not a click
    }

    const { newX, newY, shapeMetadata } = calculateShapeMetadata({
      x,
      y,
      startX,
      startY,
      currentShape,
    });
    setPreviewShape({
      ...previewShape,
      x: newX,
      y: newY,
      metadata: {
        ...previewShape.metadata,
        ...shapeMetadata,
      },
    });
  };

  // Handle Mouse Up (Finish Drawing/Just click)
  const handleMouseUp = () => {
    if (!isDrawing || !previewShape) return;
    let newShape;
    if (!isClick) {
      newShape = previewShape;
      addShape(newShape);
    }
    setIsDrawing(false);
    setPreviewShape(null);
    setIsClick(false);
  };

  useEffect(() => {
    if (!stageRef.current) return;

    stageRef.current.container().addEventListener('mousedown', handleMouseDown);
    stageRef.current.container().addEventListener('mousemove', handleMouseMove);
    stageRef.current.container().addEventListener('mouseup', handleMouseUp);

    return () => {
      if (!stageRef.current) return;

      stageRef.current
        .container()
        .removeEventListener('mousedown', handleMouseDown);
      stageRef.current
        .container()
        .removeEventListener('mousemove', handleMouseMove);
      stageRef.current
        .container()
        .removeEventListener('mouseup', handleMouseUp);
    };
  }, [currentTool, currentShape, stageState, isDrawing, previewShape]);

  if (previewShape === null) return null;

  const renderShape = () => {
    switch (currentShape) {
      case 'circle':
        return (
          <Circle
            x={previewShape.x}
            y={previewShape.y}
            radius={previewShape.metadata.radius}
            fill={previewShape.metadata.fill}
            stroke={previewShape.metadata.stroke}
            strokeWidth={previewShape.metadata.strokeWidth}
          />
        );

      case 'rectangle':
        return (
          <Rect
            x={previewShape.x}
            y={previewShape.y}
            width={previewShape.metadata.width}
            height={previewShape.metadata.height}
            fill={previewShape.metadata.fill}
            stroke={previewShape.metadata.stroke}
            strokeWidth={previewShape.metadata.strokeWidth}
          />
        );

      case 'hexagon':
        if (previewShape.metadata.radius && previewShape.metadata.sides) {
          return (
            <RegularPolygon
              x={previewShape.x}
              y={previewShape.y}
              radius={previewShape.metadata.radius}
              sides={previewShape.metadata.sides}
              fill={previewShape.metadata.fill}
              stroke={previewShape.metadata.stroke}
              strokeWidth={previewShape.metadata.strokeWidth}
            />
          );
        }
        break;

      case 'ellipse':
        if (previewShape.metadata.radiusX && previewShape.metadata.radiusY) {
          return (
            <Ellipse
              x={previewShape.x}
              y={previewShape.y}
              radiusX={previewShape.metadata.radiusX}
              radiusY={previewShape.metadata.radiusY}
              fill={previewShape.metadata.fill}
              stroke={previewShape.metadata.stroke}
              strokeWidth={previewShape.metadata.strokeWidth}
            />
          );
        }
        break;

      case 'star':
        if (
          previewShape.metadata.numPoints &&
          previewShape.metadata.innerRadius &&
          previewShape.metadata.outerRadius
        ) {
          return (
            <Star
              x={previewShape.x}
              y={previewShape.y}
              numPoints={previewShape.metadata.numPoints}
              innerRadius={previewShape.metadata.innerRadius}
              outerRadius={previewShape.metadata.outerRadius}
              fill={previewShape.metadata.fill}
              stroke={previewShape.metadata.stroke}
              strokeWidth={previewShape.metadata.strokeWidth}
            />
          );
        }
        break;

      case 'wedge':
        if (previewShape.metadata.radius && previewShape.metadata.angle) {
          return (
            <Wedge
              x={previewShape.x}
              y={previewShape.y}
              radius={previewShape.metadata.radius}
              angle={previewShape.metadata.angle}
              rotation={previewShape.rotation}
              fill={previewShape.metadata.fill}
              stroke={previewShape.metadata.stroke}
              strokeWidth={previewShape.metadata.strokeWidth}
            />
          );
        }
        break;

      case 'ring':
        if (
          previewShape.metadata.innerRadius &&
          previewShape.metadata.outerRadius
        ) {
          return (
            <Ring
              x={previewShape.x}
              y={previewShape.y}
              innerRadius={previewShape.metadata.innerRadius}
              outerRadius={previewShape.metadata.outerRadius}
              fill={previewShape.metadata.fill}
              stroke={previewShape.metadata.stroke}
              strokeWidth={previewShape.metadata.strokeWidth}
            />
          );
        }
        break;

      case 'arc':
        if (
          previewShape.metadata.innerRadius &&
          previewShape.metadata.outerRadius &&
          previewShape.metadata.angle
        ) {
          return (
            <Arc
              x={previewShape.x}
              y={previewShape.y}
              innerRadius={previewShape.metadata.innerRadius}
              outerRadius={previewShape.metadata.outerRadius}
              angle={previewShape.metadata.angle}
              fill={previewShape.metadata.fill}
              stroke={previewShape.metadata.stroke}
              strokeWidth={previewShape.metadata.strokeWidth}
            />
          );
        }
        break;

      case 'line':
        if (previewShape.metadata.points) {
          return (
            <Line
              x={previewShape.x}
              y={previewShape.y}
              points={previewShape.metadata.points}
              stroke={previewShape.metadata.stroke}
              strokeWidth={previewShape.metadata.strokeWidth}
            />
          );
        }
        break;

      case 'arrow':
        if (previewShape.metadata.points) {
          return (
            <Arrow
              x={previewShape.x}
              y={previewShape.y}
              points={previewShape.metadata.points}
              stroke={previewShape.metadata.stroke}
              strokeWidth={previewShape.metadata.strokeWidth}
              pointerWidth={previewShape.metadata.pointerWidth}
              pointerLength={previewShape.metadata.pointerLength}
            />
          );
        }
        break;
    }
    return null;
  };

  return <>{renderShape()}</>;
};
