import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useInView } from 'react-intersection-observer';
import Masonry from 'react-masonry-css';

import {
  CenteredSpinner,
  Checkbox,
  Fab,
  SegmentedButton,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Spinner } from '@components/Spinner';
import { File } from '@pages/StudioPage/api';
import { useBlocks } from '@pages/StudioPage/hooks/useBlocks';
import { useImages } from '@pages/StudioPage/hooks/useImages';
import {
  $showLibrary,
  changedDraggedImage,
  toggledLibrary,
} from '@pages/StudioPage/model';
import { centeredWidthHeightForImage } from '@pages/StudioPage/utils';
import { $isSmallBreakpoint } from '@src/shared/utils/media-query';

import {
  $isUploadAvailable,
  $selectedImages,
  allImagesUnselected,
  imageDragEnd,
  imageDragStart,
  multipleImagesSelected,
  SelectedImage,
  singleImageSelected,
} from './model';

import styles from './styles.module.css';

interface LibraryProps {
  designId: string;
  stageRef: React.MutableRefObject<Stage | null>;
}

const thumbnailsBreakpoints = {
  default: 3,
};

const IMAGE_ADD_PADDING = 16;

export const Library = ({ stageRef, designId }: LibraryProps) => {
  const showLibrary = useUnit($showLibrary);
  const selectedImages = useUnit($selectedImages);
  const isUploadAvailable = useUnit($isUploadAvailable);
  const isSmallBreakpoint = useUnit($isSmallBreakpoint);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { blocksQuery } = useBlocks();
  const { existingImageUploadMutation } = useImages({
    designId,
  });

  React.useEffect(() => {
    if (blocksQuery.hasNextPage && inView) {
      blocksQuery.fetchNextPage();
    }
  }, [inView, blocksQuery.hasNextPage, blocksQuery.fetchNextPage]);

  const addToCanvas = async (images: SelectedImage[]) => {
    if (!stageRef || !stageRef.current) return;

    const files: File[] = [];

    const center = {
      x:
        (stageRef.current.width() / 2 - stageRef.current.x()) /
        stageRef.current.scaleX(),
      y:
        (stageRef.current.height() / 2 - stageRef.current.y()) /
        stageRef.current.scaleY(),
    };

    if (images) {
      for (const [idx, image] of images.entries()) {
        const newWidth = 900;
        const newHeight =
          (newWidth * image.ref.naturalHeight) / image.ref.naturalWidth;

        const { x, y } = centeredWidthHeightForImage({
          x: center.x,
          y: center.y,
          width: newWidth,
          height: newHeight,
        });

        files.push({
          file: image.id,
          position: {
            position_x: Math.trunc(x + newWidth * idx) + IMAGE_ADD_PADDING,
            position_y: Math.trunc(y),
            position_width: newWidth,
            position_height: newHeight,
            position_omega: 0,
            position_lock: false,
          },
        });
      }
    }

    if (files.length > 0) {
      await existingImageUploadMutation.mutateAsync({ id: designId, files });
    }
  };

  if (blocksQuery.isLoading || !blocksQuery.data)
    return (
      <div
        className={cn(styles.container, {
          [styles.large]: showLibrary,
          [styles.small]: showLibrary && isSmallBreakpoint,
          [styles.mobile]: showLibrary && isMobileOnly,
          [styles.showShadow]: showLibrary,
        })}
      >
        {isSmallBreakpoint && (
          <div className={styles.sheetHeader}>
            <button
              className={styles.dragHandle}
              onClick={() => toggledLibrary()}
            />
          </div>
        )}
        <CenteredSpinner />
      </div>
    );

  return (
    <div
      className={cn(styles.container, {
        [styles.large]: showLibrary,
        [styles.small]: showLibrary && isSmallBreakpoint,
        [styles.mobile]: showLibrary && isMobileOnly,
        [styles.showShadow]: showLibrary,
      })}
    >
      {isSmallBreakpoint && (
        <div className={styles.sheetHeader}>
          <button
            className={styles.dragHandle}
            onClick={() => toggledLibrary()}
          />
        </div>
      )}
      <div className={styles.sheetHeader}>
        <SegmentedButton
          buttonStyle={styles.segmentedButton}
          onClick={() => {}}
          start
          label="Library"
          isSelected
        />
        <SegmentedButton
          buttonStyle={styles.segmentedButton}
          onClick={() => {}}
          end
          label="Unsplash"
          isDisabled
        />
        <div className={styles.closeIconContainer}>
          <button className={styles.closeIcon} onClick={() => toggledLibrary()}>
            <Icon name="sprite/x" height={32} width={32} />
          </button>
        </div>
      </div>
      <div className={styles.columns}>
        <Masonry
          breakpointCols={thumbnailsBreakpoints}
          className={styles.thumbnails}
          columnClassName={styles.thumbnailsColumn}
        >
          {blocksQuery.data.pages.map((page) => {
            return page.data.results.map((b) => {
              return (
                <ImageItem
                  key={b.id}
                  url={b.file.full_size}
                  thumbnailURL={b.file.thumbnail_330}
                  id={b.id}
                  addToCanvas={addToCanvas}
                />
              );
            });
          })}
        </Masonry>
        <div />
        <div ref={ref} className={styles.spinnerContainer}>
          <Spinner />
        </div>
      </div>
      {isUploadAvailable && (
        <Fab
          className={styles.fab}
          style="extended"
          icon={<Icon name="sprite/plus" />}
          label="Add to this design"
          onClick={() => {
            if (selectedImages) {
              addToCanvas(selectedImages);
            }
            allImagesUnselected();
          }}
        />
      )}
    </div>
  );
};

const ImageItem = ({
  thumbnailURL,
  url,
  id,
  addToCanvas,
}: {
  thumbnailURL: string;
  url: string;
  id: string;
  addToCanvas: (images: SelectedImage[]) => void;
}) => {
  const [isShowCheckbox, setShowCheckbox] = useState(false);

  const selectedImages = useUnit($selectedImages);

  // TODO move this drag to effector state
  const imageRef = React.useRef<React.ElementRef<'img'>>(null);

  useEffect(() => {
    if (!selectedImages) {
      setShowCheckbox(false);
    }
  }, [selectedImages]);

  const isSelectedCheckbox = selectedImages
    ? selectedImages.some((image) => image.id === id)
    : false;

  const storeDataForDrag = () => {
    if (!imageRef.current) return;

    imageDragStart({ id, ref: imageRef.current });
    changedDraggedImage(true);
  };

  return (
    <div
      className={cn(styles.imageContainer, {
        [styles.selectedImage]: isSelectedCheckbox,
      })}
      draggable
      onDragStart={() => {
        storeDataForDrag();
        setShowCheckbox(false);
      }}
      onDragEnd={() => {
        changedDraggedImage(false);
        imageDragEnd();
        setShowCheckbox(true);
      }}
      onMouseEnter={() => setShowCheckbox(true)}
      onMouseLeave={() => {
        if (!isSelectedCheckbox) {
          setShowCheckbox(false);
        }
      }}
      onClick={() => {
        if (id && imageRef.current) {
          singleImageSelected({ id, ref: imageRef.current });
          addToCanvas([{ id, ref: imageRef.current }]);
        }
      }}
    >
      {(isShowCheckbox || isMobile) && (
        <Checkbox
          style={{
            position: 'absolute',
            width: '16px',
            height: '16px',
          }}
          classNameOuterRadio={styles.checkboxOuterRadio}
          icon={
            <Icon
              name="sprite/checkbox-filled"
              color="#99461C"
              className={styles.checkbox}
            />
          }
          onClick={(e) => {
            if (url && imageRef.current) {
              multipleImagesSelected({ id, ref: imageRef.current });
            }
            e.stopPropagation();
          }}
          isSelected={isSelectedCheckbox}
        />
      )}

      <img
        ref={imageRef}
        className={styles.image}
        alt="library item"
        src={thumbnailURL}
      />
    </div>
  );
};
