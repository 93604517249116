import { forwardRef, MouseEventHandler, ReactNode } from 'react';

import cn from 'classnames';

import { CheckboxIcon } from '../Icons';
import {
  Size,
  TypographyPoppins,
  Variant,
} from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

export type FilterChipProps<T extends Variant> = {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  trailingAction?: MouseEventHandler<HTMLButtonElement>;
  isSelected?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
  className?: string;
  classNameLabel?: string;
  tickmarkSize?: number;
  hideTick?: boolean;
  type?: T;
  size?: Size[T];
};

export const FilterChip = forwardRef<
  HTMLButtonElement,
  FilterChipProps<Variant>
>(
  (
    {
      leadingIcon,
      trailingIcon,
      onClick,
      trailingAction,
      isSelected,
      isDisabled,
      children,
      className,
      tickmarkSize = 24,
      hideTick = false,
      classNameLabel,
      type,
      size,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <button
        className={cn(styles.chip, className, {
          [styles.selected]: isSelected,
          [styles.disabled]: isDisabled && !isSelected,
          [styles.disabledSelected]: isDisabled && isSelected,
          [styles.withLeadingIcon]: leadingIcon,
          [styles.withTrailingIcon]: trailingIcon,
          [styles.hideTick]: hideTick && !leadingIcon,
        })}
        onClick={onClick}
        disabled={isDisabled}
        {...props}
        ref={forwardedRef}
      >
        {isSelected && !hideTick ? (
          <span>
            <CheckboxIcon
              className={cn(styles.tickmark, {
                [styles.disabledTickmark]: isDisabled,
              })}
              fill="none"
              size={tickmarkSize}
            />
          </span>
        ) : leadingIcon ? (
          <span
            className={cn(styles.leadingIcon, {
              [styles.disabledLeadingIcon]: isDisabled,
            })}
          >
            {leadingIcon}
          </span>
        ) : null}
        <TypographyPoppins
          className={cn(styles.label, classNameLabel, {
            [styles.disabledLabel]: isDisabled,
            [styles.disabledSelectedLabel]: isDisabled && isSelected,
          })}
          type={type ? type : 'label'}
          size={size ? size : 'L'}
        >
          <span
            className={cn({
              [styles.disabledLabel]: isDisabled,
              [styles.disabledSelectedLabel]: isDisabled && isSelected,
            })}
          >
            {children}
          </span>
        </TypographyPoppins>
        {trailingIcon && (
          <button
            className={cn(styles.trailingIcon, {
              [styles.disabledTrailingIcon]: isDisabled,
              [styles.disabledSelectedTrailingIcon]: isDisabled && isSelected,
            })}
            onClick={(e) => {
              if (trailingAction) {
                e.stopPropagation();
                trailingAction(e);
              }
            }}
            disabled={isDisabled}
          >
            {trailingIcon}
          </button>
        )}
      </button>
    );
  },
);
