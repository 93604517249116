import { ResponseDocs } from '@api/docs';
import { Role } from '@api/users';
import { useBoardBlocks } from '@pages/BoardPage/useBoardBlocks';

import { LayoutGrid } from '../../../../components';

type Props = {
  boardId: string;
  isOwner: boolean;
  docs?: ResponseDocs;
  role?: Role | null;
  refetchRole: () => void;
  isArchived?: boolean;
};

export const Grid = ({
  boardId,
  isOwner,
  docs,
  role,
  refetchRole,
  isArchived,
}: Props) => {
  const imageBlockQuery = useBoardBlocks({ boardId, typeOfBlock: 'image' });
  const designBlockQuery = useBoardBlocks({ boardId, typeOfBlock: 'set' });
  const paletteBlockQuery = useBoardBlocks({
    boardId,
    typeOfBlock: 'color_palette',
  });

  const hasLoadedBlocks =
    imageBlockQuery.isFetched &&
    designBlockQuery.isFetched &&
    paletteBlockQuery.isFetched;

  const refetchQueries = () => {
    refetchRole();
  };

  return (
    <LayoutGrid
      allDataLoaded={hasLoadedBlocks}
      designBlocks={designBlockQuery.blocks}
      designBlocksLoading={designBlockQuery.isLoading}
      imageBlocks={imageBlockQuery.blocks}
      imageBlocksLoading={imageBlockQuery.isLoading}
      paletteBlocks={paletteBlockQuery.blocks}
      paletteBlocksLoading={paletteBlockQuery.isLoading}
      refetchQueries={refetchQueries}
      isOwner={isOwner}
      page="board"
      imageBlockHasNext={false}
      designBlocksHasNext={false}
      paletteBlocksHasNext={false}
      imageBlockFetchNext={imageBlockQuery.fetchNextPage}
      designBlocksFetchNext={designBlockQuery.fetchNextPage}
      paletteBlocksFetchNext={paletteBlockQuery.fetchNextPage}
      boardId={boardId}
      docs={docs}
      role={role}
      isArchived={isArchived}
    />
  );
};
