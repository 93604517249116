import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import { $archiveBoardId } from '@pages/BoardPage/model/archive';
import { useUnarchiveBoard } from '@pages/BoardPage/model/queries/use-unarchive-board';
import { $archiveFileIds } from '@pages/FileCard/model/archive';
import { useUnarchiveFile } from '@pages/FileCard/model/queries/use-unarchive-file';
import { $archiveHubId } from '@pages/HubPage/model/archive';
import { useUnarchiveHub } from '@pages/HubPage/model/queries/use-unarchive-hub';
import { $archiveDocId } from '@src/entities/doc/archive';
import { useUnarchiveDoc } from '@src/entities/doc/queries/use-unarchive-doc';
import { $entityType } from '@src/shared/utils/get-entity-type';

import { $isOpenUnarchiveDialog, unarchiveDialogClosed } from './model';

export const UnarchiveDialog = () => {
  const isOpenUnarchiveDialog = useUnit($isOpenUnarchiveDialog);
  const entityType = useUnit($entityType);
  const archiveHubId = useUnit($archiveHubId);
  const archiveBoardId = useUnit($archiveBoardId);
  const archiveDocId = useUnit($archiveDocId);
  const archiveFileIds = useUnit($archiveFileIds);

  const { unarchiveHubMutation } = useUnarchiveHub();
  const { unarchiveBoardMutation } = useUnarchiveBoard();
  const { unarchiveDocMutation } = useUnarchiveDoc();
  const { unarchiveFileMutation } = useUnarchiveFile();

  const headline =
    entityType === 'image' ||
    entityType === 'design' ||
    entityType === 'palette'
      ? `This file 
        is archived`
      : `This ${entityType} 
        is archived`;

  const action = () => {
    if (entityType === 'hub' && archiveHubId) {
      unarchiveHubMutation.mutate({ id: archiveHubId });
    } else if (entityType === 'board' && archiveBoardId) {
      unarchiveBoardMutation.mutate({ id: archiveBoardId });
    } else if (entityType === 'doc' && archiveDocId) {
      unarchiveDocMutation.mutate({ id: archiveDocId });
    } else if (
      (entityType === 'image' ||
        entityType === 'design' ||
        entityType === 'palette') &&
      Array.isArray(archiveFileIds) &&
      archiveFileIds.length > 0
    ) {
      unarchiveFileMutation.mutate({ ids: archiveFileIds, entityType });
    }

    unarchiveDialogClosed();
  };

  return (
    <Modal
      handleClose={unarchiveDialogClosed}
      showModal={isOpenUnarchiveDialog}
    >
      <Dialog
        headline={headline}
        description="Do you want to unarchive this?"
        action={action}
        actionLabel="Unarchive"
        closeAction={unarchiveDialogClosed}
      />
    </Modal>
  );
};
