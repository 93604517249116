import { User } from '@src/AppContext';
import { JSONContent } from '@tiptap/react';

import { BoardLvl1, BoardLvl2, BoardLvl3 } from './designs';
import { api } from './services';
import { Permissions } from './users';

export interface ResponseCreateDoc {
  created_at: string;
  created_by: number;
  id: string;
  parent: string | null;
  text: string | null;
  text_json: JSONContent | null;
  thumbnail: string | null;
  title: string;
  top_parent: string | null;
}

export interface ResponseDocs {
  count: number;
  next: null;
  previous: null;
  results: ResponseCreateDoc[];
}

export interface ResponseDoc {
  board: BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
  created_at: string;
  created_by: number;
  id: string;
  parent: any;
  text: any;
  json: JSONContent;
  html: string;
  thumbnail: any;
  hub: {
    id: string;
    name: string;
    thumbnail: any;
    created_by: Pick<
      User,
      'id' | 'first_name' | 'last_name' | 'email' | 'photo'
    >;
  };
  title: string;
  top_parent: any;
  previous_board: string;
  height: number;
  width: number;
  margin_top: number;
  margin_left: number;
  margin_right: number;
  margin_bottom: number;
  background_color: string;
  is_being_edited: boolean;
  custom_colors: string[];
  edited_by: Pick<User, 'id' | 'first_name' | 'last_name' | 'email' | 'photo'>;
  is_archived: boolean;
}

interface Doc {
  docId: string;
  is_archived?: boolean;
}

interface Docs {
  board?: string;
  is_archived?: boolean;
}

export interface CreateDoc {
  board?: string;
  title?: string;
}

export interface UpdateDoc extends Doc {
  title?: string;
  board?: string;
  redirect?: boolean;
  json?: JSONContent;
  text?: string;
  html?: string;
  height?: number;
  width?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
  background_color?: string | null;
  custom_colors?: string[];
  isDocRemovedFromBoard?: boolean; // we have one endpoint for actions related to updating a document (adding, editing, removing from a board (), etc.) so there is no obvious way on FE to determine that a document has been removed from a board, so I added the 'isDocRemovedFromBoard' flag
}

export type Member = {
  accepted_at: string;
  doc: string;
  email: string;
  id: number;
  is_joined: boolean;
  is_owner: boolean;
  permissions: Permissions[];
  board?: string;
  user: Pick<
    User,
    'id' | 'first_name' | 'last_name' | 'email' | 'photo'
  > | null;
};

type UserInvitationDoc = {
  email: string;
};

export type InvitationDoc = {
  id: string;
  users: UserInvitationDoc[];
  permissions: Permissions[];
};

export interface ResponseInvitee {
  members: Member[];
  member_seats_available: number;
}

export interface RemoveInviteeDoc {
  id: string;
  members: number[];
}

export const getDocs = async ({
  ordering,
  is_archived,
}: {
  ordering?: string;
  is_archived?: boolean;
}): Promise<ResponseDocs> => {
  const { data } = await api.get('/docs/?limit=1000', {
    params: { ordering, is_archived },
  });
  return data;
};

export const getDocsOfBoard = async ({
  board,
  is_archived,
}: Docs): Promise<ResponseDocs> => {
  const { data } = await api.get(`/docs/?board=${board}`, {
    params: { is_archived },
  });
  return data;
};

export const getDoc = async ({
  docId,
  is_archived,
}: Doc): Promise<ResponseDoc> => {
  const { data } = await api.get(`/docs/${docId}/`, {
    params: { is_archived },
  });
  return data;
};

export const createDoc = async ({
  board,
  title,
}: CreateDoc): Promise<ResponseCreateDoc> => {
  const { data } = await api.post('/docs/', { title, board });
  return data;
};

export const updateDoc = async ({
  docId,
  title,
  board,
  json,
  text,
  html,
  height,
  width,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  background_color,
}: UpdateDoc): Promise<ResponseDoc> => {
  const { data } = await api.patch(`/docs/${docId}/`, {
    title,
    board,
    json,
    text,
    html,
    margin_bottom: marginBottom,
    margin_top: marginTop,
    margin_left: marginLeft,
    margin_right: marginRight,
    height,
    width,
    background_color,
  });
  return data;
};

export const updateDocCustomColors = async ({
  docId,
  custom_colors,
}: UpdateDoc): Promise<ResponseDoc> => {
  const { data } = await api.patch(`/docs/${docId}/`, { custom_colors });
  return data;
};

export const softDeleteDoc = async (id: string) => {
  const { data } = await api.post(`/docs/${id}/soft_delete/`);
  return data;
};

export const permanentDelete = async (id: string): Promise<unknown> => {
  const { data } = await api.post(`/docs/${id}/permanent_delete/`);
  return data;
};

export const undoSoftDeleteDoc = async (id: string) => {
  const { data } = await api.post(`/docs/${id}/undo_soft_delete/`);
  return data;
};

export const downloadPDF = async ({ id }: { id: string }): Promise<Blob> => {
  const { data } = await api.get(`/docs/${id}/export_pdf/`, {
    responseType: 'blob',
  });
  return data;
};

export const getInvitees = async (
  id: string | null,
): Promise<ResponseInvitee> => {
  const { data } = await api.get(`/docs/${id}/members/`);
  return data;
};

export const inviteToDoc = async ({
  id,
  users,
  permissions,
}: InvitationDoc): Promise<InvitationDoc> => {
  const { data } = await api.post(`/docs/${id}/members/invite/`, {
    users,
    permissions,
  });
  return data;
};

export const deleteFromDoc = async ({ id, members }: RemoveInviteeDoc) => {
  const { data } = await api.post(`/docs/${id}/members/remove/`, {
    members,
  });
  return data;
};

export const archiveDoc = async ({ id }: { id: string }): Promise<unknown> => {
  const { data } = await api.post(`/docs/${id}/archive/`);
  return data;
};

export const unarchiveDoc = async ({
  id,
}: {
  id: string;
}): Promise<unknown> => {
  const { data } = await api.post(`/docs/${id}/undo_archive/`);
  return data;
};
