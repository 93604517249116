import '@fontsource-variable/caveat';
import '@fontsource-variable/montserrat';
import '@fontsource-variable/noto-serif';
import '@fontsource-variable/nunito';
import '@fontsource-variable/open-sans';
import '@fontsource-variable/oswald';
import '@fontsource-variable/playfair-display';
import '@fontsource/alex-brush';
import '@fontsource/arvo';
import '@fontsource/cormorant-garamond';
import '@fontsource/lato';
import '@fontsource/merriweather';
import '@fontsource/monsieur-la-doulaise';
import '@fontsource/rouge-script';
import '@fontsource/zeyada';
import '@fontsource-variable/dancing-script';
import '@fontsource/pacifico';
import '@fontsource/great-vibes';
import '@fontsource/lobster';
import '@fontsource/patrick-hand';
import '@fontsource/gloria-hallelujah';
import '@fontsource/homemade-apple';
import '@fontsource/indie-flower';
import '@fontsource/archivo-black';
import '@fontsource-variable/raleway';
import '@fontsource-variable/cinzel';

export const fontNames: string[] = [
  'Alex Brush',
  'Arial',
  'Archivo Black',
  'Arvo',
  'Baskerville',
  'Caveat Variable',
  'Cinzel Variable',
  'Cormorant Garamond',
  'Dancing Script Variable',
  'Futura',
  'Georgia',
  'Gloria Hallelujah',
  'Great Vibes',
  'Helvetica',
  'Homemade Apple',
  'Indie Flower',
  'Lato',
  'Lobster',
  'Merriweather',
  'Monsieur La Doulaise',
  'Montserrat Variable',
  'Noto Serif Variable',
  'Nunito Sans Variable',
  'Open Sans',
  'Oswald Variable',
  'Pacifico',
  'Patrick Hand',
  'Playfair Display Variable',
  'Raleway Variable',
  'Rouge Script',
  'Times New Roman',
  'Zeyada',
];
