export interface SpritesMap {
  sprite:
    | '3-dot-menu'
    | 'action-items-list'
    | 'add-background-filled'
    | 'add-background'
    | 'align-bottom'
    | 'align-center'
    | 'align-left'
    | 'align-middle'
    | 'align-right'
    | 'align-top'
    | 'apple'
    | 'archive'
    | 'arrow-down'
    | 'arrow-expand'
    | 'arrow-left'
    | 'arrow-right'
    | 'arrow-shrink'
    | 'arrow-swipe-left'
    | 'arrow-swipe-right'
    | 'arrow-undo'
    | 'arrow-width'
    | 'at-sign'
    | 'bin-open-soft-colored'
    | 'bin'
    | 'board-filled'
    | 'board'
    | 'broom'
    | 'building-block-filled'
    | 'building-block-light'
    | 'building-block'
    | 'bunting-soft-colored'
    | 'calendar-blank-filled'
    | 'calendar-blank'
    | 'calendar-date-filled'
    | 'calendar-date'
    | 'camera'
    | 'candy'
    | 'caret-down'
    | 'caret-left'
    | 'caret-right'
    | 'caret-up'
    | 'caution-colored'
    | 'check-circle'
    | 'checkbox-blank'
    | 'checkbox-filled'
    | 'checkbox-indeterminate'
    | 'checkbox'
    | 'checklist-colored'
    | 'chevron-down'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-up'
    | 'clock'
    | 'color-drop-filled'
    | 'color-drop'
    | 'color-dropper'
    | 'color-wheel-ring-colored'
    | 'contract-soft-colored'
    | 'contract'
    | 'copy-filled'
    | 'copy-studio'
    | 'copy'
    | 'crop'
    | 'cursor-drag-horizontally-colored'
    | 'cursor-drag-left-colored'
    | 'cursor-drag-right-colored'
    | 'cursor-drag-vertically-colored'
    | 'cursor-rotate-colored'
    | 'cursor'
    | 'cut'
    | 'dashboard-colored'
    | 'demo'
    | 'distribute-horizontally'
    | 'distribute-vertically'
    | 'doc-colored'
    | 'doc-tabel-transparent'
    | 'doc-table-add'
    | 'doc-table-merge'
    | 'doc-table'
    | 'doc'
    | 'dock-table-split'
    | 'dollar-sign-light'
    | 'dollar-sign'
    | 'dot-menu'
    | 'dot'
    | 'download'
    | 'drop-indicator'
    | 'duplicate'
    | 'elements-filled'
    | 'elements'
    | 'exclamation'
    | 'export'
    | 'eye-closed'
    | 'eye-opened'
    | 'FAQ-filled'
    | 'FAQ'
    | 'file-3d-colored'
    | 'file-ae-colored'
    | 'file-ai-colored'
    | 'file-cal-colored'
    | 'file-eml-colored'
    | 'file-fig-colored'
    | 'file-html-colored'
    | 'file-indd-colored'
    | 'file-locked-colored'
    | 'file-mp3-colored'
    | 'file-mp4-colored'
    | 'file-pdf-colored'
    | 'file-png-colored'
    | 'file-ppt-colored'
    | 'file-psd-colored'
    | 'file-sketch-colored'
    | 'file-sql-colored'
    | 'file-txt-colored'
    | 'file-xls-colored'
    | 'file-zip-colored'
    | 'film-strip'
    | 'flag-filled'
    | 'flag'
    | 'folder-empty-colored'
    | 'folder-full-colored'
    | 'gears-colored'
    | 'google-colored'
    | 'grid'
    | 'group'
    | 'hashtag'
    | 'home-filled'
    | 'home'
    | 'hub-filled'
    | 'hub'
    | 'image-cards-soft-colored'
    | 'image-filled'
    | 'image'
    | 'infinite-canvas'
    | 'information-filled'
    | 'information'
    | 'invoice-soft-colored'
    | 'lamp-and-chair-colored'
    | 'layer-back'
    | 'layer-front'
    | 'library-filled'
    | 'library'
    | 'link'
    | 'location-pin-filled'
    | 'location-pin'
    | 'lock-closed-filled'
    | 'lock-closed-light'
    | 'lock-closed'
    | 'lock-colored'
    | 'logout'
    | 'magnifier'
    | 'magnifying-glass-colored'
    | 'martini-colored'
    | 'menu-hamburger-left'
    | 'menu-hamburger-right'
    | 'microphone'
    | 'moodboard-soft-colored'
    | 'page-landscape-add'
    | 'page-landscape'
    | 'pageless'
    | 'paint-formatting'
    | 'paintbrush-with-magic-colored'
    | 'paintbrush'
    | 'paintbucket'
    | 'palette-soft-colored'
    | 'paper-plane-curly-colored'
    | 'paper-plane-filled'
    | 'paper-plane-with-shape-colored'
    | 'paper-plane'
    | 'paste'
    | 'pen-filled'
    | 'pen'
    | 'person'
    | 'personalize'
    | 'plus'
    | 'public'
    | 'question-in-circle'
    | 'question'
    | 'reminder-colored'
    | 'remove-background-filled'
    | 'remove-background'
    | 'rotate-clockwise'
    | 'rotate-counterclockwise'
    | 'settings-filled'
    | 'settings'
    | 'shuffle'
    | 'side-menu'
    | 'source-filled'
    | 'source'
    | 'sparkles-colored'
    | 'sparkles-vai-colored'
    | 'star-pointy'
    | 'sticky-action'
    | 'sticky-add-filled'
    | 'sticky-add'
    | 'sticky-note-soft-colored'
    | 'sticky'
    | 'studio-arc'
    | 'studio-arrow'
    | 'studio-circle'
    | 'studio-double-circle'
    | 'studio-ellipse'
    | 'studio-filled'
    | 'studio-hexagon'
    | 'studio-line'
    | 'studio-rectangle'
    | 'studio-six-pointed-star'
    | 'studio-slice-triangle'
    | 'studio'
    | 'task'
    | 'team-24'
    | 'team-soft-colored'
    | 'text-align-centre'
    | 'text-align-left'
    | 'text-align-right'
    | 'text-bold'
    | 'text-bullet-list'
    | 'text-color-colored'
    | 'text-color'
    | 'text-font'
    | 'text-H1'
    | 'text-H2'
    | 'text-H3'
    | 'text-italic'
    | 'text-numbered-list'
    | 'text-strikethrough'
    | 'text-task-list'
    | 'text-underline'
    | 'text-wrap-behind-colored'
    | 'text-wrap-break-colored'
    | 'text-wrap-front-colored'
    | 'text-wrap-inline-colored'
    | 'text-wrap-square-colored'
    | 'text-wrap-square-right-colored'
    | 'text-wrap-tight-colored'
    | 'text'
    | 'textbox'
    | 'tick'
    | 'tidy-up-filled'
    | 'tidy-up'
    | 'todo-list-soft-colored'
    | 'toggle'
    | 'ungroup'
    | 'upload'
    | 'v-logo'
    | 'vai-colored'
    | 'vai-filled'
    | 'vai-style'
    | 'vai'
    | 'window-with-plant-colored'
    | 'x'
    | 'zoom-in-filled'
    | 'zoom-in'
    | 'zoom-out-filled'
    | 'zoom-out';
}
export const SPRITES_META: {
  [Key in keyof SpritesMap]: {
    filePath: string;
    items: Record<
      SpritesMap[Key],
      {
        viewBox: string;
        width: number;
        height: number;
      }
    >;
  };
} = {
  sprite: {
    filePath: 'sprite.ddcf9f4d.svg',
    items: {
      '3-dot-menu': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'action-items-list': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'add-background-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'add-background': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'align-bottom': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'align-center': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'align-left': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'align-middle': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'align-right': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'align-top': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      apple: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      archive: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'arrow-down': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'arrow-expand': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'arrow-left': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'arrow-right': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'arrow-shrink': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'arrow-swipe-left': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'arrow-swipe-right': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'arrow-undo': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'arrow-width': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'at-sign': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'bin-open-soft-colored': {
        viewBox: '0 0 81 80',
        width: 81,
        height: 80,
      },
      bin: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'board-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      board: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      broom: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'building-block-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'building-block-light': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'building-block': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'bunting-soft-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'calendar-blank-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'calendar-blank': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'calendar-date-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'calendar-date': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      camera: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      candy: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'caret-down': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'caret-left': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'caret-right': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'caret-up': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'caution-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'check-circle': {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      'checkbox-blank': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'checkbox-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'checkbox-indeterminate': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      checkbox: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'checklist-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'chevron-down': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'chevron-left': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'chevron-right': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'chevron-up': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      clock: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'color-drop-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'color-drop': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'color-dropper': {
        viewBox: '0 0 40 36',
        width: 40,
        height: 36,
      },
      'color-wheel-ring-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'contract-soft-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      contract: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'copy-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'copy-studio': {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      copy: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      crop: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'cursor-drag-horizontally-colored': {
        viewBox: '0 0 26 24',
        width: 26,
        height: 24,
      },
      'cursor-drag-left-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'cursor-drag-right-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'cursor-drag-vertically-colored': {
        viewBox: '0 0 24 26',
        width: 24,
        height: 26,
      },
      'cursor-rotate-colored': {
        viewBox: '0 0 24 26',
        width: 24,
        height: 26,
      },
      cursor: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      cut: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'dashboard-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      demo: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'distribute-horizontally': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'distribute-vertically': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'doc-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'doc-tabel-transparent': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'doc-table-add': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'doc-table-merge': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'doc-table': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      doc: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'dock-table-split': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'dollar-sign-light': {
        viewBox: '0 0 10 20',
        width: 10,
        height: 20,
      },
      'dollar-sign': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'dot-menu': {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      dot: {
        viewBox: '0 0 12 12',
        width: 12,
        height: 12,
      },
      download: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'drop-indicator': {
        viewBox: '0 0 6 92',
        width: 6,
        height: 92,
      },
      duplicate: {
        viewBox: '0 0 19 18',
        width: 19,
        height: 18,
      },
      'elements-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      elements: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      exclamation: {
        viewBox: '0 0 16 16',
        width: 16,
        height: 16,
      },
      export: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'eye-closed': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'eye-opened': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'FAQ-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      FAQ: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-3d-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-ae-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-ai-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-cal-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-eml-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-fig-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-html-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-indd-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-locked-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-mp3-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-mp4-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-pdf-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-png-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-ppt-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-psd-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-sketch-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-sql-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-txt-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-xls-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'file-zip-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'film-strip': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'flag-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      flag: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'folder-empty-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'folder-full-colored': {
        viewBox: '0 0 80 81',
        width: 80,
        height: 81,
      },
      'gears-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'google-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      grid: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      group: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      hashtag: {
        viewBox: '0 0 25 24',
        width: 25,
        height: 24,
      },
      'home-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      home: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'hub-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      hub: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'image-cards-soft-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'image-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      image: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'infinite-canvas': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'information-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      information: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'invoice-soft-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'lamp-and-chair-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'layer-back': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'layer-front': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'library-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      library: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      link: {
        viewBox: '0 0 18 19',
        width: 18,
        height: 19,
      },
      'location-pin-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'location-pin': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'lock-closed-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'lock-closed-light': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'lock-closed': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'lock-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      logout: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      magnifier: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'magnifying-glass-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'martini-colored': {
        viewBox: '0 0 80 81',
        width: 80,
        height: 81,
      },
      'menu-hamburger-left': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'menu-hamburger-right': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      microphone: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'moodboard-soft-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'page-landscape-add': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'page-landscape': {
        viewBox: '0 0 25 24',
        width: 25,
        height: 24,
      },
      pageless: {
        viewBox: '0 0 25 24',
        width: 25,
        height: 24,
      },
      'paint-formatting': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'paintbrush-with-magic-colored': {
        viewBox: '0 0 200 104',
        width: 200,
        height: 104,
      },
      paintbrush: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      paintbucket: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'palette-soft-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'paper-plane-curly-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'paper-plane-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'paper-plane-with-shape-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'paper-plane': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      paste: {
        viewBox: '0 0 18 18',
        width: 18,
        height: 18,
      },
      'pen-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      pen: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      person: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      personalize: {
        viewBox: '0 0 25 24',
        width: 25,
        height: 24,
      },
      plus: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      public: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'question-in-circle': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      question: {
        viewBox: '0 0 8 13',
        width: 8,
        height: 13,
      },
      'reminder-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'remove-background-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'remove-background': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'rotate-clockwise': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'rotate-counterclockwise': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'settings-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      settings: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      shuffle: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'side-menu': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'source-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      source: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'sparkles-colored': {
        viewBox: '0 0 80 81',
        width: 80,
        height: 81,
      },
      'sparkles-vai-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      'star-pointy': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'sticky-action': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'sticky-add-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'sticky-add': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'sticky-note-soft-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      sticky: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-arc': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-arrow': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-circle': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-double-circle': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-ellipse': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-hexagon': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-line': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-rectangle': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-six-pointed-star': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'studio-slice-triangle': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      studio: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      task: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'team-24': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'team-soft-colored': {
        viewBox: '0 0 81 80',
        width: 81,
        height: 80,
      },
      'text-align-centre': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-align-left': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-align-right': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-bold': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-bullet-list': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-color-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-color': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-font': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-H1': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-H2': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-H3': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-italic': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-numbered-list': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-strikethrough': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-task-list': {
        viewBox: '0 0 25 24',
        width: 25,
        height: 24,
      },
      'text-underline': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-wrap-behind-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-wrap-break-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-wrap-front-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-wrap-inline-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-wrap-square-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-wrap-square-right-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'text-wrap-tight-colored': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      text: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      textbox: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      tick: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'tidy-up-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'tidy-up': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'todo-list-soft-colored': {
        viewBox: '0 0 80 80',
        width: 80,
        height: 80,
      },
      toggle: {
        viewBox: '0 0 25 24',
        width: 25,
        height: 24,
      },
      ungroup: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      upload: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'v-logo': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'vai-colored': {
        viewBox: '0 0 22 22',
        width: 22,
        height: 22,
      },
      'vai-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'vai-style': {
        viewBox: '0 0 25 24',
        width: 25,
        height: 24,
      },
      vai: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'window-with-plant-colored': {
        viewBox: '0 0 200 104',
        width: 200,
        height: 104,
      },
      x: {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'zoom-in-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'zoom-in': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'zoom-out-filled': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
      'zoom-out': {
        viewBox: '0 0 24 24',
        width: 24,
        height: 24,
      },
    },
  },
};
