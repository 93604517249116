import {
  createContext,
  PointerEvent,
  PropsWithChildren,
  useContext,
} from 'react';

import { useDragControls } from 'framer-motion';

import {
  Modal,
  TypographyPoppins,
  VaiColored,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { useModalShortcutDisable } from '@pages/StudioPage/hooks/useModalShortcutDisable';

import styles from './styles.module.css';

type ModalContextType = {
  closeModal: () => void;
  showModal: boolean;
  dragControls: ReturnType<typeof useDragControls>;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

const useModalContext = () => {
  const ctx = useContext(ModalContext);

  if (!ctx) {
    throw new Error('Must be used inside a VaiModalProvider');
  }

  return ctx;
};

const VaiModalProvider = ({
  children,
  closeModal,
  showModal,
}: PropsWithChildren<{
  closeModal: () => void;
  showModal: boolean;
}>) => {
  const dragControls = useDragControls();

  return (
    <ModalContext.Provider
      value={{
        closeModal,
        showModal,
        dragControls,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const VaiModal = ({ children }: React.PropsWithChildren) => {
  const { closeModal, showModal, dragControls } = useModalContext();

  const { isMobile } = useWindowSize();

  useModalShortcutDisable(showModal);

  return (
    <Modal
      showModal={showModal}
      handleClose={closeModal}
      className={styles.modal}
      isVai
      // Drag is disabled on mobile
      drag={!isMobile}
      dragListener={false}
      dragElastic={false}
      dragMomentum={false}
      dragControls={dragControls}
    >
      {children}
    </Modal>
  );
};

const VaiModalHeader = () => {
  const { closeModal, dragControls } = useModalContext();

  const startDragging = (e: PointerEvent<HTMLDivElement>) => {
    dragControls.start(e);
  };

  return (
    <div
      className={styles.header}
      onPointerDown={startDragging}
      style={{
        touchAction: 'none',
      }}
    >
      <div className={styles.headerLeft}>
        <VaiColored />
        <TypographyPoppins type="title" size="M" className={styles.headerText}>
          Vai
        </TypographyPoppins>
      </div>
      <button tabIndex={0} className={styles.closeButton} onClick={closeModal}>
        <Icon
          name="sprite/plus"
          style={{
            transform: 'rotate(45deg)',
          }}
        />
      </button>
    </div>
  );
};

const VaiModalContent = ({ children }: PropsWithChildren) => {
  return <div className={styles.mainContent}>{children}</div>;
};

const VaiModalFooter = ({ children }: PropsWithChildren) => {
  return <div className={styles.footer}>{children}</div>;
};

export {
  VaiModalProvider,
  VaiModal,
  VaiModalHeader,
  VaiModalFooter,
  VaiModalContent,
};
