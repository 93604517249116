import { useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  Divider,
  SideSheet,
  Team,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Member } from '@api/docs';
import { Member as MemberHub } from '@api/hubs';
import { Permissions } from '@api/users';
import { useChangePermissions } from '@pages/BoardPage/model/queries/useChangePermissions';
import { SidebarCollapsibleItem } from '@src/entities/navbar';
import { expandedSidebar } from '@src/entities/navbar/model';

import { Description } from '../ui/description';
import { ExtraSeats } from '../ui/extra-seats';
import { GuestSeat } from '../ui/guest-seat';
import { Invite } from '../ui/invite';
import { InviteField } from '../ui/invite-field';
import { Invitees } from '../ui/invitees';
import { Title } from '../ui/title';
import { ExistingUser } from '../ui/user/existing-user';
import { RemovedUser } from '../ui/user/removed-user';
import {
  $emails,
  $permissions,
  $query,
  emailsCleared,
  emailsEntered,
  permissionsSelected,
  queryCleared,
  queryEntered,
} from './model/inviting';
import {
  $id,
  $isBoardShareSheetOpen,
  boardShareSheetClosed,
} from './model/open-share-sheet';
import { useInviteToBoard } from './model/queries/use-invite-to-board';
import { useInvitees } from './model/queries/use-invitees';
import { useRemoveInvitee } from './model/queries/use-remove-from-board';
import { useSuggestions } from './model/queries/use-suggestions';

import styles from './styles.module.css';

export const BoardShareSheet = () => {
  const [isInvite, setInvite] = useState(true);

  const isBoardShareSheetOpen = useUnit($isBoardShareSheetOpen);
  const id = useUnit($id);
  const query = useUnit($query);
  const emails = useUnit($emails);
  const permissions = useUnit($permissions);

  const { suggestionsQuery } = useSuggestions({ query, emails, id });
  const { inviteesQuery, currentPermissions } = useInvitees({ id });

  const isNotEnoughSeats = inviteesQuery.data
    ? inviteesQuery.data?.member_seats_available < emails.length
    : false;

  const onSuccess = () => {
    if (emails.length > 0) {
      clear();
    }

    setInvite(true);
  };

  const onError = () => setInvite(true);

  const { sendInvitation } = useInviteToBoard({ onSuccess, onError });
  const { changePermissions, error } = useChangePermissions({});
  const { removeInvitee } = useRemoveInvitee();

  const invite = () => {
    if (isInvite && id) {
      sendInvitation({
        id,
        users: emails,
        permissions,
      });

      setInvite(false);
    }
  };

  const handleChangePermissions = ({
    permissions,
    member,
  }: {
    permissions: Permissions[];
    member: Member | MemberHub;
  }) => {
    if (id) {
      changePermissions({
        boardId: id,
        member: member.id,
        email: member.email,
        permissions,
      });
    }
  };

  const remove = (member: MemberHub) => {
    removeInvitee({
      boardId: member.board,
      members: [member.id],
      email: member.email,
      hubId: member.hub,
    });
  };

  const clear = () => {
    queryCleared();
    emailsCleared();
  };

  return (
    <SideSheet
      className={styles.sideSheetContainer}
      showSheet={isBoardShareSheetOpen}
      closeSideSheet={boardShareSheetClosed}
      handleBackdropClick={boardShareSheetClosed}
    >
      <SideSheet.Heading
        className={styles.headingContainer}
        closeSideSheet={boardShareSheetClosed}
      >
        Your team
      </SideSheet.Heading>
      <SideSheet.Body className={styles.bodyContainer}>
        <SideSheet.ContentContainer>
          <div
            className={cn(styles.inviteByEmail, {
              [styles.noRemoved]:
                inviteesQuery.data &&
                !inviteesQuery.data.removed_members.length,
            })}
          >
            <Title
              title="Invite others to this board"
              description="They will count towards your hub seat limit"
            />
            {emails.length > 0 && (
              <GuestSeat
                permissions={permissions}
                permissionsSelected={permissionsSelected}
                currentPermissions={currentPermissions}
                type="board"
              />
            )}
            <InviteField
              isNotEnoughSeats={isNotEnoughSeats}
              suggestions={suggestionsQuery.data ?? []}
              invitation={query}
              setInvitation={(s: string) => queryEntered(s)}
              users={emails}
              setUsers={emailsEntered}
              clear={clear}
            />
            {suggestionsQuery.data &&
              suggestionsQuery.data.length > 0 &&
              emails.length === 0 && (
                <ul className={styles.suggestions}>
                  {suggestionsQuery.data.map((user) => (
                    <ExistingUser
                      key={user.id}
                      id={id}
                      user={user}
                      sendInvitation={sendInvitation}
                      currentPermissions={currentPermissions}
                    />
                  ))}
                </ul>
              )}
            {emails.length > 0 && (
              <Invite isNotEnoughSeats={isNotEnoughSeats} invite={invite} />
            )}
            <Description
              membeSeatsAvailable={inviteesQuery.data?.member_seats_available}
              numberGuests={emails.length}
            />
            <ExtraSeats />
            <Divider className={styles.divider} type="short-line" />
            <Title title="People with access" />
            {inviteesQuery.data ? (
              <Invitees
                invitees={inviteesQuery.data}
                remove={remove}
                currentPermissions={currentPermissions}
                handleChangePermissions={handleChangePermissions}
                error={error}
              />
            ) : null}
          </div>
          {inviteesQuery.data && inviteesQuery.data.removed_members.length ? (
            <div className={styles.invitees}>
              <SidebarCollapsibleItem
                className={styles.navItem}
                classNameChildren={styles.navItemChildren}
                classNameIconLeft={styles.iconLeft}
                classNameIconRight={styles.iconRight}
                name="Quickly invite others"
                description="These people have access to other items in this hub. Adjust the toggles to invite them here."
                leftIcon={<Team fill="none" />}
                expandSidebar={() => expandedSidebar()}
              >
                <ul className={styles.guestList}>
                  {inviteesQuery.data.removed_members.map((user) => {
                    return (
                      <RemovedUser
                        key={user.email}
                        id={id}
                        user={user}
                        sendInvitation={sendInvitation}
                        currentPermissions={currentPermissions}
                      />
                    );
                  })}
                </ul>
              </SidebarCollapsibleItem>
            </div>
          ) : null}
        </SideSheet.ContentContainer>
      </SideSheet.Body>
      <TooltipRadix description="Coming soon: Make selected docs private">
        <span
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <SideSheet.BottomControls
            className={styles.private}
            mainType="outlined"
            hideCancel
            primaryLabel="Make private"
            icon={<Icon name="sprite/lock-closed" />}
            closeSideSheet={() => {}}
            mainClick={() => {}}
            isDisabled
          />
        </span>
      </TooltipRadix>
    </SideSheet>
  );
};
