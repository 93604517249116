import { DeletedItemType } from './bin';
import { BoardLvl1, BoardLvl2, BoardLvl3, Hub } from './designs';
import { api, BlocksResponse, File } from './services';
import { Permissions } from './users';

type User = {
  first_name: string | null;
  id: number;
  last_name: string | null;
  photo: any;
  email: string;
};

export interface UserInvitation {
  email: string;
  name?: string;
  avatar?: string;
}

export interface Invitee {
  member: number | string;
  permissions: Permissions[];
}

export interface Invitation {
  id: string;
  nested_board?: string[];
  message?: string;
  permissions: Permissions[];
  users: UserInvitation[];
  boardName?: string;
  setOn?: (value: boolean) => void;
}

export interface MakeRootBoard {
  boardId: string;
  parentBoardId?: string;
}

export interface MakeRootBoardResponse {
  boardId: string;
  name: string;
}

export interface ChangingPermissions extends Invitee {
  email?: string;
  boardId: string;
}

export interface RemoveInvitee {
  email?: string;
  boardId: string;
  members: number[] | string[];
  boardName?: string;
  noBoardAccess?: boolean;
  hubId?: string | null;
}

export interface Member {
  created_at: string;
  accepted_at: string;
  board: string;
  email: string;
  hub: string | null;
  id: number;
  is_joined: boolean;
  is_owner: boolean;
  permissions: Permissions[];
  user: User | null;
}

export interface ResponseInvitee {
  members: Member[];
  member_seats_available: number;
  removed_members: { email: string }[];
}

export interface MoveToBoard {
  parent: string;
  children: string;
}

export type UpdateBoard = {
  name?: string;
  description?: string;
  boardId: string;
};

export type Board = {
  block_count: number;
  children: Board[];
  description: string | null;
  has_nested_boards: boolean;
  hub: { id: string; name: string; is_removed: boolean } | null;
  id: string;
  is_archived: boolean;
  is_removed: boolean;
  is_shared: boolean;
  level: 0 | 1 | 2;
  name: string;
  parent: Hub | BoardLvl1 | BoardLvl2 | BoardLvl3 | null | undefined;
  permissions: string[];
  share: { allow_comments: boolean; id: string } | null;
  top_parent: {
    id: string;
    name: string;
    parent?: { type: 'hub'; name: string };
  } | null;
  recent_images: Array<{
    id: string;
    file: File;
  }>;
};

export type BoardResponse = Board & {
  children: Board[];
};

export type ResponseArchiveBoard = {
  created_at: string;
  description: string;
  id: string;
  level: number;
  name: string;
  parent: {
    created_by: {
      email: string;
      first_name: string;
      id: number;
      last_name: string;
      photo: {
        full_size: string;
        medium_square_crop: string;
        small_square_crop: string;
        thumbnail: string;
      } | null;
    };
    id: string;
    name: string;
    thumbnail: {
      full_size: string;
      thumbnail: string;
    } | null;
    type: 'hub';
  };
};

//@ts-ignore
export const getBoards = async (params): Promise<Array<Board>> => {
  const { data } = await api.get('/boards/', {
    params,
  });
  return data;
};

export const getBoard = async ({
  id,
  is_archived,
}: {
  id: string;
  is_archived?: boolean;
}): Promise<Board> => {
  const { data } = await api.get(`/boards/${id}/`, {
    params: { is_archived },
  });
  return data;
};

export const deleteBoard = async (id: string) => {
  const { data } = await api.delete(`/boards/${id}/`);
  return data;
};

export const softDeleteBoard = async (id: string) => {
  const { data } = await api.post(`/boards/bulk_soft_delete/`, {
    boards: [id],
  });
  return data;
};

export const permanentDelete = async (id: string): Promise<unknown> => {
  const { data } = await api.post(`/boards/permanent_delete/`, {
    board: id,
  });
  return data;
};

//@ts-ignore
export const createBoard = async (payload) => {
  const { data } = await api.post('/boards/', payload);

  if (payload.images) {
    await api.post(`/boards/${data.id}/add_images/`, {
      image_ids: payload.images,
    });
  }

  return data;
};
//@ts-ignore
export const addImageToBoard = async (boardId, payload) => {
  const { data } = await api.post(`/boards/${boardId}/add_images/`, payload);
  return data;
};
//@ts-ignore
export const getBoardImages = async (boardId) => {
  const { data } = await api.get(`/boards/${boardId}/images/`);
  return data;
};

export const updateBoard = async ({
  boardId,
  description,
  name,
}: UpdateBoard) => {
  const { data } = await api.patch(`/boards/${boardId}/`, {
    description,
    name,
  });
  return data;
};

export const restoreBoard = async (item: DeletedItemType): Promise<unknown> => {
  const { data } = await api.post(`/boards/bulk_restore_deleted/`, {
    boards: [item.id],
  });
  return data;
};
//@ts-ignore
export const addOrMoveBoardToHub = async ({ hub, boards }) => {
  const { data } = await api.post(`/boards/add_or_move_to_hub/`, {
    hub,
    boards,
  });
  return data;
};
//@ts-ignore
export const removeBoardFromHub = async ({ hub, boards }) => {
  const { data } = await api.post(`/boards/remove_from_hub/`, {
    hub,
    boards,
  });
  return data;
};

export const getInvitees = async (
  boardId: string | null,
): Promise<ResponseInvitee> => {
  const { data } = await api.get(`/boards/${boardId}/members/`);
  return data;
};

export const inviteToBoard = async ({
  id,
  nested_board,
  message,
  users,
  permissions,
}: Invitation): Promise<Invitation> => {
  const { data } = await api.post(`/boards/${id}/members/invite/`, {
    nested_board,
    message,
    users,
    permissions,
  });
  return data;
};

export const changeInviteePermissions = async ({
  boardId,
  member,
  permissions,
}: ChangingPermissions) => {
  const { data } = await api.post(`/boards/${boardId}/members/permissions/`, {
    member,
    permissions,
  });
  return data;
};

export const deleteInvitee = async ({ boardId, members }: RemoveInvitee) => {
  const { data } = await api.post(`/boards/${boardId}/members/remove/`, {
    members,
  });
  return data;
};

export const turnIntoRootBoard = async ({
  boardId,
}: MakeRootBoard): Promise<MakeRootBoardResponse> => {
  const { data } = await api.post(`/boards/tree/make_root/`, {
    board: boardId,
  });
  return data;
};

export const moveToBoard = async ({
  parent,
  children,
}: MoveToBoard): Promise<any> => {
  const { data } = await api.post(`/boards/tree/move_to/`, {
    parent,
    children,
  });
  return data;
};

export const BlockChoiceMapping = {
  image: 0,
  set: 1,
  show: 2,
  color_palette: 3,
  // background removed blocks are 4 and sets from lite are 5 but we do not need them here
} as const;

export const getAllBlocksOfBoard = async ({
  boardId,
  typeOfBlock,
  offset,
  is_archived,
}: {
  boardId: string;
  typeOfBlock?: keyof typeof BlockChoiceMapping;
  offset: number;
  is_archived?: boolean;
}): Promise<BlocksResponse> => {
  const { data } = await api.get(`/boards/${boardId}/blocks/`, {
    params: {
      offset,
      block_type: typeOfBlock ? BlockChoiceMapping[typeOfBlock] : undefined,
      is_archived,
    },
  });

  return data;
};

export const archiveBoard = async ({
  id,
}: {
  id: string;
}): Promise<ResponseArchiveBoard> => {
  const { data } = await api.post(`/boards/archive/`, {
    board: id,
  });
  return data;
};

export const unarchiveBoard = async ({
  id,
}: {
  id: string;
}): Promise<ResponseArchiveBoard> => {
  const { data } = await api.post(`/boards/undo_archive/`, {
    board: id,
  });
  return data;
};
