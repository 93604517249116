import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage as TStage } from 'konva/lib/Stage';
import { isMobile } from 'react-device-detect';
import { Group, Layer, Rect, Stage } from 'react-konva';
import { useMediaQuery } from 'react-responsive';
import { Prompt } from 'react-router';

import { BrandLoader } from '@visualist/design-system/src/components/v2';
import { useLoadingDelay } from '@visualist/design-system/src/components/v2/brand-loader/useLoadingDelay';

import {
  MAIN_OBJECTS_LAYER,
  MAX_ZOOM,
  MIN_ZOOM,
  PAGE_GROUP,
  PAGE_GROUP_RECT,
  SHAPE,
} from '@pages/StudioPage/constants';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { useDragSelection } from '@pages/StudioPage/hooks/useDragSelection';
import { useDrop } from '@pages/StudioPage/hooks/useDrop';
import { useImages } from '@pages/StudioPage/hooks/useImages';
import { useTouch } from '@pages/StudioPage/hooks/useTouch';
import {
  $currentTool,
  $isDraggingImageFromLibrary,
  $isEditingText,
  $selectedObjectIds,
  $showLibrary,
  $stageState,
  changedEditingState,
  closedLibrary,
  selectObjectIdInGroup,
  selectObjectIds,
  updatedStage,
} from '@pages/StudioPage/model';
import {
  calculateTextAndImageBounds,
  centerStage,
} from '@pages/StudioPage/utils';
import { onWheelChange } from '@pages/StudioPage/utils/gestures';
import { useAppData } from '@src/AppContext';
import { $hoveredStickyId, editedSticky } from '@src/entities/Stickies/model';
import {
  checkIfAttached,
  chooseColour,
  computeStickyPositionToRatio,
} from '@src/entities/Stickies/utils';
import { IMAGE_PREVIEW_MUTATION } from '@src/shared/constants/query-names';
import useStickies from '@src/shared/queries/useStickies';
import { useIsMutating } from '@tanstack/react-query';

import { useStudioShortcut } from '../../hooks/useStudioShortcut';
import { DragLayer } from '../layers/drag-layer';
import { StickiesLayer } from '../layers/sticky-layer';
import { $draggableImages } from '../Library/model';
import { VaiTidyPopupModal } from '../TidyupPopup';
import { Renderer } from './renderer';

import styles from './styles.module.css';

type Props = {
  designId: string;
  stageRef: React.MutableRefObject<TStage | null>;
};

export const Canvas = React.forwardRef<TStage | null, Props>(
  ({ designId, stageRef }, ref) => {
    const stage = useUnit($stageState);
    const currentTool = useUnit($currentTool);
    const isEditingText = useUnit($isEditingText);
    const isDraggingImageFromLibrary = useUnit($isDraggingImageFromLibrary);
    const hoveredStickyId = useUnit($hoveredStickyId);
    const draggedImages = useUnit($draggableImages);
    const showLibrary = useUnit($showLibrary);
    const selectedObjectIds = useUnit($selectedObjectIds);

    const [dimensions, setDimensions] = useState<
      { height: number; width: number } | undefined
    >(undefined);
    const [hasCentered, setHasCentered] = useState(false);
    const [showHandCursor, setShowHandCursor] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const pageContainerRectRef = useRef<Konva.Rect>(null);

    const { user } = useAppData();

    const {
      designData,
      currentPageMetadata,
      currentPageIndex: currentPage,
      images,
      textboxes,
      isLoading,
      redo,
      undo,
      copyObjects,
      cutObjects,
      pasteObjects,
    } = useStudioDesign(designId);

    const { imageUploadMutation } = useImages({
      designId,
    });
    const { stickiesQuery, addNewStickyOptimistic } = useStickies(
      designId,
      null,
    );

    const { droppedImageFile, droppedImageFileFromLibrary } = useDrop({
      designId,
      stageRef,
    });

    const isToolbarInOneRow = useMediaQuery({
      query: '(min-width: 1500px)',
    });

    const isLoadingDelay = useLoadingDelay({
      enableLoadingDelay: isLoading,
      duration: 2000,
    });

    const { minY, maxY, minX, maxX } = useMemo(() => {
      return calculateTextAndImageBounds({
        images,
        textboxes: textboxes,
      });
    }, [images, textboxes]);

    const isUploadingPreview = useIsMutating({
      mutationKey: [IMAGE_PREVIEW_MUTATION],
    });

    useEffect(() => {
      const confirmUnload = (event: BeforeUnloadEvent) => {
        if (imageUploadMutation.isPending || isUploadingPreview) {
          event.preventDefault();
          return "We're saving your design. If you go to another page, you may lose some changes.";
        }
      };

      window.addEventListener('beforeunload', confirmUnload);

      // Make sure to clean up the event listener on component unmount
      return () => {
        window.removeEventListener('beforeunload', confirmUnload);
      };
    }, [imageUploadMutation.isPending, isUploadingPreview]);

    let pageData = null;
    if (designData.state?.type === 'pages') {
      pageData = currentPageMetadata();
    }

    useEffect(() => {
      if (
        dimensions &&
        currentPage !== null &&
        designData.state?.type === 'pages'
      ) {
        const pageWidth = pageData?.width || 0;
        const pageHeight = pageData?.height || 0;

        const topPadding = isToolbarInOneRow ? 50 : 90;
        const bottomPadding = 123;

        // Calculate available viewport dimensions after padding
        const availableHeight =
          dimensions.height - (topPadding + bottomPadding);
        const availableWidth = dimensions.width;

        // Calculate a scale factor that ensures the page fits within the padded viewport
        const scaleX = availableWidth / pageWidth;
        const scaleY = availableHeight / pageHeight;

        // Use the smaller scale to ensure the entire page fits
        const fitScale = Math.min(scaleX, scaleY);

        // Clamp the scale between zoom min/max values
        const MAX_SCALE = isMobile ? 0.05 : MAX_ZOOM;
        const MIN_SCALE = MIN_ZOOM;
        const defaultScale = Math.max(MAX_SCALE, Math.min(fitScale, MIN_SCALE));

        const stageX = availableWidth / 2 - (pageWidth / 2) * defaultScale;
        const stageY =
          topPadding + (availableHeight / 2 - (pageHeight / 2) * defaultScale);

        updatedStage({
          scale: defaultScale,
          x: stageX,
          y: stageY,
        });
        setHasCentered(true);
      }
    }, [
      currentPage,
      dimensions,
      isMobile,
      pageData?.width,
      pageData?.height,
      designData.state?.type,
      isToolbarInOneRow,
    ]);

    useEffect(() => {
      if (
        images &&
        textboxes &&
        !hasCentered &&
        dimensions &&
        designData.state?.type === 'infinite'
      ) {
        // Center the stage
        centerStage({
          stageRef,
          images,
          textboxes,
          setHasCentered,
        });
      } else if (!hasCentered && designData.state?.type === 'pages') {
        setHasCentered(true);
      }
    }, [dimensions, images.length, textboxes.length, designData]);

    useEffect(() => {
      // Synchronise the current tool with the cursor
      if (currentTool === 'move') {
        setShowHandCursor(true);
      } else {
        setShowHandCursor(false);
      }
    }, [currentTool]);

    useLayoutEffect(() => {
      const updateDimensions = () => {
        if (containerRef.current) {
          setDimensions({
            height: containerRef.current.clientHeight,
            width: containerRef.current.clientWidth,
          });
        }
      };

      // TEMP HACK quick fix to get the screen measured again so studio is properly displayed
      const timeout = setTimeout(() => {
        updateDimensions();
      }, 1);

      // Update dimensions initially
      updateDimensions();

      // Update dimensions when window resizes
      window.addEventListener('resize', updateDimensions);

      // Add resize observer for more accurate dimension updates
      const resizeObserver = new ResizeObserver(() => {
        updateDimensions();
      });

      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      // Clean up event listener on unmount
      return () => {
        window.removeEventListener('resize', updateDimensions);
        resizeObserver.disconnect();
        if (timeout) clearTimeout(timeout);
      };
    }, [setDimensions]);

    const onDraggingStageEnded = (e: KonvaEventObject<DragEvent>) => {
      updatedStage({
        scale: stage.scale,
        x: e.currentTarget.position().x,
        y: e.currentTarget.position().y,
      });
    };

    const currentStickyMutationsRunning = useIsMutating({
      mutationKey: ['ADD_STICKY_MUTATION', { imageId: designId }],
    });

    const addPlaceholderSticky = (
      e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>,
    ) => {
      // If there are mutations running do nothing
      if (currentStickyMutationsRunning) return;

      const stage = e.target.getStage();
      if (!stage) return;

      const pointer = stage.getRelativePointerPosition();

      if (!pointer) return;

      // If not clicking on the container do nothing
      // Allow stickies on the staage, images, or text
      if (
        !(
          e.target.name() === 'Image' ||
          e.target.name() === 'Text' ||
          e.target.name() === SHAPE ||
          e.target.name() === 'Stage'
        )
      )
        return;

      // Turn off any sticky being edited
      editedSticky(null);

      const { ratioX, ratioY } = computeStickyPositionToRatio({
        x: pointer.x,
        y: pointer.y,
        minX,
        minY,
        maxX,
        maxY,
      });

      const attachment = checkIfAttached({
        stageRef,
        point: pointer,
      });

      addNewStickyOptimistic({
        id: 'new-sticky',
        created_by: user,
        is_private: true,
        left_pixel: ratioX,
        top_pixel: ratioY,
        text: '',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        background_hex: chooseColour(
          stickiesQuery.data ? stickiesQuery.data.stickies.length : 0,
        ),
        attached_to_id: attachment?.id,
        attached_to_type: attachment?.type,
        action_sticky: null,
      });
    };

    const clickedOnCanvas = (e: KonvaEventObject<MouseEvent>) => {
      // TODO use better way to dismiss selected
      if (currentTool === 'sticky') addPlaceholderSticky(e);

      if (
        e.currentTarget.name() === 'Stage' &&
        e.target.name() !== 'Image' &&
        e.target.name() !== 'Text' &&
        e.target.name() !== SHAPE
      ) {
        if (isEditingText) {
          // If text was being edited, when click off occurs, changed editing mode to false but select the text
          changedEditingState(false);
          return;
        }
        selectObjectIds(new Set());
        selectObjectIdInGroup(null);
      }
    };

    // Drag Selection handling
    const { startDragSelection, updateDragSelection, endDragSelection } =
      useDragSelection(stageRef, designId);

    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
      updateDragSelection(e);
    };

    const { handleTouchMove, handleTouchEnd, handleTouchStart } = useTouch({
      updateStage: updatedStage,
    });

    // Undo and redo
    // Mac undo
    useStudioShortcut('Command+z', undo);
    // Mac redo
    useStudioShortcut('Command+y', redo);
    useStudioShortcut('Command+Shift+z', redo);

    // Windows undo
    useStudioShortcut('Ctrl+z', undo);

    // Windows redo
    useStudioShortcut('Ctrl+y', redo);
    useStudioShortcut('Ctrl+Shift+y', redo);

    // Copy - Mac
    useStudioShortcut('Command+c', () => {
      const ids = Array.from(selectedObjectIds);
      if (ids.length > 0) {
        copyObjects(ids);
      }
    });

    // Copy - Windows
    useStudioShortcut('Control+c', () => {
      const ids = Array.from(selectedObjectIds);
      if (ids.length > 0) {
        copyObjects(ids);
      }
    });

    // Cut - Mac
    useStudioShortcut('Command+x', () => {
      const ids = Array.from(selectedObjectIds);
      if (ids.length > 0) {
        cutObjects(ids);
      }
    });

    // Cut - Windows
    useStudioShortcut('Control+x', () => {
      const ids = Array.from(selectedObjectIds);
      if (ids.length > 0) {
        cutObjects(ids);
      }
    });

    // Paste - Mac
    useStudioShortcut('Command+v', () => {
      pasteObjects();
    });

    // Paste - Windows
    useStudioShortcut('Control+v', () => {
      pasteObjects();
    });

    return (
      <div
        id="studioStageContainer"
        ref={containerRef}
        className={styles.container}
        onDrop={(e) => {
          if (isDraggingImageFromLibrary && draggedImages) {
            droppedImageFileFromLibrary({ e, images: draggedImages });
          } else {
            droppedImageFile(e);
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onClick={() => {
          if (showLibrary) {
            closedLibrary();
          }
        }}
      >
        <VaiTidyPopupModal />
        <Prompt
          when={imageUploadMutation.isPending || !!isUploadingPreview}
          message={() =>
            "We're saving your design. If you go to another page, you may lose some changes."
          }
        />
        {typeof dimensions !== 'undefined' &&
        designData.state?.type === 'infinite' ? (
          <Stage
            ref={ref}
            x={stage.x}
            y={stage.y}
            scaleX={stage.scale}
            scaleY={stage.scale}
            height={dimensions.height}
            width={dimensions.width}
            // Stage built in draggable is disabled on mobile as the onTouch handles dragging and pinching
            draggable={!isMobile && currentTool === 'move'}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onTouchStart={handleTouchStart}
            onMouseUp={endDragSelection}
            onMouseDown={startDragSelection}
            onMouseMove={onMouseMove}
            onMouseLeave={endDragSelection}
            onDragEnd={onDraggingStageEnded}
            onWheel={onWheelChange}
            onClick={clickedOnCanvas}
            onTap={clickedOnCanvas}
            name="Stage"
            className={cn(styles.stage, {
              [styles.handToolCursor]:
                showHandCursor || (currentTool === 'sticky' && hoveredStickyId),
              [styles.addTextCursor]:
                !showHandCursor && currentTool === 'add-text',
              [styles.addShapeCursor]:
                !showHandCursor && currentTool === 'add-shape',
              [styles.stickyCursor]:
                currentTool === 'sticky' &&
                !hoveredStickyId &&
                !currentStickyMutationsRunning,
              [styles.loadingCursor]:
                currentTool === 'sticky' && currentStickyMutationsRunning,
            })}
          >
            <Layer name={MAIN_OBJECTS_LAYER}>
              <Renderer designId={designId} stageRef={stageRef} />
            </Layer>
            <StickiesLayer
              designId={designId}
              hasCentered={hasCentered}
              stageRef={stageRef}
              dimensions={dimensions}
            />
            <DragLayer
              stageRef={stageRef}
              designId={designId}
              hasCentered={hasCentered}
            />
          </Stage>
        ) : (
          typeof dimensions !== 'undefined' &&
          designData.state?.type === 'pages' && (
            <Stage
              ref={ref}
              x={stage.x}
              y={stage.y}
              scaleX={stage.scale}
              scaleY={stage.scale}
              height={dimensions.height}
              width={dimensions.width}
              // Stage built in draggable is disabled on mobile as the onTouch handles dragging and pinching
              draggable={!isMobile && currentTool === 'move'}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              onTouchStart={handleTouchStart}
              onMouseUp={endDragSelection}
              onMouseDown={startDragSelection}
              onMouseMove={onMouseMove}
              onMouseLeave={endDragSelection}
              onDragEnd={onDraggingStageEnded}
              onWheel={onWheelChange}
              onClick={clickedOnCanvas}
              onTap={clickedOnCanvas}
              name="Stage"
              className={cn(styles.pagedStage, {
                [styles.handToolCursor]:
                  showHandCursor ||
                  (currentTool === 'sticky' && hoveredStickyId),
                [styles.addTextCursor]:
                  !showHandCursor && currentTool === 'add-text',
                [styles.addShapeCursor]:
                  !showHandCursor && currentTool === 'add-shape',
                [styles.stickyCursor]:
                  currentTool === 'sticky' &&
                  !hoveredStickyId &&
                  !currentStickyMutationsRunning,
                [styles.loadingCursor]:
                  currentTool === 'sticky' && currentStickyMutationsRunning,
              })}
            >
              <Layer name={MAIN_OBJECTS_LAYER}>
                <Group
                  name={PAGE_GROUP}
                  id={PAGE_GROUP}
                  x={0}
                  y={0}
                  height={currentPageMetadata()?.height}
                  width={currentPageMetadata()?.width}
                  draggable={false}
                >
                  <Rect
                    id={PAGE_GROUP_RECT}
                    ref={pageContainerRectRef}
                    name={PAGE_GROUP_RECT}
                    x={0}
                    y={0}
                    height={currentPageMetadata()?.height}
                    width={currentPageMetadata()?.width}
                    stroke="#85736C"
                    fill={currentPageMetadata()?.backgroundColor}
                    listening={false}
                    draggable={false}
                  />
                  <Renderer designId={designId} stageRef={stageRef} />
                </Group>
              </Layer>
              <StickiesLayer
                designId={designId}
                hasCentered={hasCentered}
                stageRef={stageRef}
                dimensions={dimensions}
              />
              <DragLayer
                stageRef={stageRef}
                designId={designId}
                hasCentered={hasCentered}
              />
            </Stage>
          )
        )}
        {!hasCentered || isLoadingDelay ? (
          <div className={styles.loaderContainer}>
            <BrandLoader
              animatedIcon="paint-brush"
              subText="Crafting your canvas, just a sec!"
            />
          </div>
        ) : null}
      </div>
    );
  },
);
