import { useHistory, useParams } from 'react-router';

import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';

/*
 This is the only place where the conversion form UI Page to Index (Page 1 -> Index 0) should happen.
 Anywhere else use exclusively Index and Assume the usage of Index
*/
export const usePageNavigation = () => {
  const { design_id } = useParams<{ design_id: string; pageNumber?: string }>();
  const { changePage, currentPageIndex, designData, changeToEnd } =
    useStudioDesign(design_id);
  const history = useHistory();

  const currentPageIndexSafe = currentPageIndex ?? 0;

  const navigateToPage = (pageIndex: number) => {
    if (designData.state?.type !== 'pages') return;

    const page = changePage(pageIndex);
    history.replace(
      `/studio/${design_id}/${page === undefined ? 1 : page + 1}`,
    );
  };

  const navigateToEnd = () => {
    const lastPageIndex = changeToEnd();
    if (lastPageIndex === null) {
      console.error('Last page index not found.');
      return;
    }

    // Convert to 1-based index for URL
    history.replace(
      `/studio/${design_id}/${
        lastPageIndex !== undefined ? lastPageIndex + 1 : 1
      }`,
    );
  };

  const selectedId = () => {
    if (designData.state?.type !== 'pages') return '';
    return designData.state.data[currentPageIndexSafe]?.metadata.id || '';
  };

  return {
    navigateToPage,
    currentPageIndex: currentPageIndexSafe, // Always zero-based
    navigateToEnd,
    selectedId,
  };
};
