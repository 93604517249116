import { useEffect } from 'react';

import { useUnit } from 'effector-react';
import { useLocation } from 'react-router-dom';

import { $id } from '@src/entities/share-sheet/board/model/inviting';
import { useInvitees } from '@src/entities/share-sheet/board/model/queries/use-invitees';

import { LayoutGrid, LibraryActions } from '../../components';
import { FileCard } from '..';
import { LibraryPageTitle } from './LibraryPageTitle';
import { useAllBlocks } from './model/queries/useAllBlocks';

export const LibraryPage = () => {
  const location = useLocation();

  const id = useUnit($id);

  const { role, inviteesQuery } = useInvitees({ id });

  useEffect(() => {
    imageBlockQuery.query.refetch();
    designBlockQuery.query.refetch();
    paletteBlockQuery.query.refetch();
    // eslint-disable-next-line
  }, [location.search]);

  // Three loaders, one for image blocks, studio, and palettes to make each one independant
  const imageBlockQuery = useAllBlocks({
    ordering: '-created_at',
    typeOfBlock: 'image',
  });
  const designBlockQuery = useAllBlocks({
    ordering: '-created_at',
    typeOfBlock: 'set',
  });
  const paletteBlockQuery = useAllBlocks({
    ordering: '-created_at',
    typeOfBlock: 'color_palette',
  });

  // useEffect(() => {
  //   // Show Vai AI modal when more than 40 blocks
  //   if (imageBlockQuery.blocks.length >= 40) {
  //     openedVaiTagPopup();
  //   }
  // }, [imageBlockQuery.blocks.length]);

  const hasLoadedBlocks =
    imageBlockQuery.query.isFetched &&
    designBlockQuery.query.isFetched &&
    paletteBlockQuery.query.isFetched;

  let blocks = {};

  const imageList = imageBlockQuery.blocks.reduce(
    (files, block, index, arr) => {
      if (!files.has(block.block_type)) {
        files.set(block.block_type, {
          indexList: { [block.id]: index },
          idList: { [index]: block.id },
          lastFileIndex: arr.length - 1,
        });
      } else {
        const existingFile = files.get(block.block_type);
        files.set(block.block_type, {
          ...existingFile,
          indexList: { ...existingFile.indexList, [block.id]: index },
          idList: { ...existingFile.idList, [index]: block.id },
        });
      }

      blocks = {
        ...blocks,
        [block.id]: block,
      };

      return files;
    },
    new Map(),
  );

  const designList = designBlockQuery.blocks.reduce(
    (files, block, index, arr) => {
      if (!files.has(block.block_type)) {
        files.set(block.block_type, {
          indexList: { [block.id]: index },
          idList: { [index]: block.id },
          lastFileIndex: arr.length - 1,
        });
      } else {
        const existingFile = files.get(block.block_type);
        files.set(block.block_type, {
          ...existingFile,
          indexList: { ...existingFile.indexList, [block.id]: index },
          idList: { ...existingFile.idList, [index]: block.id },
        });
      }

      blocks = {
        ...blocks,
        [block.id]: block,
      };

      return files;
    },
    new Map(),
  );

  const paletteList = paletteBlockQuery.blocks.reduce(
    (files, block, index, arr) => {
      if (!files.has(block.block_type)) {
        files.set(block.block_type, {
          indexList: { [block.id]: index },
          idList: { [index]: block.id },
          lastFileIndex: arr.length - 1,
        });
      } else {
        const existingFile = files.get(block.block_type);
        files.set(block.block_type, {
          ...existingFile,
          indexList: { ...existingFile.indexList, [block.id]: index },
          idList: { ...existingFile.idList, [index]: block.id },
        });
      }

      blocks = {
        ...blocks,
        [block.id]: block,
      };

      return files;
    },
    new Map(),
  );

  // TODO: move combinedFileList loading into the file card component.

  const combinedFileList = new Map();

  imageList.forEach((value, key) => {
    combinedFileList.set(key, value);
  });

  designList.forEach((value, key) => {
    combinedFileList.set(key, value);
  });

  paletteList.forEach((value, key) => {
    combinedFileList.set(key, value);
  });

  combinedFileList.set('blocks', blocks);

  const refetchQueries = () => {
    inviteesQuery.refetch();
  };

  return (
    <>
      <LibraryPageTitle />
      <FileCard fileList={combinedFileList} />
      <div className="library-page">
        <LayoutGrid
          allDataLoaded={hasLoadedBlocks}
          imageBlocks={imageBlockQuery.blocks}
          imageBlocksLoading={imageBlockQuery.query.isLoading}
          imageBlockHasNext={imageBlockQuery.query.hasNextPage}
          imageBlockFetchNext={imageBlockQuery.query.fetchNextPage}
          designBlocks={designBlockQuery.blocks}
          designBlocksLoading={designBlockQuery.query.isLoading}
          designBlocksHasNext={designBlockQuery.query.hasNextPage}
          designBlocksFetchNext={designBlockQuery.query.fetchNextPage}
          paletteBlocks={paletteBlockQuery.blocks}
          paletteBlocksLoading={paletteBlockQuery.query.isLoading}
          paletteBlocksHasNext={paletteBlockQuery.query.hasNextPage}
          paletteBlocksFetchNext={paletteBlockQuery.query.fetchNextPage}
          refetchQueries={refetchQueries}
          role={role}
          page="library"
        />

        <LibraryActions />
      </div>
    </>
  );
};
