import { PropsWithChildren } from 'react';

import { BrandLoader } from '@visualist/design-system/src/components/v2';

import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';

import styles from './styles.module.css';

export const FileLoader = ({
  children,
  designId,
}: PropsWithChildren<{
  designId: string;
}>) => {
  const { isLoading, error } = useStudioDesign(designId);

  const loader = (
    <div className={styles.loaderContainer}>
      <BrandLoader
        animatedIcon="paint-brush"
        subText="Crafting your canvas, just a sec!"
      />
    </div>
  );

  // If any loading of blob file show loader
  if (isLoading) return loader;

  if (error) {
    return <p>{error.message}</p>;
  }

  return <>{children}</>;
};
