import { useCallback, useEffect, useMemo, useState } from 'react';

import { Stage } from 'konva/lib/Stage';
import { useParams } from 'react-router';

import {
  DesignJSONV2 as DesignState,
  ImageJSON,
  isImageJSON,
  isShapeJSON,
  isTextJSON,
  ShapeJSON,
  TextJSON,
} from '@api/designs';
import {
  Orientation,
  PageSize,
} from '@pages/StudioPage/components/page-setup/page-size';
import { DesignMetadata } from '@pages/StudioPage/lib/design';
import { DesignManager } from '@pages/StudioPage/lib/design-manager';

/**
 * Hook to connect with the design file class manager. Allows for changes in class state to be reflected in react
 *
 * @param designId
 * @returns
 */

export function useStudioDesign(designId: string) {
  const design = DesignManager.getInstance(designId);

  const { pageNumber } = useParams<{
    pageNumber?: string;
  }>();

  const [designData, setDesignData] = useState<{
    state: DesignState | null;
    metadata: DesignMetadata;
  }>(() => ({
    state: design.getState(),
    metadata: design.getMetadata(),
  }));

  useEffect(() => {
    const unsubscribe = design.subscribe((newState, newMetadata) => {
      setDesignData({
        state: structuredClone(newState),
        metadata: { ...newMetadata },
      });
    });

    const pn = typeof pageNumber === 'undefined' ? 0 : Number(pageNumber);

    if (!designData.state) {
      design.loadDesign(pn);
    } else {
      const newState = design.getState();
      const newMetadata = design.getMetadata();

      if (!newState) return;

      setDesignData({
        state: newState,
        metadata: newMetadata,
      });
    }

    return () => {
      unsubscribe();
    };
  }, [design, designId]);

  const {
    pageNumberIndex: currentPageIndex,
    isLoading,
    isError,
  } = designData.metadata;

  return {
    designData,
    // TODO Fix THIS JANK
    objects:
      designData.state === null
        ? []
        : designData.state.type === 'infinite'
        ? designData.state.data.objects
        : currentPageIndex !== null &&
          currentPageIndex < designData.state.data.length
        ? designData.state.data[currentPageIndex].objects
        : [],
    allPageObjects:
      designData.state === null
        ? []
        : designData.state.type === 'infinite'
        ? designData.state.data.objects
        : designData.state.data.flatMap((page) => page.objects),
    images:
      designData.state === null
        ? []
        : designData.state.type === 'infinite'
        ? (designData.state.data.objects.filter((obj) =>
            isImageJSON(obj),
          ) as ImageJSON[])
        : currentPageIndex !== null &&
          currentPageIndex < designData.state.data.length
        ? (designData.state.data[currentPageIndex].objects.filter((obj) =>
            isImageJSON(obj),
          ) as ImageJSON[])
        : ([] as ImageJSON[]),
    textboxes:
      designData.state === null
        ? []
        : designData.state.type === 'infinite'
        ? (designData.state.data.objects.filter((obj) =>
            isTextJSON(obj),
          ) as TextJSON[])
        : currentPageIndex !== null &&
          currentPageIndex < designData.state.data.length
        ? (designData.state.data[currentPageIndex].objects.filter((obj) =>
            isTextJSON(obj),
          ) as TextJSON[])
        : ([] as TextJSON[]),
    shapes:
      designData.state === null
        ? []
        : designData.state.type === 'infinite'
        ? (designData.state.data.objects.filter((obj) =>
            isShapeJSON(obj),
          ) as ShapeJSON[])
        : currentPageIndex !== null &&
          currentPageIndex < designData.state.data.length
        ? (designData.state.data[currentPageIndex].objects.filter((obj) =>
            isShapeJSON(obj),
          ) as ShapeJSON[])
        : ([] as ShapeJSON[]),
    isLoading,
    error: isError,
    isInPagesMode: useMemo(
      () => designData.state?.type === 'pages',
      [designData],
    ),
    currentPageIndex: currentPageIndex,
    changePage: useCallback(
      (page: number | null) => {
        return design.changePage(page ?? 0);
      },
      [design],
    ),
    addImage: useCallback(
      (...params: Parameters<typeof design.addImage>) =>
        design.addImage(...params),
      [design],
    ),
    addText: useCallback(
      (...params: Parameters<typeof design.addText>) =>
        design.addText(...params),
      [design],
    ),
    addShape: useCallback(
      (...params: Parameters<typeof design.addShape>) =>
        design.addShape(...params),
      [design],
    ),
    updateImage: useCallback(
      (...params: Parameters<typeof design.updateImage>) =>
        design.updateImage(...params),
      [design],
    ),
    updateText: useCallback(
      (...params: Parameters<typeof design.updateText>) =>
        design.updateText(...params),
      [design],
    ),
    updateShape: useCallback(
      (...params: Parameters<typeof design.updateShape>) =>
        design.updateShape(...params),
      [design],
    ),
    updateLayer: useCallback(
      (...params: Parameters<typeof design.updateLayer>) =>
        design.updateLayer(...params),
      [design],
    ),
    updateLayers: useCallback(
      (...params: Parameters<typeof design.updateLayers>) =>
        design.updateLayers(...params),
      [design],
    ),
    applyPassedLayers: useCallback(
      (...params: Parameters<typeof design.applyPassedLayers>) =>
        design.applyPassedLayers(...params),
      [design],
    ),
    deleteObject: useCallback(
      (id: string) => design.deleteObject(id),
      [design],
    ),
    rotateSingleObject: useCallback(
      (id: string, amount: number) => design.rotateSingleObject(id, amount),
      [design],
    ),
    rotateMultipleObjects: useCallback(
      (
        rotatedNodes: Array<{
          id: string;
          x: number;
          y: number;
          rotation: number;
        }>,
      ) => design.rotateMultipleObjects(rotatedNodes),
      [design],
    ),
    switchStateType: useCallback(
      (stage: Stage | null) => design.switchStateType({ stage }),
      [design],
    ),
    copyObjects: useCallback(
      (objectIds: string[]) => design.copyStudioObject(objectIds),
      [design],
    ),
    cutObjects: useCallback(
      (objectIds: string[]) => design.cutStudioObject(objectIds),
      [design],
    ),
    pasteObjects: useCallback(() => design.pasteStudioObjects(), [design]),
    deletePage: useCallback(
      (pageId: string) => {
        return design.deletePage(pageId);
      },
      [design],
    ),
    addPage: useCallback(
      (index?: number, id?: string) => design.addPage(index, id),
      [design],
    ),
    swapPage: useCallback(
      (
        currentIndex: number,
        swapTo: number,
        currentSelectedFrameId: string,
      ) => {
        return design.swapPage(currentIndex, swapTo, currentSelectedFrameId);
      },
      [design],
    ),
    updateBackgroundColor: useCallback(
      (backgroundColor: string, updateAllPages: boolean, pageId: string) =>
        design.updateBackgroundColor(backgroundColor, updateAllPages, pageId),
      [design],
    ),
    updatePagedSize: useCallback(
      (
        width: number,
        height: number,
        orientation: Orientation,
        size: PageSize,
        updateAllPages: boolean,
        pageId: string,
      ) =>
        design.updatePageSize(
          width,
          height,
          orientation,
          size,
          updateAllPages,
          pageId,
        ),
      [design],
    ),
    saveThumbnail: useCallback(
      (index: number, data: string) => design.saveThumbnail(index, data),
      [design],
    ),
    markPageForCopy: useCallback(
      (pageId: string) => design.markPageForCopy(pageId),
      [design],
    ),
    pastePage: useCallback(
      (index?: number) => design.pastePage(index),
      [design],
    ),
    duplicatePage: useCallback(
      (pageId: string) => {
        return design.duplicatePage(pageId);
      },
      [design],
    ),
    changeToEnd: useCallback(() => {
      return design.changeToEnd();
    }, [design]),
    currentPageMetadata: design.getCurrentPageMetadata,
    undo: useCallback(design.undo, [design]),
    redo: useCallback(design.redo, [design]),
    addToGroup: useCallback(
      (objectIds: Set<string>) => design.addToGroup(objectIds),
      [design],
    ),
    removeFromGroup: useCallback(
      (objectIds: Set<string>) => design.removeFromGroup(objectIds),
      [design],
    ),
    getGroupObjectsByObjectId: design.getGroupObjectsByObjectId,
    isAnyGrouped: design.isAnyGrouped,
    areAllInSameGroup: design.areAllInSameGroup,
  };
}
