import { ChangeEvent, ComponentProps, useReducer, useState } from 'react';

import { useUnit } from 'effector-react';

import {
  Button,
  Divider,
  Dropdown,
  FilterChip,
  Modal,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Page } from '@api/designs';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { $stageState, updatedStage } from '@pages/StudioPage/model';

import { $showPageSizeSetupModal, closedPageSizeSetupModal } from '../model';

import styles from './styles.module.css';

const reducer = (state: PageSetupState, action: Action): PageSetupState => {
  switch (action.type) {
    case 'update-page-size': {
      let width = state.width;
      let height = state.height;

      if (action.payload.pageSize === 'letter') {
        width = 2550;
        height = 3300;
      } else if (action.payload.pageSize === 'a4') {
        width = 2490;
        height = 3510;
      }

      const currentOrientation = width > height ? 'landscape' : 'portrait';

      return {
        ...state,
        orientation: currentOrientation,
        width,
        height,
        size: action.payload.pageSize,
      };
    }
    case 'update-orientation': {
      // If dimensions are equal, just update orientation without swapping
      if (state.width === state.height) {
        return {
          ...state,
          orientation: action.payload.orientation,
        };
      }

      // If requested orientation matches current dimensions, no change needed
      const currentOrientation =
        state.width > state.height ? 'landscape' : 'portrait';
      if (currentOrientation === action.payload.orientation) {
        return state;
      }

      // Swap width and height to match requested orientation
      return {
        ...state,
        width: state.height,
        height: state.width,
        orientation: action.payload.orientation,
      };
    }
    case 'update-width': {
      const width = action.payload.width;
      const currentOrientation =
        width > state.height ? 'landscape' : 'portrait';

      let size: PageSize = state.size;
      const predefinedWidth: Record<
        'letter' | 'a4',
        { landscape: number; portrait: number }
      > = {
        letter: { landscape: 3300, portrait: 2550 },
        a4: { landscape: 3510, portrait: 2490 },
      };
      if (
        state.size in predefinedWidth &&
        width !==
          predefinedWidth[state.size as 'letter' | 'a4'][currentOrientation]
      ) {
        size = 'custom';
      }

      return {
        ...state,
        width: action.payload.width,
        orientation:
          width === state.height ? state.orientation : currentOrientation,
        size,
      };
    }
    case 'update-height': {
      const height = action.payload.height;
      const currentOrientation =
        state.width > height ? 'landscape' : 'portrait';

      let size: PageSize = state.size;
      const predefinedHeight: Record<
        'letter' | 'a4',
        { landscape: number; portrait: number }
      > = {
        letter: { landscape: 2550, portrait: 3300 },
        a4: { landscape: 24903510, portrait: 3510 },
      };
      if (
        state.size in predefinedHeight &&
        height !==
          predefinedHeight[state.size as 'letter' | 'a4'][currentOrientation]
      ) {
        size = 'custom';
      }

      return {
        ...state,
        height: height,
        orientation:
          state.width === height ? state.orientation : currentOrientation,
        size,
      };
    }
    default:
      return state;
  }
};

export type Orientation = 'portrait' | 'landscape';

export type PageSize = 'a4' | 'letter' | 'custom';

type PageSetupState = {
  width: number;
  height: number;
  orientation: Orientation;
  size: PageSize;
};

type Action =
  | {
      type: 'reset';
      payload: PageSetupState;
    }
  | {
      type: 'update-page-size';
      payload: {
        pageSize: PageSize;
      };
    }
  | {
      type: 'update-orientation';
      payload: {
        orientation: Orientation;
      };
    }
  | {
      type: 'update-width';
      payload: {
        width: number;
      };
    }
  | {
      type: 'update-height';
      payload: {
        height: number;
      };
    };

export const PageSizeSetupWrapper = ({
  designId,
  pageId,
}: {
  designId: string;
  pageId: string;
}) => {
  const [showPageSizeSetupModal] = useUnit([$showPageSizeSetupModal]);
  const { designData } = useStudioDesign(designId);

  if (
    !designData ||
    !designData.state?.data ||
    !pageId ||
    designData.state?.type === 'infinite'
  )
    return null;

  const pageData = designData.state?.data.find(
    (page) => page.metadata.id === pageId,
  );

  if (!pageData) return null;

  return (
    <PageSizeSetup
      key={showPageSizeSetupModal.toString()}
      page={pageData}
      showPageSetup={showPageSizeSetupModal}
      designId={designId}
      pageId={pageId}
    />
  );
};

const PageSizeSetup = ({
  page,
  showPageSetup,
  designId,
  pageId,
}: {
  page: Page;
  showPageSetup: boolean;
  designId: string;
  pageId: string;
}) => {
  const [state, dispatch] = useReducer(reducer, {
    height: page.metadata.height,
    width: page.metadata.width,
    orientation: page.metadata.orientation,
    size: page.metadata.size,
  });
  const [updateAllPages, setUpdateAllPages] = useState(false);
  const stage = useUnit($stageState);
  const { updatePagedSize } = useStudioDesign(designId);

  const updatePageSize = (pageSize: PageSize) => {
    dispatch({
      type: 'update-page-size',
      payload: {
        pageSize,
      },
    });
  };

  const changeOrientation = (o: Orientation) => {
    dispatch({
      type: 'update-orientation',
      payload: {
        orientation: o,
      },
    });
  };

  const updateWidth = (newWidth: number) => {
    dispatch({
      type: 'update-width',
      payload: { width: newWidth },
    });
  };

  const updateHeight = (newHeight: number) => {
    dispatch({
      type: 'update-height',
      payload: { height: newHeight },
    });
  };

  const saveChanges = async () => {
    const orientation = state.width > state.height ? 'landscape' : 'portrait';
    updatePagedSize(
      state.width,
      state.height,
      orientation,
      state.size,
      updateAllPages,
      pageId,
    );
    updatedStage({
      scale: 0,
      x: stage.x,
      y: stage.y,
    });
    closedPageSizeSetupModal();
  };

  return (
    <Modal
      handleClose={() => closedPageSizeSetupModal()}
      showModal={showPageSetup}
    >
      <div className={styles.container}>
        <TypographyPoppins type="headline" size="S" className={styles.title}>
          Page size
        </TypographyPoppins>
        <section className={styles.pageSetupContainer}>
          <PageSize
            width={state.width}
            height={state.height}
            size={state.size}
            updatePageSize={updatePageSize}
            updateWidth={updateWidth}
            updateHeight={updateHeight}
          />
          <div>
            <Orientation
              changeOrientation={changeOrientation}
              orientation={state.orientation as Orientation}
            />
          </div>
        </section>
        <section className={styles.applyToContainer}>
          <Divider className={styles.divider} type="short-line" />
          <div style={{ marginLeft: '12px' }}>
            <TypographyPoppins type="body" size="M" className={styles.labels}>
              Apply to
            </TypographyPoppins>
            <div className={styles.applyToRow}>
              <label htmlFor="this-page" className={styles.radioRow}>
                <input
                  checked={!updateAllPages}
                  onChange={() => setUpdateAllPages(false)}
                  type="radio"
                  name="apply-to"
                  id="this-page"
                  value="this-page"
                  className={styles.radio}
                />
                <TypographyPoppins type="body" size="M" className={styles.text}>
                  this page only
                </TypographyPoppins>
              </label>
              <label htmlFor="all-pages" className={styles.radioRow}>
                <input
                  checked={updateAllPages}
                  onChange={() => setUpdateAllPages(true)}
                  type="radio"
                  name="apply-to"
                  id="all-pages"
                  value="all-pages"
                  className={styles.radio}
                />
                <TypographyPoppins type="body" size="M" className={styles.text}>
                  all pages
                </TypographyPoppins>
              </label>
            </div>
          </div>
        </section>
        <section className={styles.footer}>
          <div className={styles.row}>
            <Button
              label="Cancel"
              type="outlined"
              onClick={() => closedPageSizeSetupModal()}
            />
            <Button label="Save" type="filled" onClick={saveChanges} />
          </div>
        </section>
      </div>
    </Modal>
  );
};

const getPageSizeLabel = (size: PageSize) => {
  if (size === 'custom') {
    return 'Custom';
  } else if (size === 'letter') {
    return 'Letter (8.5" x 11")';
  } else if (size === 'a4') {
    return 'A4 (8.3" x 11.7")';
  }

  return 'Custom';
};

const PageSize = ({
  width,
  height,
  size,
  updatePageSize,
  updateWidth,
  updateHeight,
}: {
  width: number;
  height: number;
  size: PageSize;
  updatePageSize: (s: PageSize) => void;
  updateWidth: (width: number) => void;
  updateHeight: (height: number) => void;
}) => {
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    updateFn: (value: number) => void,
    min: number,
    max: number,
  ) => {
    const value = Number(e.target.value);
    const isOutOfBounds = value > max || value < min;

    // Apply error color if out of bounds
    e.target.style.color = isOutOfBounds
      ? 'var(--color-error-40)'
      : 'var(--color-tertiary-10)';
    updateFn(value);

    if (isOutOfBounds) {
      setTimeout(() => {
        const currentValue = Number(e.target.value);
        if (currentValue > max) {
          updateFn(max);
        } else if (currentValue < min) {
          updateFn(min);
        }
        e.target.style.color = 'var(--color-tertiary-10)';
      }, 1000);
    }
  };

  const onChangeWidth = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e, updateWidth, 110, 10000);
  };

  const onChangeHeight = (e: ChangeEvent<HTMLInputElement>) => {
    handleInputChange(e, updateHeight, 110, 10000);
  };

  return (
    <fieldset className={styles.fieldset}>
      <div className={styles.row}>
        <label htmlFor="size">
          <TypographyPoppins type="body" size="M" className={styles.labels}>
            Page size
          </TypographyPoppins>
        </label>
        <Dropdown>
          <Dropdown.Menu
            trigger={
              <FilterChip
                className={styles.inputButton}
                trailingIcon={<Icon name="sprite/caret-down" />}
                type="body"
                size="S"
              >
                {getPageSizeLabel(size)}
              </FilterChip>
            }
            side="bottom"
            align="start"
            sideOffset={4}
            collisionPadding={8}
            density="-2"
          >
            <Dropdown.MenuItem
              item={{
                content: 'Letter (8.5" x 11")',
                onClick: () => updatePageSize('letter'),
                trailingIcon:
                  getPageSizeLabel(size) === 'Letter (8.5" x 11")' ? (
                    <Icon name="sprite/tick" />
                  ) : null,
              }}
            />
            <Dropdown.MenuItem
              item={{
                content: 'A4 (8.3" x 11.7")',
                onClick: () => updatePageSize('a4'),
                trailingIcon:
                  getPageSizeLabel(size) === 'A4 (8.3" x 11.7")' ? (
                    <Icon name="sprite/tick" />
                  ) : null,
              }}
            />
            <Dropdown.MenuItem
              item={{
                content: 'Custom',
                onClick: () => updatePageSize('custom'),
                trailingIcon:
                  getPageSizeLabel(size) === 'Custom' ? (
                    <Icon name="sprite/tick" />
                  ) : null,
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        className={styles.row}
        style={{
          marginTop: '12px',
        }}
      >
        <label htmlFor="width">
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Width (pixels)
          </TypographyPoppins>
        </label>
        <Input
          value={width}
          type="number"
          name="width"
          id="width"
          min="1"
          max="10000"
          onChange={onChangeWidth}
          contentEditable
        />
      </div>
      <div
        className={styles.row}
        style={{
          marginTop: '8px',
        }}
      >
        <label htmlFor="height">
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Height (pixels)
          </TypographyPoppins>
        </label>
        <Input
          value={height}
          type="number"
          name="height"
          id="studioPageHeight"
          min="1"
          max="10000"
          onChange={onChangeHeight}
          contentEditable
        />
      </div>
    </fieldset>
  );
};

const Orientation = (props: {
  orientation: Orientation;
  changeOrientation: (orientation: Orientation) => void;
}) => {
  const { orientation, changeOrientation } = props;

  return (
    <div className={styles.orientationContainer}>
      <TypographyPoppins type="body" size="M" className={styles.labels}>
        Orientation
      </TypographyPoppins>
      <div className={styles.orientationRow}>
        <label htmlFor="portrait" className={styles.radioRow}>
          <input
            checked={orientation === 'portrait'}
            onChange={() => changeOrientation('portrait')}
            type="radio"
            name="orientation"
            id="portrait"
            value="portrait"
            className={styles.radio}
          />
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Portrait
          </TypographyPoppins>
        </label>
        <label htmlFor="landscape" className={styles.radioRow}>
          <input
            checked={orientation === 'landscape'}
            onChange={() => changeOrientation('landscape')}
            type="radio"
            name="orientation"
            id="landscape"
            value="landscape"
            className={styles.radio}
          />
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Landscape
          </TypographyPoppins>
        </label>
      </div>
    </div>
  );
};

const Input = ({
  ...rest
}: Exclude<ComponentProps<'input'>, 'className'> & {}) => {
  return <input {...rest} className={styles.input} />;
};
