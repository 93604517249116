import { AxiosPromise, AxiosResponse } from 'axios';

import {
  AddColorType,
  BlockType,
  ColorType,
  ImageType,
  UpdateColorType,
} from '@visualist/design-system';

import { BoardLvl1, BoardLvl2 } from './designs';
import {
  api,
  ColorCodes,
  ColorSwatch,
  ColorTheme,
  ImageTypes,
  Studio,
} from './services';

export type Theme =
  | 'colourful'
  | 'scientific'
  | 'light'
  | 'dark'
  | 'warm'
  | 'cool'
  | 'neutral'
  | 'monochromatic';

export type ColorPalette = {
  image: string;
  theme: string;
  board?: string;
};

type Tag = {
  name: string;
  label: boolean;
};

type BulkTag = {
  name: string;
  block_list: Array<string>;
};

type BulkTagDelete = {
  tag: string;
  block_list: Array<string>;
};

export type EditFile = {
  id: string;
  name: string;
};

export type File = {
  full_size: string;
  thumbnail_100: string;
  thumbnail_130: string;
  thumbnail_160: string;
  thumbnail_330: string;
  thumbnail_400: string;
  thumbnail_640: string;
  small_square_crop: string;
};

type SetFile = {
  file: string;
};

type Boards = {
  board_id: string;
  board_name: string;
  block_id: string;
  parent: BoardLvl1 | BoardLvl2 | null;
};

type Blocks = {
  id: string;
  boards: Boards[];
  file: File;
  height: number;
  width: number;
};

export interface AllFileCopiesResponse {
  blocks: Blocks[];
  boards: Boards[];
}

export const addColorPalette = (data: ColorPalette) =>
  api.post(`/blocks/color/from_image/`, data);

export const removeBackgroundPreview = (id: string): Promise<ImageType> =>
  api.post(`/blocks/remove_bg/preview/`, { block: id });

export const removeBackgroundSaveAsNew = (
  id: string,
): AxiosPromise<ImageType> =>
  api.post(`/blocks/remove_bg/save_new/`, { block: id });

export const removeBackgroundReplace = (id: string): AxiosPromise<ImageType> =>
  api.post(`/blocks/remove_bg/replace/`, { block: id });

export const addTagToBlock = (id: string, data: Tag) =>
  api.post(`/blocks/${id}/tags/`, data);

//patch block by ids
export const editFile = ({ id, name }: EditFile) =>
  api.patch(`/blocks/${id}/`, { name });

export type AddedBlockToSetResponse = Array<{
  id: string;
  name: null | string;
  file: File;
  studio: Pick<
    Studio,
    | 'position_x'
    | 'position_y'
    | 'position_width'
    | 'position_omega'
    | 'position_height'
    | 'position_lock'
  >;
  width: number;
  height: number;
  created_at: string;
}>;

export const addBlocksToSet = async (
  setId: string,
  files: SetFile[],
): Promise<AxiosResponse<AddedBlockToSetResponse>> =>
  api.post(`/blocks/set/${setId}/add_blocks/`, { files: files });

export const bulkAddTag = (data: BulkTag) =>
  api.post(`/blocks/bulk_create_tags/`, data);

export const bulkDeleteBlock = (data: any) =>
  api.post('/blocks/bulk_delete/', data);

export const permanentDelete = async (id: string): Promise<unknown> => {
  const data = await api.post('/blocks/permanent_delete/', { block_id: id });
  return data;
};

export const bulkUndoDeleteBlock = async ({
  id,
  location,
}: {
  id: string;
  location?: string;
}): Promise<unknown> => {
  const { data } = await api.post('/blocks/bulk_undo_delete/', {
    block_ids: [id],
    location,
  });

  return data;
};

export const removeTagFromBlock = (id: string, tag: string) =>
  api.delete(`/blocks/${id}/tags/${tag}/`);

export const bulkRemoveTagFromBlock = (data: BulkTagDelete) =>
  api.post('/blocks/bulk_delete_tags/', data);

export const downloadSelectedBlocks = async (payload: { blocks: string[] }) => {
  return await api.post(`/blocks/download_zip/`, payload);
};

export const downloadSelectedBlocksSingle = async (id: string) => {
  return await api.get(`/blocks/${id}/download/`, {
    responseType: 'blob',
  });
};

export const addColorToBlock = async (id: string, data: AddColorType) => {
  return await api.post(`/blocks/add_color/`, { ...data, block: id });
};

export const deleteColorFromBlock = (id: string, colorId: string) =>
  api.delete(`/blocks/${id}/colors/${colorId}/`);

export const getColourPaletteFromBlock = async (
  id: string,
): Promise<ColorType[]> => {
  const { data } = await api.get(`/blocks/image/${id}/colors/`);
  return data;
};

export const updateColorBlock = (id: string, data: UpdateColorType[]) =>
  api.put(`/blocks/color/${id}/`, { colors_to_block: data });

export const saveAsNewBlock = (
  data: Pick<UpdateColorType, 'order' | 'hex'>[],
) => api.post(`/blocks/color/new/`, { colors: data });

export const uploadImage = async (data: any): Promise<AxiosResponse<Blocks>> =>
  await api.post(`/blocks/image/`, data, {
    headers: {
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    },
  });

/**
 * an endpoint for creating new color palette
 *
 * @namespace {blocks/color}
 * @description
 * https://dev.visualistapp.com/api/swagger/#operations-blocks-blocks_color_new_create
 */

type ColorPaletteColorItem = {
  hex: string;
  order: number;
};

export type NewColorPalettePayload = {
  colors: ColorPaletteColorItem[];
  project?: string;
  board?: string;
};

export type NewColorPaletteResponse = {
  data: {
    id: string;
    file: {
      thumbnail_640: string;
      thumbnail_330: string;
      thumbnail_400: string;
      small_square_crop: string;
      thumbnail_160: string;
      thumbnail_130: string;
      thumbnail_100: string;
      full_size: string;
    };
    block_type: BlockType;
    tags: {
      id: string;
      name: string;
      tag_source: string;
    }[];
    colors: {
      id: string;
      hex_color: string;
      rgb_color: string;
      main_color: boolean;
      order: number;
    }[];
    block_source: string;
    source_data: {
      page_domain: string;
      page_url: string;
      saved_date?: string;
    };
    boards: Record<string, unknown>[];
    projects: Record<string, unknown>[];
    use_count: number;
    created_at: string;
    width: number;
    height: number;
    project_list: Record<string, unknown>[];
    board_list: Record<string, unknown>[];
    is_removed: boolean;
  };
};
export type UpdateColorPalette = {
  codes: ColorCodes;
  id?: string;
};

export const newColorPalette = (
  data: NewColorPalettePayload,
): Promise<NewColorPaletteResponse> => api.post(`/blocks/color/new/`, data);

export const getAllCopiesFile = async (
  imageName?: string,
): Promise<AllFileCopiesResponse> => {
  const { data } = await api.get('/images/info/', {
    params: {
      image_name: imageName,
    },
  });

  return data;
};

// TODO: Refactor to use better types system

export type ColourSearchBlock = {
  id: string;
  file: ImageTypes;
  copies_number: number;
  min_distance: number;
  closest_color: number;
  colors: {
    hex_color: string;
  }[];
};

type ColourSearchResponse = {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Array<ColourSearchBlock>;
  };
  state: {
    status: string;
    message: string;
  };
};

/**
 * Endpoint for getting blocks for palette
 *
 * Must only be passed either array of rgb colors or array of hex colors. # not neccesary.
 *
 * @namespace {blocks/colour_search}
 * @description
 * https://staging.visualistapp.com/api/swagger/#/blocks/blocks_color_search_list
 */

export const getBlocksForPalette = async ({
  colours,
  tolerance = 25,
  offset = 0,
}: {
  colours: string[];
  tolerance?: number;
  offset?: number;
}): Promise<ColourSearchResponse> => {
  // Convert colours to object with key as hex{idx} and value as color so that params are formatted as ?hex1=#ffffff&hex2=#000000
  const coloursObject = colours.reduce((acc, color, idx) => {
    acc[`hex${idx + 1}`] = color.split('#')[1];
    return acc;
  }, {} as Record<string, string>);

  const { data } = await api.get('/search/multicolor', {
    params: {
      tolerance,
      ...coloursObject,
      offset,
    },
  });
  return data;
};

export const createColorSwatches = async (color: ColorTheme) =>
  await api.post(`/blocks/${color.block}/swatches/`, {
    theme: color.theme,
    swatches: color.swatches,
    theme_create: color.theme_create,
  });

export const editColorSwatches = async ({
  block,
  swatches,
  theme,
}: ColorTheme) =>
  await api.put(`/blocks/${block}/swatches/`, {
    theme,
    swatches,
  });

export const deleteColorSwatch = async ({
  blockId,
  id,
}: {
  blockId: string;
  id: string;
}) => api.delete(`/blocks/${blockId}/swatches/${id}`);

export const deleteColorTheme = async ({
  blockId,
  theme,
}: {
  blockId: string;
  theme: string;
}) =>
  api.post(`/blocks/${blockId}/themes/delete/`, {
    theme,
  });

export const renameColorTheme = async ({
  blockId,
  new_name,
  current_name,
}: {
  blockId: string;
  new_name: string;
  current_name: string;
}) =>
  api.post(`/blocks/${blockId}/themes/rename/`, {
    new_name,
    current_name,
  });

export const createColorPalette = async ({
  swatches,
  board,
  name,
}: {
  swatches: ColorSwatch[];
  board?: string;
  name?: string;
}) => {
  const { data } = await api.post(`/blocks/palette/`, {
    swatches,
    board,
    name,
  });

  return data;
};

export const createColorPaletteFromColorTheme = async ({
  id,
  theme,
  board,
}: {
  id: string;
  theme: string;
  board?: string;
}) => {
  const { data } = await api.post(`/blocks/${id}/palette/`, {
    theme,
    board,
  });

  return data;
};

export const createColorPaletteAsCopy = async ({
  id,
  swatches,
  board,
}: {
  id: string;
  swatches: ColorSwatch[];
  board?: string;
}) => {
  const { data } = await api.post(`/blocks/${id}/palette/`, {
    swatches,
    board,
  });

  return data;
};

export const updateColorPalette = async ({ codes, id }: UpdateColorPalette) =>
  await api.put(`/blocks/palette/${id}/`, {
    display_color_codes: codes,
  });

export const archiveFile = async ({
  ids,
}: {
  ids: string[] | null;
}): Promise<unknown> => {
  const { data } = await api.post(`/blocks/bulk_archive/`, {
    block_ids: ids,
  });

  return data;
};

export const unarchiveFile = async ({
  ids,
}: {
  ids: string[] | null;
}): Promise<unknown> => {
  const { data } = await api.post(`/blocks/bulk_undo_archive/`, {
    block_ids: ids,
  });

  return data;
};
