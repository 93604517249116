import { MutableRefObject, useEffect, useMemo, useState } from 'react';

import { useUnit } from 'effector-react';
import { AnimatePresence, motion } from 'framer-motion';
import { Stage } from 'konva/lib/Stage';
import { useMediaQuery } from 'react-responsive';

import {
  Divider,
  Dropdown,
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { AnyIconName, Icon } from '@visualist/icons';

import { SELECTION_TRANSFORMER } from '@pages/StudioPage/constants';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { useDesign } from '@pages/StudioPage/hooks/useDesign';
import { useStudioShortcut } from '@pages/StudioPage/hooks/useStudioShortcut';
import {
  $currentTool,
  $selectedObjectIdInGroup,
  $selectedObjectIds,
  $showLibrary,
  changedTool,
  openedShuffler,
} from '@pages/StudioPage/model';
import { isTransformerNode } from '@pages/StudioPage/utils';

import { AddToStudio } from './add-to-studio';
import { DesignLayout } from './design-layout';
import { ImageToolbar } from './image-toolbar';
import { nodeAlignment } from './multi-select-toolbar/alignment';
import { AlignmentType } from './multi-select-toolbar/constants';
import { nodeDistribution } from './multi-select-toolbar/distribute';
import { rotateMultipleNodes } from './multi-select-toolbar/rotate';
import { PageSetup } from './page-setup';
import { ShapeToolbar } from './shape-toolbar';
import { TextToolbar } from './text-toolbar';
import { Tools } from './tools';
import { ZoomAndUndoRedoControls } from './zoom-undo-redo-controls';

import styles from './styles.module.css';

export const MainToolbar = ({
  designId,
  stageRef,
}: {
  designId: string;
  stageRef: MutableRefObject<Stage | null>;
}) => {
  const selectedObjectIds = useUnit($selectedObjectIds);

  const { designData, undo, redo } = useStudioDesign(designId);
  const { designQuery, updateDesignCustomColorsMutation } = useDesign({
    designId,
  });
  const [customColors, setCustomColors] = useState<string[]>([]);

  const showSelectedToolbar = selectedObjectIds.size !== 0;

  const isSmall = useMediaQuery({
    // Measure this with different font sizes etc
    query: '(min-width: 1500px)',
  });

  const isPaged = designData.state?.type === 'pages';

  const isInOneRow = isSmall;

  useStudioShortcut('v', () => {
    changedTool('select');
  });
  useStudioShortcut('h', () => changedTool('move'));

  useEffect(() => {
    if (designQuery.data?.custom_colors) {
      setCustomColors(designQuery.data.custom_colors);
    }
  }, [designQuery.data?.custom_colors]);

  const updateCustomColors = (customColor: string) => {
    if (!customColors.includes(customColor)) {
      setCustomColors((prevColors) => {
        const updatedColors = [...prevColors, customColor];
        updateDesignCustomColorsMutation.mutate({
          id: designId,
          custom_colors: updatedColors,
        });
        return updatedColors;
      });
    }
  };

  return (
    <div className={styles.toolbarSpaceContainer}>
      <div className={styles.toolbar}>
        <PageSetup
          hideAddNewPage={!isPaged}
          designId={designId}
          stage={stageRef.current}
        />
        <div className={styles.divider}></div>
        <ZoomAndUndoRedoControls
          stage={stageRef.current}
          redo={redo}
          undo={undo}
          canRedo={designData.metadata.canRedo}
          canUndo={designData.metadata.canUndo}
        />
        <div className={styles.divider}></div>
        <AddToStudio designId={designId} stage={stageRef.current} />
        <div className={styles.divider}></div>
        <Tools />
        <div className={styles.divider}></div>
        <AnimatePresence>
          {isInOneRow ? (
            <SelectionToolbar
              key="selection-toolbar"
              designId={designId}
              stage={stageRef.current}
              showSelectedToolbar={showSelectedToolbar}
              customColors={customColors}
              updateCustomColors={updateCustomColors}
            />
          ) : null}
        </AnimatePresence>
        <DesignLayout designId={designId} stage={stageRef.current} />
      </div>
      {!isInOneRow ? (
        <AnimatePresence>
          <div className={styles.toolbar}>
            <SelectionToolbar
              key="selection-toolbar"
              firstButtonIsRouned
              designId={designId}
              stage={stageRef.current}
              showSelectedToolbar={showSelectedToolbar}
              customColors={customColors}
              updateCustomColors={updateCustomColors}
            />
          </div>
        </AnimatePresence>
      ) : null}
    </div>
  );
};

const toolbarAnimations = {
  initial: {
    width: 0,
  },
  animate: {
    width: 'auto',
  },
  exit: {
    width: 0,
  },
  transition: {
    type: 'spring',
    bounce: 0.1,
    duration: 0.3,
  },
};

const SelectionToolbar = ({
  designId,
  firstButtonIsRouned = false,
  stage,
  disableAnimation = false,
  showSelectedToolbar,
  customColors,
  updateCustomColors,
}: {
  designId: string;
  firstButtonIsRouned?: boolean;
  stage: Stage | null;
  disableAnimation?: boolean;
  showSelectedToolbar: boolean;
  customColors: string[];
  updateCustomColors: (customColors: string) => void;
}) => {
  const {
    updateLayer,
    objects,
    updateLayers,
    rotateSingleObject,
    rotateMultipleObjects,
    addToGroup,
    removeFromGroup,
    isAnyGrouped,
    areAllInSameGroup,
  } = useStudioDesign(designId);
  const selectedObjectIds = useUnit($selectedObjectIds);
  const selectedObjectIdInGroup = useUnit($selectedObjectIdInGroup);
  const currentTool = useUnit($currentTool);
  const showLibrary = useUnit($showLibrary);

  const [showDropdown, setShowDropdown] = useState(false);

  const hasOneItemSelected =
    selectedObjectIds.size === 1 || selectedObjectIdInGroup;
  const firstItemId = selectedObjectIdInGroup
    ? selectedObjectIdInGroup
    : Array.from(selectedObjectIds)[0];

  const isAddingStickyDisabled = useMemo(() => objects.length === 0, [objects]);

  const bringToFront = () => {
    if (hasOneItemSelected) {
      updateLayer(firstItemId, 'front');
    } else {
      updateLayers({
        objectIds: Array.from(selectedObjectIds),
        action: 'front',
      });
    }
  };

  const sendToBack = () => {
    if (hasOneItemSelected) {
      updateLayer(firstItemId, 'back');
    } else {
      updateLayers({
        objectIds: Array.from(selectedObjectIds),
        action: 'back',
      });
    }
  };

  const findSelectionTransformer = () => {
    const nodes = stage?.find(`.${SELECTION_TRANSFORMER}`);

    const multiSelectTransformer = nodes?.length ? nodes[0] : null;

    if (!multiSelectTransformer || !isTransformerNode(multiSelectTransformer))
      return null;

    return multiSelectTransformer;
  };

  const multiSelectMenuItems = AlignmentType.flatMap((type, index, arr) => {
    const name = `sprite/align-${type.toLocaleLowerCase()}` as AnyIconName;
    const item = (
      <Dropdown.SubMenuItem
        key={type}
        item={{
          classNameleadingIcon: styles.icon,
          leadingIcon: <Icon name={name} />,
          content: `${type}`,
          onClick: () => {
            const multiSelectTransformer = findSelectionTransformer();

            nodeAlignment(
              Array.from(selectedObjectIds).map((id) => id),
              type,
              multiSelectTransformer,
              stage,
            );
          },
        }}
      />
    );

    // If this is the third item in a group and not the last overall, add a divider
    if ((index + 1) % 3 === 0 && index !== arr.length - 1) {
      return [
        item,
        <hr key={type + 'underscore'} className={styles.menuDivider} />,
      ];
    }

    return item;
  });

  const animations = disableAnimation ? {} : toolbarAnimations;

  const baseBar = (
    <div className={styles.toolbarInner}>
      <SegmentedButton
        start={firstButtonIsRouned}
        buttonStyle={styles.button}
        label="Arrange"
        isDisabled={selectedObjectIds.size === 0}
        onClick={() => setShowDropdown((p) => !p)}
      />
      <Dropdown open={showDropdown} onOpenChange={setShowDropdown}>
        <Dropdown.Menu
          side="bottom"
          sideOffset={20}
          align="start"
          density="-2"
          alignOffset={-84}
          className={styles.dropdownContainer}
        >
          <Dropdown.SubMenu
            trailingIcon={<Icon name="sprite/caret-right" />}
            icon="Layer"
          >
            <Dropdown.SubMenuItem
              item={{
                classNameleadingIcon: styles.icon,
                leadingIcon: <Icon name="sprite/layer-front" />,
                content: `Bring to front`,
                onClick: () => bringToFront(),
              }}
            />
            <Dropdown.SubMenuItem
              item={{
                classNameleadingIcon: styles.icon,
                leadingIcon: (
                  <Icon name="sprite/layer-back" width={24} height={24} />
                ),
                content: `Send to back`,
                onClick: () => sendToBack(),
              }}
            />
          </Dropdown.SubMenu>
          <Dropdown.SubMenu
            trailingIcon={<Icon name="sprite/caret-right" />}
            icon="Rotate"
          >
            <Dropdown.SubMenuItem
              item={{
                classNameleadingIcon: styles.icon,
                leadingIcon: <Icon name="sprite/rotate-clockwise" />,
                content: `90° clockwise`,
                onClick: () => {
                  const multiSelectTransformer = findSelectionTransformer();
                  if (multiSelectTransformer) {
                    const nodes = rotateMultipleNodes({
                      selectionTransformerRef: multiSelectTransformer,
                      stageRef: stage,
                      rotateAmount: 90,
                    });
                    if (nodes) {
                      rotateMultipleObjects(nodes);
                    }
                  } else {
                    rotateSingleObject(firstItemId, 90);
                  }
                },
              }}
            />
            <Dropdown.SubMenuItem
              item={{
                classNameleadingIcon: styles.icon,
                leadingIcon: (
                  <Icon
                    name="sprite/rotate-counterclockwise"
                    width={24}
                    height={24}
                  />
                ),
                content: `90° anti-clockwise`,
                onClick: () => {
                  const multiSelectTransformer = findSelectionTransformer();
                  if (multiSelectTransformer) {
                    const nodes = rotateMultipleNodes({
                      selectionTransformerRef: multiSelectTransformer,
                      stageRef: stage,
                      rotateAmount: -90,
                    });
                    if (nodes) {
                      rotateMultipleObjects(nodes);
                    }
                  } else {
                    rotateSingleObject(firstItemId, -90);
                  }
                },
              }}
            />
          </Dropdown.SubMenu>
          {selectedObjectIds.size >= 2 ? (
            <Dropdown.SubMenu
              icon="Align"
              trailingIcon={<Icon name="sprite/caret-right" />}
            >
              {multiSelectMenuItems.length ? multiSelectMenuItems : null}
            </Dropdown.SubMenu>
          ) : null}
          {selectedObjectIds.size > 2 && (
            <Dropdown.SubMenu
              icon="Distribute"
              trailingIcon={<Icon name="sprite/caret-right" />}
            >
              <Dropdown.SubMenuItem
                item={{
                  classNameleadingIcon: styles.icon,
                  leadingIcon: (
                    <Icon
                      style={{ justifyContent: 'unset' }}
                      name="sprite/distribute-horizontally"
                    />
                  ),
                  content: `Horizontally`,
                  onClick: () => {
                    const multiSelectTransformer = findSelectionTransformer();
                    nodeDistribution(
                      Array.from(selectedObjectIds).map((id) => id),
                      'HORIZONTAL',
                      multiSelectTransformer,
                      stage,
                    );
                  },
                }}
              />
              <Dropdown.SubMenuItem
                item={{
                  classNameleadingIcon: styles.icon,
                  leadingIcon: <Icon name="sprite/distribute-vertically" />,
                  content: `Vertically`,
                  onClick: () => {
                    const multiSelectTransformer = findSelectionTransformer();
                    nodeDistribution(
                      Array.from(selectedObjectIds).map((id) => id),
                      'VERTICAL',
                      multiSelectTransformer,
                      stage,
                    );
                  },
                }}
              />
            </Dropdown.SubMenu>
          )}
          {selectedObjectIds.size > 1 ? (
            <>
              <Divider className={styles.groupDivider} type="short-line" />
              <Dropdown.MenuItem
                item={{
                  content: 'Group',
                  classNameContent: styles.groupMenuItemContent,
                  isDisabled: areAllInSameGroup(selectedObjectIds),
                  onClick: () => {
                    addToGroup(selectedObjectIds);
                  },
                }}
              />
              <Dropdown.MenuItem
                item={{
                  content: 'Ungroup',
                  classNameContent: styles.groupMenuItemContent,
                  isDisabled: !isAnyGrouped(selectedObjectIds),
                  onClick: () => {
                    removeFromGroup(selectedObjectIds);
                  },
                }}
              />
            </>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
      <TooltipRadix description="Tidy up">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/tidy-up" />}
          onClick={() => openedShuffler()}
          isSelected={false}
        />
      </TooltipRadix>
      <div className={styles.divider}></div>
      <TooltipRadix
        description={
          isAddingStickyDisabled
            ? 'Add your first image or text to leave sticky notes'
            : 'Sticky notes'
        }
      >
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/sticky-add" />}
          onClick={() => changedTool('sticky')}
          isDisabled={isAddingStickyDisabled}
          isSelected={currentTool === 'sticky' && !showLibrary}
        />
      </TooltipRadix>
      <AnimatePresence>
        {hasOneItemSelected && showSelectedToolbar ? (
          <motion.div {...animations} className={styles.toolbarInner}>
            <SelectedObjectBar
              designId={designId}
              selectedObjectId={firstItemId}
              stage={stage}
              customColors={customColors}
              updateCustomColors={updateCustomColors}
            />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );

  return <>{baseBar}</>;
};

const SelectedObjectBar = ({
  designId,
  selectedObjectId,
  stage,
  customColors,
  updateCustomColors,
}: {
  designId: string;
  selectedObjectId: string;
  stage: Stage | null;
  customColors: string[];
  updateCustomColors: (customColors: string) => void;
}) => {
  const { objects, updateText, deleteObject, updateShape } =
    useStudioDesign(designId);

  const selectedItem = useMemo(
    () => objects.find((o) => o.id === selectedObjectId),
    [objects, selectedObjectId],
  );

  if (!selectedItem) return null;

  switch (selectedItem.type) {
    case 'image':
      return (
        <ImageToolbar
          designId={designId}
          imageId={selectedObjectId}
          blockId={selectedItem.metadata.blockId}
          canShowOnboardingTip={false}
          imageType={selectedItem.metadata.imageType}
        />
      );
    case 'shape':
      return (
        <ShapeToolbar
          shape={selectedItem}
          updateShape={updateShape}
          deleteShape={deleteObject}
          customColors={customColors}
          updateCustomColors={updateCustomColors}
        />
      );
    case 'text':
      return (
        <TextToolbar
          key={`${selectedItem.id}-${selectedItem.metadata.fontSize}`}
          textbox={selectedItem}
          updateText={updateText}
          deleteObject={deleteObject}
          stage={stage}
        />
      );
    default:
      return null;
  }
};
