import { useRef } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { useParams } from 'react-router-dom';

import { $hubDeleteModalState } from '@src/entities/delete-modals/model';
import { useBilling } from '@src/shared/queries/useBilling';
import { uploadFiles } from '@src/shared/utils';

import { useAllBoards } from '../../pages/BoardPage/model/queries/useAllBoards';
import { ActionButton } from '..';

import styles from './LibraryActions.module.css';

export type Page = 'docPage';

type Props = {
  page?: Page;
  isHubLocked?: boolean;
  isArchived?: boolean;
};

export const LibraryActions = ({ page, isHubLocked, isArchived }: Props) => {
  const hubDeleteModalState = useUnit($hubDeleteModalState);

  const {
    query: { data },
  } = useBilling();

  const { data: allBoards } = useAllBoards();
  const { board_id } = useParams<any>();
  const uploadButtonRef = useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    if (!uploadButtonRef.current || !allBoards) return;

    const files = Array.from(uploadButtonRef.current.files ?? []);
    const board = allBoards.find((board: any) => board.id === board_id);
    uploadFiles(files, board);
  };

  const uploadAction = () => {
    uploadButtonRef.current?.click();
  };

  if (isHubLocked || !data) return null;

  return (
    <>
      <ActionButton
        className={cn(styles.button, {
          [styles.confirmationDialogOpened]: !!hubDeleteModalState,
        })}
        uploadAction={uploadAction}
        page={page}
        plan={data.membershipTierInfo.plan}
        isArchived={isArchived}
      />
      {/*
       * HACK: this input is needed here because the DropDownList is being unMounted
       * once a user clicks on it and values become unavailable.
       */}
      <input
        type="file"
        multiple
        ref={uploadButtonRef}
        className={styles.uploadInput}
        accept="image/png, image/jpeg"
        onChange={handleUpload}
      />
    </>
  );
};
