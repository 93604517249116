import Konva from 'konva';
import { Vector2d } from 'konva/lib/types';

import { ImageJSON, ShapeJSON, TextJSON } from '@api/designs';

import { getImages, getTextBoxes } from '../api';
import { shapeType } from '../components/Shape';

export const fetchImages = async (id: string) => {
  const imagesResponse = await getImages(id);

  return imagesResponse.data.results;
};

export const fetchTextboxes = async (id: string) => {
  const textResponse = await getTextBoxes(id);

  return textResponse.data.results;
};

export const isBlobOldShapeData = (
  blob: unknown,
): blob is {
  shapes: Array<{
    x: number;
    y: number;
    id: string;
    rotation: number;
    scale: {
      x: number;
      y: number;
    };
    metadata: {
      fill: string;
      radius: number;
      stroke: string;
      strokeWidth: number;
      type: shapeType;
    };
  }>;
} => {
  if (
    typeof blob === 'object' &&
    blob !== null &&
    'shapes' in blob &&
    Array.isArray((blob as any).shapes)
  ) {
    return true;
  }

  return false;
};

// Rotates a point (x, y) around the origin (0,0) by a given angle in radians.
// Uses standard 2D rotation matrix transformation.
export const rotatePoint = ({ x, y }: Vector2d, rad: number) => {
  const rcos = Math.cos(rad);
  const rsin = Math.sin(rad);
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
};

// Rotates around the centre of the object
// Because groups are squares in Konva
// we want to rotate them around the center not top left
export const rotateAroundCenter = (
  object: ImageJSON | TextJSON | ShapeJSON,
  amount: number,
): ImageJSON | TextJSON | ShapeJSON => {
  if (!object.metadata.width || !object.metadata.height) {
    console.error(
      `${object.id} has null width or hight. Shouldn't be possible`,
    );
    return object;
  }
  const topLeft = {
    x: -object.metadata.width / 2,
    y: -object.metadata.height / 2,
  };

  const currentRotation = Konva.getAngle(object.rotation);
  const newRotation = Konva.getAngle(object.rotation + amount);

  const current = rotatePoint(topLeft, currentRotation);
  const rotated = rotatePoint(topLeft, newRotation);

  const dx = rotated.x - current.x;
  const dy = rotated.y - current.y;

  const newObject = {
    ...object,
    x: object.x + dx,
    y: object.y + dy,
    rotation: object.rotation + amount,
  };

  return newObject;
};
