import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import {
  persistQueryClient,
  removeOldestQuery,
} from '@tanstack/react-query-persist-client';

import { api } from './api';
import { User } from './AppContext';
import { disabledLoadingDemoMode, enabledLoadingDemoMode } from './model';
import {
  BILLING_INFO_QUERY,
  HUB_CREDIT_QUERY,
  HUB_PRODUCTS_QUERY,
  MANAGE_BILLING_QUERY,
  MEMBERSHIP_PRODUCT_INFO_QUERY,
} from './shared/constants';

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onSuccess: async (res, query) => {
      if (query.queryKey.includes('my-user')) {
        const userResponse = res as User;
        const isDemo = userResponse.meta?.onboarding?.demoMode;

        const currentApiUrl = api.defaults.baseURL;
        const toBeApiUrl = isDemo
          ? `${import.meta.env.VITE_API_BASE_URL}/demo`
          : import.meta.env.VITE_API_BASE_URL;

        // const lastUsedMode = localStorage.getItem('last-used-mode');

        if (
          currentApiUrl !== toBeApiUrl
          // && !(lastUsedMode === 'demo' && isDemo)
        ) {
          api.defaults.baseURL = toBeApiUrl;
          // Reset all queries in cache

          enabledLoadingDemoMode();
          await queryClient.refetchQueries({});
          disabledLoadingDemoMode();
        }

        console.log(isDemo, toBeApiUrl);

        localStorage.setItem('last-used-mode', isDemo ? 'demo' : 'not-demo');
      }
    },
  }),
  defaultOptions: {
    queries: {
      gcTime: Infinity,
    },
  },
});

const localStoragePersistor = createSyncStoragePersister({
  storage: typeof window !== 'undefined' ? window.localStorage : null,
  retry: removeOldestQuery,
});

persistQueryClient({
  queryClient,
  persister: localStoragePersistor,
  maxAge: Infinity,
  // This is the buster key for saving our cached data. On a new commit this is changed and the cache is invalidated.
  buster: import.meta.env.VITE_RENDER_GIT_COMMIT_SHA ?? 'DEV',
  dehydrateOptions: {
    shouldDehydrateQuery: (query) => {
      // Store all queries except user data
      const queryIsReadyForPersistance = query.state.status === 'success';
      if (queryIsReadyForPersistance) {
        const { queryKey } = query;
        // Do not cache the following queries
        const excludeFromPersisting =
          queryKey.includes('my-user') ||
          queryKey.includes(BILLING_INFO_QUERY) ||
          queryKey.includes(MEMBERSHIP_PRODUCT_INFO_QUERY) ||
          queryKey.includes(HUB_CREDIT_QUERY) ||
          queryKey.includes(HUB_PRODUCTS_QUERY) ||
          queryKey.includes(MANAGE_BILLING_QUERY);
        return !excludeFromPersisting;
      }
      return queryIsReadyForPersistance;
    },
  },
});
