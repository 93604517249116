import { whiteOnexOne } from './white-one-by-one-base64-img';

// Zoom max
export const MIN_ZOOM = 20;
export const MAX_ZOOM = 0.05;

// Canvas realted
export const GRID_SIZE = 10;

// Animate related
export const TAB_ANIMATION_START_DELAY = 0.15;
export const TOOLBAR_ANIMATION_START_DELAY = TAB_ANIMATION_START_DELAY + 0.15;

// Names of nodes
export const DRAG_SELECTION = 'drag-selection';
export const MAIN_OBJECTS_LAYER = 'main-objects-layer';
export const PAGE_GROUP = 'Page-Group';
export const IMAGES_LAYER = 'Images';
export const IMAGE = 'Image';
export const IMAGE_GROUP = 'Image-Group';
export const TEXT_LAYER = 'Texts';
export const TEXT_GROUP = 'Text-Group';
export const TEXT = 'Text';
export const STICKY_LAYER = 'Sticky';
export const TRANSFORMER = 'Transformer';
export const DEFAULT_FONTFAMILY = 'Arial';
export const DEFAULT_FONTSIZE = 80;
export const SHAPE_LAYER = 'ShapeLayer';
export const SHAPE_GROUP = 'Shape-Group';
export const SHAPE = 'Shape';
export const PAGE_GROUP_RECT = 'Page-Group-Rect';
export const WHITE_ONE_BY_ONE_BASE64_IMAGE = whiteOnexOne;
export const SELECTION_TRANSFORMER = 'selection-transformer';
