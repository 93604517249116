import { memo } from 'react';

import { ArchiveDialog } from './archive';
import { BoardCreationDialog } from './board/creation';
import { MoveBoardDialog } from './board/move-board';
import { WarningDialog } from './board/move-board/warning';
import { BoardRenamingDialog } from './board/renaming';
import { ColorCodesDialog } from './color-codes';
import { HubCreationDialog } from './hub/creation';
import { HubRenamingDialog } from './hub/renaming';
import { HubRestoringDialog } from './hub/restoring';
import { PaletteCreationDialog } from './palette';
import { UnarchiveDialog } from './unarchive';

export const Dialogs = memo(() => (
  <>
    <HubCreationDialog />
    <HubRenamingDialog />
    <HubRestoringDialog />
    <BoardCreationDialog />
    <BoardRenamingDialog />
    <MoveBoardDialog />
    <WarningDialog />
    <PaletteCreationDialog />
    <ColorCodesDialog />
    <ArchiveDialog />
    <UnarchiveDialog />
  </>
));
