import React, { useRef, useState } from 'react';

import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';
import { debounce } from 'lodash';
import { flushSync } from 'react-dom';

import {
  Dropdown,
  SegmentedButton,
  TextColor2,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Alignment, TextJSON } from '@api/designs';
import { DEFAULT_FONTFAMILY } from '@pages/StudioPage/constants';
import { fontNames } from '@pages/StudioPage/fonts';
import { isTextNode } from '@pages/StudioPage/utils';

import { DesignType } from '../../../lib/design';
import { $isFontsLoaded } from '../../Canvas/model/fonts-loading';

import styles from './styles.module.css';

type Props = {
  textbox: TextJSON;
  updateText: (updates: Parameters<DesignType['updateText']>['0']) => void;
  deleteObject: (updates: Parameters<DesignType['deleteObject']>['0']) => void;
  stage: Stage | null;
};

export const TextToolbar = ({
  stage,
  textbox,
  updateText,
  deleteObject,
}: Props) => {
  const {
    id,
    metadata: {
      bold,
      italic,
      colour,
      fontSize,
      underline,
      alignment,
      fontFamily,
    },
  } = textbox;
  const [showColorWheel, setShowColorWheel] = React.useState(false);
  const [localFontSize, setLocalFontSize] = React.useState(fontSize);
  const [isFocus, setIsFocus] = useState(false);
  const previewFont = useRef<string | null>(null);

  const colorInputRef = React.useRef<HTMLInputElement>(null);

  const isFontsLoaded = useUnit($isFontsLoaded);

  const toggleColorWheel = () => setShowColorWheel((s) => !s);

  React.useEffect(() => {
    if (showColorWheel) {
      colorInputRef.current?.click();
    }
  }, [showColorWheel]);

  const mutateAlignment = (alignment: Alignment) => {
    updateText({
      id,
      metadata: {
        alignment,
      },
    });
  };

  const alignCenter = () => {
    flushSync(() => mutateAlignment('center'));
  };

  const alignLeft = () => {
    flushSync(() => mutateAlignment('left'));
  };

  const alignRight = () => {
    flushSync(() => mutateAlignment('right'));
  };

  const toggleBold = () => {
    flushSync(() =>
      updateText({
        id,
        metadata: {
          bold: !bold,
        },
      }),
    );
  };

  const toggleItalic = () => {
    flushSync(() =>
      updateText({
        id,
        metadata: {
          italic: !italic,
        },
      }),
    );
  };

  const toggleUnderline = () => {
    flushSync(() =>
      updateText({
        id,
        metadata: {
          underline: !underline,
        },
      }),
    );
  };

  const updateFontSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFontSize = Number(e.target.value);

    if (newFontSize && newFontSize <= 0) return;

    setLocalFontSize(newFontSize);
  };

  const getCurrentSize = () => {
    const node = stage?.find(`#${id}`);

    const scale = stage?.scale();

    if (!scale || !node || node.length === 0) return null;

    const rect = node[0].getClientRect();

    return {
      x: rect.x,
      y: rect.y,
      width: rect.width / scale.x,
      height: rect.height / scale.y,
    };
  };

  const commitFontSize = () => {
    if (localFontSize === 0) {
      setLocalFontSize(fontSize);
      return;
    }

    const size = getCurrentSize();

    if (!size) return;

    const growing = document.getElementById(`${id}-textarea`);
    const textNodesKonva = stage?.find(`#${id}`);

    flushSync(() =>
      updateText({
        id,
        metadata: {
          fontSize: localFontSize,
          height: size.height,
          width: size.width,
        },
      }),
    );

    if (growing) {
      growing.style.width = `${size.width}px`;
      growing.style.height = `${size.height}px`;
    }
    if (textNodesKonva?.length === 1) {
      const node = textNodesKonva[0];

      node.width(size.width);
      node.height(size.height);

      node.fire('transformend', {
        type: 'transformend',
        target: node,
        evt: new MouseEvent('transformend'),
      });
    }
  };

  const updateColour = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColour = e.target.value;

    if (!newColour) return;

    commitColour(newColour);
  };

  const commitColour = React.useMemo(() => {
    return debounce((newColour: string) => {
      updateText({
        id,
        metadata: {
          colour: newColour,
        },
      });
    }, 200);
  }, []);

  const removeText = () => {
    deleteObject(id);
  };

  const setFontFamily = (font: string) => {
    const size = getCurrentSize();

    if (!size) return;

    flushSync(() =>
      updateText({
        id,
        metadata: {
          fontFamily: font,
          height: size.height,
          width: size.width,
        },
      }),
    );

    previewFont.current = null;
  };

  const findTextNode = () => {
    const node = stage?.find(`#${id}`);

    if (node?.length !== 1) return;

    const textNode = node[0];

    if (!isTextNode(textNode)) return;

    return textNode;
  };

  const hoverFont = (font: string | null) => {
    const textNode = findTextNode();

    if (!textNode) return;

    // If font is null set font to default font
    const currentFont = font === null ? fontFamily : font;

    const growingElement = document.getElementById(`${id}-growing`);

    if (!growingElement) return;

    growingElement.style.fontFamily = currentFont;

    textNode.setAttr('fontFamily', currentFont);
    textNode.setAttr('width', growingElement.clientWidth + 5);
  };

  const leadIcon = (isSelected: boolean) => (
    <Icon name="sprite/tick" opacity={isSelected ? 1 : 0} />
  );

  const fontMenuItems = fontNames.map((font) => {
    const strippedFont = font.replace(/\s*Variable$/, '');
    return {
      leadingIcon: leadIcon(fontFamily === font),
      label: font,
      onClick: () => setFontFamily(font),
      content: strippedFont,
    };
  });

  return (
    <>
      <div className={styles.divider}></div>
      {isFontsLoaded && (
        <Dropdown>
          <Dropdown.Menu
            trigger={
              <TooltipRadix description="Font" side="top">
                <SegmentedButton
                  buttonStyle={styles.button}
                  onClick={() => {}}
                  icon={
                    <>
                      {fontFamily === DEFAULT_FONTFAMILY ? (
                        <Icon name="sprite/text-font" />
                      ) : (
                        <p
                          style={{
                            fontFamily,
                            width: '100%',
                          }}
                        >
                          Aa
                        </p>
                      )}
                    </>
                  }
                />
              </TooltipRadix>
            }
            side="top"
            sideOffset={8}
            align="start"
            density="-2"
            className={styles.dropdownContainer}
          >
            {fontMenuItems.map((item) => (
              <Dropdown.MenuItem
                key={item.label}
                item={item}
                textStyle={{ fontFamily: item.label }}
                onMouseEnter={() => {
                  hoverFont(item.label);
                }}
                onMouseLeave={() => {
                  hoverFont(null);
                }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
      <TooltipRadix description="Size" side="top">
        <SegmentedButton
          start
          buttonStyle={styles.button}
          icon={
            <input
              value={Math.round(localFontSize) ?? ''}
              className={styles.input}
              onChange={updateFontSize}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.key === 'Enter') commitFontSize();
              }}
              onFocus={() => setIsFocus(true)}
              onBlur={() => {
                commitFontSize();
                setIsFocus(false);
              }}
              type="number"
            />
          }
          onClick={() => {}}
          isSelected={isFocus}
        />
      </TooltipRadix>
      <TooltipRadix description="Color" side="top">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={
            <>
              <TextColor2 />
              <div className={styles.colorContainer}>
                <input
                  style={{
                    visibility: showColorWheel ? 'visible' : 'hidden',
                  }}
                  value={colour}
                  ref={colorInputRef}
                  type="color"
                  className={styles.colorWheelInput}
                  onChange={updateColour}
                />
              </div>
            </>
          }
          onClick={toggleColorWheel}
        />
      </TooltipRadix>
      <TooltipRadix description="Bold" side="top">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-bold" />}
          onClick={toggleBold}
          isSelected={bold}
        />
      </TooltipRadix>
      <TooltipRadix description="Italic" side="top">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-italic" />}
          onClick={toggleItalic}
          isSelected={italic}
        />
      </TooltipRadix>
      <TooltipRadix description="Underline" side="top">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-underline" />}
          onClick={toggleUnderline}
          isSelected={underline}
        />
      </TooltipRadix>
      <div className={styles.divider}></div>
      <TooltipRadix description="Align left" side="top">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-align-left" />}
          onClick={alignLeft}
          isSelected={alignment === 'left'}
        />
      </TooltipRadix>
      <TooltipRadix description="Align centre" side="top">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-align-centre" />}
          onClick={alignCenter}
          isSelected={alignment === 'center'}
        />
      </TooltipRadix>
      <TooltipRadix description="Align right" side="top">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/text-align-right" />}
          onClick={alignRight}
          isSelected={alignment === 'right'}
        />
      </TooltipRadix>
      <div className={styles.divider}></div>
      <TooltipRadix description="Delete" side="top">
        <SegmentedButton
          end
          buttonStyle={styles.button}
          icon={<Icon name="sprite/bin" size={24} color="var(--color-error)" />}
          onClick={removeText}
        />
      </TooltipRadix>
    </>
  );
};
